import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  min-height: 90px;
  justify-content: ${props => props.justifyContent};
  z-index: 100;
  padding: 15px 20px;
  
  background-color: ${props => props.transparent && 'transparent'};
  
  .gpay-button.long {
    width: 100%;
    height: 57px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`

const Footer = props => {
    const { children, ...rest } = props

    return (
        <StyledFooter {...rest}>
            {props.children}
        </StyledFooter>
    )
}

Footer.propTypes = {
    isBigger: PropTypes.bool,
    transparent: PropTypes.bool,
    flexDirection: PropTypes.string,
    isFixedOnLg: PropTypes.bool,
    justifyContent: PropTypes.string,
}

Footer.defaultProps = {
    isBigger: false,
    flexDirection: 'column',
    isFixedOnLg: false,
    justifyContent: 'center',
}

export default Footer
