import React from 'react'
import { useSelector } from 'react-redux'
import { DetailContainer, LoyaltyHistoryList } from '../../components/profileadmin/_baseComponents'
import ListItemVoucherRequest from '../../components/profileadmin/ListItemVoucherRequest'
import VoucherDisplay from '../../components/profileadmin/VoucherDisplay'
import { useTranslation } from 'react-i18next'
import bgIllustration from '../../images/profile/illustration_guthaben.svg'


const ViewVoucher = (props) => {
    const company = useSelector(state => state.company)
    const voucherBalanceAmountCents = company.voucher?.balance?.amountCents || 0
    const historyArray = company.voucher?.requests || []
    const { t } = useTranslation()

    return (

        <DetailContainer>
            <VoucherDisplay voucherBalanceAmountCents={voucherBalanceAmountCents}/>
            <LoyaltyHistoryList bgIllustration={bgIllustration} title={t('profile.voucher.historyTitle')}>
                {historyArray.map((e, index) => <ListItemVoucherRequest key={index} historyEvent={e} lastItem={(index + 1 >= historyArray.length)}/>)}
            </LoyaltyHistoryList>
        </DetailContainer>
    )
}

export default ViewVoucher
