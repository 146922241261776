import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUserMenuOpen } from '../actions/appStateActions'

const UserMenuContainer = styled.div`
  position: absolute;
  top: 0;
  right:-200px;
  width: 200px;
  height: max(100vh, 100%);
  font-size: 0.9em;
  background-color: ${props => props.theme.headerBottomColor};
  font-weight: 500;
  
  border-left: 1px solid lightgrey;
  
  padding: 10px;
  
  & h3 {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid darkgray;
  }
`

const StyledUl = styled.ul`
  font-size: 0.8em;
  list-style: none;
  width: 100%;
  padding: 0 10px;
  margin: 38px 0 0;
 
`

const StyledLi = styled.li`
  cursor: pointer;
  text-align: center;
  padding: 10px;
  background: black;
  color: white;
  border-radius: 6px;
  margin: 5px;
`

const StyledUsername = styled.h3`
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
`

const UserMenu = (props) => {
    const { t } = useTranslation()
    const { user } = useAuth0()
    const history = useHistory()
    const dispatch = useDispatch()


    return <>
        {(user) && <UserMenuContainer>
            <StyledUsername>{user.nickname}</StyledUsername>
            <StyledUl>

                <StyledLi
                    onClick={() => {
                        dispatch(setUserMenuOpen(false))
                        return history.push('/')
                    }}>
                    {t('profile.home')}
                </StyledLi>

                <StyledLi
                    onClick={() => {
                        dispatch(setUserMenuOpen(false))
                        return history.push('/logout')
                    }}>
                    {t('profile.logout')}
                </StyledLi>

            </StyledUl>
        </UserMenuContainer>}
    </>

}
export default UserMenu
