import React from 'react'
import { useSelector } from 'react-redux'
import CashbackDisplay from '../../components/profileadmin/CashbackDisplay'
import { DetailContainer, LoyaltyHistoryList } from '../../components/profileadmin/_baseComponents'
import ListItemCashbackEvent from '../../components/profileadmin/ListItemCashbackEvent'
import { useTranslation } from 'react-i18next'
import bgIllustration from '../../images/profile/illustration_cashback.svg'


const ViewCashback = () => {
    const company = useSelector(state => state.company)
    const cashbackPoints = company.loyalty?.account?.amount || 0
    const cashbackThreshold = company.loyalty?.configuration?.threshold || 0
    const cashbackPointValue = company.loyalty?.configuration?.pointValue || 0
    const historyArray = company.loyalty?.events || []
    const { t } = useTranslation()

    return (
        <DetailContainer>
            <CashbackDisplay cashbackPoints={cashbackPoints} cashbackThreshold={cashbackThreshold} cashbackPointValue={cashbackPointValue}/>
            <LoyaltyHistoryList bgIllustration={bgIllustration} title={t('profile.cashback.historyTitle')}>
                {historyArray.map((e, index) => <ListItemCashbackEvent key={index} historyEvent={e} lastItem={(index + 1 >= historyArray.length)}/>)}
            </LoyaltyHistoryList>
        </DetailContainer>
    )
}

export default ViewCashback
