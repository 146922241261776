import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import WizardStepNavigation from '../components/WizardStepNavigation'
import { WithWizard } from 'react-albus'
import Headline from '../components/Headline'
import Figure from '../components/Figure'
import { ReactComponent as LogoWithClaim } from '../images/logo_with_claim.svg'
import { ReactComponent as LogoWithoutClaim } from '../images/logo_without_claim.svg'

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NavigationHeader = styled(StyledHeader)`
  margin-bottom: 10px;
`

const OnlyLogoImage = styled(Figure)`
  margin-top: 0.8em;
  
  @media (min-width: ${props => props.theme.desktop}) {
    rect, path {
      fill: ${props => props.theme.mainColorDesktop};
    }
  }
`

const NavigationImage = styled(Figure)`
  margin-top: 0.4em;
  
  @media (min-width: ${props => props.theme.desktop}) {
    rect, path {
      fill: ${props => props.theme.mainColorDesktop};
    }
  }
`

const OnlyLogoTitle = styled(Headline)`
  margin: 0.6em 0;
  font-size: 1.25em;
`

const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Header = (props) => {
    const { stepConfig, onlyLogo, title } = props
    const cancelReturnUrl = useSelector(state => state.paymentData.paymentGatewayData?.cancelReturnUrl)

    if (onlyLogo) {
        return <StyledHeader>
            <OnlyLogoImage maxWidth="62%" maxWidthDesktop="40%">
                <LogoWithClaim/>
            </OnlyLogoImage>
            {title && <OnlyLogoTitle forwardedAs="h3" fontWeight="400" dangerouslySetInnerHTML={{ __html: title }}/>}
        </StyledHeader>
    }

    const Content = (props) => {
        if (cancelReturnUrl) {
            return <a href={cancelReturnUrl}>
                <NavigationImage>
                    <LogoWithoutClaim/>
                </NavigationImage>
            </a>
        } else if (props.step && props.step.id === 'step-6') {
            return <NavigationImage maxWidth="40%">
                <LogoWithoutClaim/>
            </NavigationImage>
        } else {
            return <StyledLink to="/wizard/step-1">
                <NavigationImage maxWidth="40%">
                    <LogoWithoutClaim/>
                </NavigationImage>
            </StyledLink>
        }
    }

    if (!stepConfig) {
        return <StyledHeader hasMinHeight={false}>
            <Content {...props}/>
        </StyledHeader>
    }

    return (
        <WithWizard render={(wizard) => (
            <NavigationHeader>
                <Content step={wizard.step} {...props} />
                <WizardStepNavigation stepConfig={stepConfig} wizard={wizard}/>
            </NavigationHeader>
        )}/>
    )
}

Header.propTypes = {
    onlyLogo: PropTypes.bool,
    title: PropTypes.string,
    stepConfig: PropTypes.object,
}

Header.defaultProps = {
    hideLogo: false,
}

export default Header
