import {
    ALLOW_PAYMENT_METHOD,
    CLEAR_STATE,
    DISALLOW_PAYMENT_METHOD,
    INIT_IS_BILL_SCANNED,
    RECEIPT_SCANNED,
    SET_AMOUNT_EDITABLE,
    SET_CASHBACK_POT,
    SET_HIDE_RESTAURANT_IN_HEADER,
    SET_REDIRECT_AFTER_LOGIN,
    SET_TRANSACTION_FINISHED,
    SET_USER_MENU_OPEN,
    SET_XHR_PROGRESS,
} from '../actions/types'

const initState = {
    transactionFinished: false,
    isBillScanned: false,
    supportedPaymentMethods: ['credit_card', 'pay_pal', 'sofort', 'alipay', 'wechat', 'paydirekt', 'google_pay', 'stripe_credit_card', 'stripe_sofort'],
    amountEditable: true,
    xhrInProgress: false,
    hideRestaurantInHeader: false,
    redirectAfterLogin: null,
    userMenuOpen: false,
    cashbackPot: null,
}

export function appState (
    state = initState,
    action,
) {
    switch (action.type) {
        case RECEIPT_SCANNED: {
            return { ...state, isBillScanned: true }
        }
        case SET_TRANSACTION_FINISHED: {
            return { ...state, transactionFinished: action.payload }
        }
        case INIT_IS_BILL_SCANNED:
            return { ...state, isBillScanned: false }
        case ALLOW_PAYMENT_METHOD: {
            const { supportedPaymentMethods } = state
            if (!supportedPaymentMethods.includes(action.payload)) {
                const newList = [...supportedPaymentMethods, action.payload]
                return { ...state, supportedPaymentMethods: newList }
            } else {
                return { ...state }
            }
        }
        case DISALLOW_PAYMENT_METHOD: {
            const { supportedPaymentMethods } = state
            const removedList = supportedPaymentMethods.filter(item => item !== action.payload)
            return { ...state, supportedPaymentMethods: removedList }
        }
        case SET_AMOUNT_EDITABLE:
            return { ...state, amountEditable: action.payload }
        case SET_XHR_PROGRESS:
            return { ...state, xhrInProgress: action.payload }
        case SET_HIDE_RESTAURANT_IN_HEADER:
            return { ...state, hideRestaurantInHeader: action.payload }
        case SET_REDIRECT_AFTER_LOGIN:
            return { ...state, redirectAfterLogin: action.payload }
        case SET_USER_MENU_OPEN:
            return { ...state, userMenuOpen: action.payload }
        case SET_CASHBACK_POT:
            return { ...state, cashbackPot: action.payload }
        case CLEAR_STATE:
            return { ...initState }
        default:
            return state
    }
}
