import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as heartIcon } from '../../images/profile/favorite.svg'
import { useDispatch, useSelector } from 'react-redux'
import { createOrDeleteCompanyFavorite } from '../../actions/profileActions'
import { isFavoriteSelector } from '../../reducers/profile'

const StyledButton = styled.button`
  -webkit-appearance: none;
  border: none;
  background: transparent;
  display: ${props => props.display ?? props.display};
  padding: 0;
  
  &:active, &:hover, &:focus {
    border: none;
    background: transparent;
    outline: none;
  }
`

const StyledFavorite = styled(heartIcon)`
    width: 25px;
    height: 25px;
    color: ${props => props.theme.mainColor};
    fill: ${props => (props.active === 'true') ? props.theme.mainColor : 'transparent'};
    margin: auto;
`

const FavoriteButton = (props) => {
    const { company } = props
    const dispatch = useDispatch()
    const isFavorite = useSelector(state => isFavoriteSelector(state, company.id))
    const isFavoriteBoolean = (typeof isFavorite === 'object' && isFavorite != null) ? true : isFavorite
    const authState = useSelector((state => state.authState))
    const onFavoriteClicked = event => {
        event.stopPropagation()
        dispatch(createOrDeleteCompanyFavorite(company))
    }

    return <StyledButton key={company.id}
                         onClick={onFavoriteClicked}
                         display={authState.authToken ? 'block' : 'none'}>
        <StyledFavorite active={isFavoriteBoolean?.toString()}/>
    </StyledButton>
}

FavoriteButton.propTypes = {
    company: PropTypes.object.isRequired,
}

export default FavoriteButton
