import React from 'react'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as cashbackArrow } from '../../images/profile/cashback_arrow.svg'
import { ReactComponent as cashbackInside } from '../../images/profile/cashback_inside.svg'

const rotating = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
`
const CashBackIconContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  height: 100%;
`

const CashBackArrow = styled(cashbackArrow)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; 
  animation: ${rotating} 1s linear infinite;
`

const CashBackInside = styled(cashbackInside)`
  position: absolute;
  width: 60%;
  top: 0;
  left: 20%; 
`
const CashbackAnimatedIcon = (props) => {
    return <CashBackIconContainer width={'22px'}>
        <CashBackArrow/>
        <CashBackInside/>
    </CashBackIconContainer>

}

export default CashbackAnimatedIcon
