import React from 'react'
import { ExtraStatusMessage, HistoryDateColumn, IconColumn, OperationColumn, StyledImgIcon, StyledLi, ValueColumn } from './_baseComponents'
import { useTranslation } from 'react-i18next'
import { formatDate, getValueCentsInCurrencyFormat } from '../../utils'
import cashbackIcon from '../../images/profile/cashback_inside.svg'
import cashBackIcon from '../../images/profile/cashback.svg'


const RT_RECEIVED = 'receive'
const RT_REDEEMED = 'redeem'

const ListItemCashbackEvent = (props) => {
    const { t } = useTranslation()
    const { historyEvent } = props
    const redeemEvent = (historyEvent.requestType === RT_REDEEMED)
    const TEMP_POINT_VALUE = 10 //TODO get it from company - currently event doesnt have the info

    return <>
        <StyledLi>
            <OperationColumn> {(historyEvent.requestType === RT_RECEIVED) ? '+' : '-'} </OperationColumn>
            <ValueColumn> {historyEvent.amount} </ValueColumn>
            <IconColumn>
                <StyledImgIcon src={cashbackIcon}/>
                {(redeemEvent) && <StyledImgIcon src={cashBackIcon}/>}
            </IconColumn>
            <HistoryDateColumn>
                {`${formatDate(new Date(historyEvent.updatedAt), t('profile.historyDateFormat'))}`} {t('profile.historyDateFormatClock')}
                {(redeemEvent) && <ExtraStatusMessage>{t('profile.cashback.redeemToVoucherEvent', {
                    amountCents: getValueCentsInCurrencyFormat(historyEvent.amount * TEMP_POINT_VALUE),
                })}</ExtraStatusMessage>}
            </HistoryDateColumn>
        </StyledLi>
    </>
}

export default ListItemCashbackEvent
