import React from 'react'
import styled from 'styled-components'
import OverlayPopup from '../OverlayPopup'
import cashbackIcon from '../../images/profile/cashback_icon.svg'
import IconLabel from '../IconLabel'
import Button from '../Button'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { setRedirectAfterLogin } from '../../actions/appStateActions'

const DefaultPopupBody = styled.div`
  padding: 20px 10px;
  margin: auto;
  font-size: 1.0em;
  font-weight: 300;
`

const Wrapper = styled.div`
  font-size: ${props => props.theme.fontSize}; //reset relative fonts
`

const TitleContainer = styled.div`
  width: 100%;
`

const StyledIconLabel = styled(IconLabel)`
  padding-left: 5px;
  font-size: 1.4em;
  font-weight: 600;
  width: 90%;
  margin: auto;
`

const HintText = styled.p`
  font-size: 0.8em;
  padding: 30px 0px;
  color: #3e3e3e;
  text-align: center;
`

const StyledDefaultBody = styled(DefaultPopupBody)`
  padding: 0px;
`

const StyledButton = styled(Button)`
  width: 80%;
  margin: auto;
  background: ${props => (!props.disabled) ? props.theme.profile.cashbackGradient : 'lightgray'};
`

const SignUpPopupActionWrapper = (props) => {
    const { showPopup, earnedCashbackPoints, hidePopupFunction, customInfo, children } = props
    const { t } = useTranslation()
    const history = useHistory()
    const { isAuthenticated } = useAuth0()
    const restaurant = useSelector(state => state.restaurant)
    const { loginWithRedirect } = useAuth0()
    const dispatch = useDispatch()

    const loginWithReturnUrl = async () => {
        const return_url = '/profile/companies/' + restaurant.companyId // : window.location.pathname
        await dispatch(setRedirectAfterLogin(return_url))
        loginWithRedirect({ screen_hint: 'signup' })
    }

    const ctaParams = (isAuthenticated) ? {
        action: () => history.push('/profile/companies/' + restaurant.companyId),
        label: t('profile.actions.pushToCompanyLink'),
    } : {
        action: () => loginWithReturnUrl(),
        label: t('profile.actions.signup.getpoints'),
    }

    return (
        <Wrapper>
            {children}
            {showPopup && <>
                <OverlayPopup onOutsideClick={hidePopupFunction} style={{ padding: '20px 10px' }}>

                    {earnedCashbackPoints && <TitleContainer><StyledIconLabel icon={cashbackIcon}>
                        {earnedCashbackPoints} {'Cashback-Punkte'}
                    </StyledIconLabel></TitleContainer>}
                    {(customInfo) && customInfo}
                    <StyledDefaultBody>
                        <HintText>{'Mit stampayGO Cashback-Punkte sammeln und gegen Guthaben eintauschen'}</HintText>
                    </StyledDefaultBody>
                    <StyledButton variant={'small'}
                                  onClick={ctaParams.action}>
                        {ctaParams.label}
                    </StyledButton>
                </OverlayPopup>
            </>}

        </Wrapper>
    )
}


export default SignUpPopupActionWrapper
