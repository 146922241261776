import React from 'react'
import PropTypes from 'prop-types'
import Container from '../components/Container'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Headline from '../components/Headline'
import Footer from '../components/Footer'
import { ReactComponent as LoadingAnimation } from '../images/bars.svg'
import Button from '../components/Button'
import { useHistory } from 'react-router-dom'

const StyledImage = styled(LoadingAnimation)`
  fill: ${props => props.theme.mainColor};
`

const Processing = (props) => {
    const { children, text, showRestart } = props
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <>
            <Container centered>
                {children}
                <StyledImage/>
                <Headline as="h4">
                    {text ? text : t('loadingScreen.title')}
                </Headline>
            </Container>
            {showRestart ? <Footer>
                    <Button onClick={() => history.replace('/wizard/step-1')}>
                        {t('paymentFailed.buttonText')}
                    </Button>
                </Footer> :
                <Footer/>
            }
        </>
    )
}

Processing.propTypes = {
    text: PropTypes.string,
    showRestart: PropTypes.bool,
}

export default Processing
