import API, { URLs } from '../api'

export const postPaymentIntent = (data) => {
    return API.post(URLs.paymentIntents, {
        paymentIntent: data,
    }, { headers: { 'Key-Inflection': 'camel' } })
}

export const patchPaymentIntent = (paymentIntent) => {
    return API.patch(`${URLs.paymentIntents}/${paymentIntent.id}`, {
        paymentIntent: paymentIntent,
    }, { headers: { 'Key-Inflection': 'camel' } })
}

export function getPaymentIntent (paymentIntentId) {
    return API.get(`${URLs.paymentIntents}/${paymentIntentId}`, { headers: { 'Key-Inflection': 'camel' } })
}

export const deletePaymentIntent = (paymentIntentId) => {
    return API.delete(`${URLs.paymentIntents}/${paymentIntentId}`)
}
