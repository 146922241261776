import React from 'react'
import { useSelector } from 'react-redux'
import { DetailContainer, LoyaltyHistoryList } from '../../components/profileadmin/_baseComponents'
import ListItemTransactionHistory from '../../components/profileadmin/ListItemTransactionHistory'
import FeatureTitle from '../../components/profileadmin/FeatureTitle'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import bgIllustration from '../../images/profile/illustration_zahlungen.svg'


const ViewPayments = (props) => {
    const company = useSelector(state => state.company)
    const historyArray = company.transactions
    const { t } = useTranslation()
    const theme = useTheme()
    return (<DetailContainer>
            <div style={{ padding: '20px' }}>
                <FeatureTitle color={theme.profile.PaymentsPrimaryColor}
                              infoTitle={t('profile.payments.info.title')}
                              infoDescription={t('profile.payments.info.description')}>
                    {t('profile.payments.title')}
                </FeatureTitle>
            </div>
            <LoyaltyHistoryList bgIllustration={bgIllustration} title={t('profile.payments.historyTitle')}>
                {historyArray.map((e, index) => <ListItemTransactionHistory key={index} historyEvent={e} lastItem={(index + 1 >= historyArray.length)}/>)}
            </LoyaltyHistoryList>
        </DetailContainer>
    )
}

export default ViewPayments
