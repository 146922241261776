import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CompanyLoyaltyDetail from './CompanyLoyaltyDetail'
import { useHistory, useParams } from 'react-router-dom'
import Processing from '../Processing'
import { fetchCompany } from '../../actions/companyActions'

const ProfileCompany = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const company = useSelector(state => state.company)

    useEffect(() => {
        (async function () {
            try {
                dispatch(fetchCompany(id))
            } catch (e) {
                console.error(e)
                dispatch(history.push('/'))
            }
        })()
    }, [])

    return company.id ? <CompanyLoyaltyDetail/> : <Processing/>
}

export default ProfileCompany
