import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import { useDispatch, useSelector } from 'react-redux'
import Container from '../components/Container'
import styled, { useTheme } from 'styled-components'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button'
import { useTracker } from '../hooks'
import Processing from './Processing'
import { fetchVoucherCard, setVoucherNumberCode, setVoucherQrCode } from '../actions/voucherCardActions'
import { createVoucherRequest, setPin } from '../actions/voucherRequestActions'
import Headline from '../components/Headline'
import giftCardIcon from '../images/voucher/gift_card_icon.svg'
import shieldIcon from '../images/voucher/shield_icon.svg'
import { ReactComponent as noCameraAccessImg } from '../images/voucher/no_camera_access_img.svg'
import Label from '../components/Label'
import IconLabel from '../components/IconLabel'
import InputMask from 'react-input-mask'
import ReactCodeInput from 'react-code-input'
import { fetchTransaction } from '../actions/transactionActions'
import { setHideRestaurantInHeader } from '../actions/appStateActions'
import { getValueCentsInCurrencyFormat } from '../utils'

const HeadlineQRScreen = styled(Headline)` 
    z-index: 20;
    color: ${props => props.theme.voucherBoxTextColor};
    font-size: 1.0em;
    margin-bottom: 20px;
    @media (min-width: ${props => props.theme.desktop}) {
      color: ${props => props.theme.focalWidgetTextColor};
      position: absolute;
      top: 0px;
    }
`

const HeadlineNoCameraAccessScreen = styled(Headline)` 
    color: ${props => props.theme.voucherBoxTextColor};
    width: 86%;
    line-height: 30pt;
    font-size: 1.0em;
    text-align: center;
`

const QrCodeContainer = styled.div`
  width: 100%;
  max-width: 460px;
  margin:auto;
  
  @media (min-width: ${props => props.theme.desktop}) {
      max-width: 100%;
    }
`
const ViewFinder = styled.div`
  position: absolute;
  z-index: 10;
  border: 50px solid rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 460px;
  transform: translateY(-100%);
  boxShadow: inset 0 0 0 5px rgba(255, 0, 0, 1.0);
  @media (min-width: ${props => props.theme.desktop}) {
    max-width: 100%;
  }
    
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`

const ManualInput = styled(InputMask)`
  padding: 10px 0.2em;
  font-size: 1.1em;
  
  margin: unset;
  text-align: center;
  color: ${props => props.theme.voucherBoxTextColor};
  border-style:solid;
  border-radius: ${props => props.theme.borderRadius};
  border-color: ${props => props.theme.cardInputBorderColor};
  &:focus {
     outline: none;
   }
`

const ErrorBox = styled.div`
  color: red;
  font-size: 0.8em;
  margin-top: 10px;
`

const TopSessionDiv = styled.div`
  min-height: 100px;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  padding-top: 50px;
  margin-bottom: 50px;
  width: 100%;
`

const NoCameraAccessImg = styled(noCameraAccessImg)`
      max-width: 30%;
      height: auto;
      margin: auto;
      ${props => props.square && `
        width: 100%;
        height: 100%;
    `};
`

const SecondaryButton = styled(Button)`
  background-color: ${props => props.theme.voucherSecondaryButtonColor};
  color: ${props => props.theme.voucherBoxTextColor};
`

const StyledContainer = styled(Container)`
  padding: 0;
`

const StepReadStampayCard = ({ wizard }) => {
    const [hasCameraAccess, setHasCameraAccess] = useState(true)
    const [showQrCodeNumberInput, setShowQrCodeNumberInput] = useState(false)
    const [voucherRequestError, setVoucherRequestError] = useState(false)
    const [voucherCardInputError, setVoucherCardInputError] = useState(false)
    const voucherCard = useSelector(state => state.voucherCard)
    const voucherBalance = useSelector(state => state.voucherBalance)

    useTracker(null)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const xhrInProgress = useSelector(state => state.appState.xhrInProgress)
    const theme = useTheme()

    const onCardRead = async (result) => {
        await dispatch(setVoucherQrCode(result))
        await dispatch(fetchVoucherCard())
    }

    const onPinChange = async (event) => {
        const pin = event
        if (pin.length >= 4) {
            try {
                await dispatch(setPin(pin))
                await dispatch(createVoucherRequest())
                await dispatch(fetchTransaction())
                wizard.push('step-2')
            } catch (e) {
                setVoucherRequestError(true)
            }
        }
    }

    const handleScan = (result) => {
        if (result) {
            onCardRead(result)
        }
    }

    const handleError = (err) => {
        console.error(err)
        setHasCameraAccess(false)
    }

    const openQrCodeNumberInput = () => {
        setShowQrCodeNumberInput(true)
    }

    const onQrCodeNumberChange = async (event) => {
        let cardNumberManualInput = event.target.value
        const cardNumber = cardNumberManualInput.replace(/-/g, '').replace(/ /g, '')
        if (cardNumber.length >= 16) {
            createVoucherCardWithCardNumber(cardNumber)
        }
    }

    const createVoucherCardWithCardNumber = async (cardNumber) => {
        try {
            await dispatch(setVoucherNumberCode(cardNumber))
            await dispatch(fetchVoucherCard())
        } catch (e) {
            setVoucherCardInputError(true)
        }
    }
    dispatch(setHideRestaurantInHeader(false))
    if (xhrInProgress) {
        dispatch(setHideRestaurantInHeader(true))
        return <Processing/>
    }

    const getManualInput = (withManualNumberInput = false) => {
        dispatch(setHideRestaurantInHeader(false))
        if (withManualNumberInput) {
            return <Container>
                <TopSessionDiv>
                    <IconLabel weight='500' icon={giftCardIcon}>{t('voucherBox.enterCardNumber')}</IconLabel>
                    <ManualInput mask="9999 - 9999 - 9999 - 9999" alwaysShowMask={false} maskChar=" " type="tel"
                                 onChange={(onQrCodeNumberChange)}
                                 autoComplete={'off'}
                                 autoCorrect={'off'}
                                 spellCheck={'off'} autoFocus/>
                    {(voucherCardInputError) && <ErrorBox>{t('voucherBox.wrongNumberCode')}</ErrorBox>}
                </TopSessionDiv>
            </Container>
        } else {
            const pinInputProps = {
                inputMode: 'numeric',
                autoFocus: true,
                inputStyle: {
                    display: 'inline-block',
                    fontWeigh: '500',
                    margin: '-2px',
                    MozAppearance: 'textfield',
                    width: '1.8em',
                    borderRadius: '3px',
                    fontSize: '1.8em',
                    height: '1.8em',
                    paddingLeft: '0',
                    textAlign: 'center',
                    outline: 'none',
                    borderStyle: 'solid',
                    borderColor: theme.cardInputBorderColor,
                },
            }

            const getFormattedCardNumber = () => {
                let formattedCardNumber = ''
                if (!voucherCard.numberCode) {
                    return '...'
                }
                for (let i = 0; i < 16; i += 4) {
                    formattedCardNumber += voucherCard.numberCode.slice(i, i + 4)
                    if (i < 12) {
                        formattedCardNumber += '-'
                    }

                }
                return formattedCardNumber
            }

            if (voucherBalance.amountCents <= 0) {
                // TODO: Show error message if card has not enough money
                return <Container>
                    <TopSessionDiv>
                        <IconLabel weight='lighter' icon={giftCardIcon} style={{ marginBottom: '0' }}>{t('voucherBox.cardNumber')}: {getFormattedCardNumber()}</IconLabel>
                        <IconLabel align={'left'}>{t('voucherBox.voucher')}: {getValueCentsInCurrencyFormat(voucherBalance.amountCents)}</IconLabel>
                    </TopSessionDiv>
                </Container>
            }

            return <Container>
                <TopSessionDiv>
                    <IconLabel weight='lighter' icon={giftCardIcon} style={{ marginBottom: '0' }}>{t('voucherBox.cardNumber')}: {getFormattedCardNumber()}</IconLabel>
                    <IconLabel align={'left'}>{t('voucherBox.voucher')}: {getValueCentsInCurrencyFormat(voucherBalance.amountCents)}</IconLabel>
                </TopSessionDiv>

                <IconLabel icon={shieldIcon} weight='500'>{t('voucherBox.enterPin')}</IconLabel>
                <ReactCodeInput type='number' fields={4} {...pinInputProps} onChange={onPinChange}
                                autoFocus={!withManualNumberInput}
                                autoComplete={'off'}
                                autoCorrect={'off'}
                                spellCheck={'off'}/>
                {(voucherRequestError) && <ErrorBox>{t('voucherBox.wrongPin')}</ErrorBox>}
            </Container>
        }
    }

    const getQrCodeCameraInput = () => {
        dispatch(setHideRestaurantInHeader(true))
        return <>
            {(hasCameraAccess) ? <>
                    <StyledContainer>
                        <HeadlineQRScreen as="h3">{t('voucherBox.readQrCodeHint')}</HeadlineQRScreen>
                        <QrCodeContainer>
                            <QrReader
                                delay={300}
                                onError={(err) => handleError(err)}
                                onScan={(result) => handleScan(result)}
                                style={{ width: '100%' }}
                                showViewFinder={false}
                            />
                            <ViewFinder/>
                        </QrCodeContainer>
                    </StyledContainer>
                    <Footer>
                        <Label>{t('voucherBox.or')}</Label>
                        <SecondaryButton onClick={() => openQrCodeNumberInput()}>{t('voucherBox.qrManualInput')}</SecondaryButton>
                    </Footer>
                </>
                : <>
                    <StyledContainer>
                        <HeadlineNoCameraAccessScreen as="h3">{t('camera.accessDeniedCard')}</HeadlineNoCameraAccessScreen>
                        <NoCameraAccessImg/>
                    </StyledContainer>
                    <Footer>
                        <Button onClick={() => openQrCodeNumberInput()}>{t('voucherBox.qrManualInput')}</Button>
                    </Footer>
                    {/*TODO container and footer background color will use default colors when design changes */}
                </>}
        </>

    }

    const getCardInput = () => {
        if (voucherCard.qrCode) {
            return getManualInput()
        } else if (showQrCodeNumberInput) {
            return getManualInput(true)
        } else {
            return getQrCodeCameraInput()
        }
    }
    const cardInput = getCardInput()
    return (
        <>
            {cardInput}
        </>
    )
}

export default StepReadStampayCard

