import React from 'react'
import Container from '../components/Container'
import Header from '../components/Header'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button'
import { ReactComponent as MaintenanceTool } from '../images/maintenance.svg'
import Footer from '../components/Footer'
import ErrorBoundary from '../components/ErrorBoundary'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTracker } from '../hooks'
import Text from '../components/Text'
import styled from 'styled-components'
import Figure from '../components/Figure'

const StyledMaintenance = styled(MaintenanceTool)`
  @media (min-width: ${props => props.theme.desktop}) {
    path {
      fill: ${props => props.theme.mainColorDesktop};
    }
  }
`

const Maintenance = () => {
    const restaurant = useSelector(state => state.restaurant)
    const { t } = useTranslation()
    const history = useHistory()
    useTracker('Maintenance')

    const handleClick = (event) => {
        history.push(`/restaurants/${restaurant.id}/feedback`) // this needs to be added to the project
    }

    return (
        <ErrorBoundary>
            <Header onlyLogo/>
            <Container centered>
                <Figure maxWidth="40%">
                    <StyledMaintenance/>
                </Figure>
                <Text margin="20px 0 0 0" dangerouslySetInnerHTML={{ __html: t('maintenance.text') }}/>
            </Container>
            <Footer>
                <Button onClick={handleClick}>
                    {t('maintenance.button')}
                </Button>
            </Footer>
        </ErrorBoundary>
    )
}

export default Maintenance
