import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { setUserMenuOpen } from '../actions/appStateActions'
import { useHistory } from 'react-router'

const AppContainer = styled.div` 
    transform: ${props => props.showUserMenu ? 'translate(-200px, 0px)' : 'translate(0px, 0px)'};
    transition-duration: 0.5s;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
`

const CloseSideMenuArea = styled.div`
    position: absolute;
    top: 0px;
    right:0px;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
`


const AppWithSideMenu = (props) => {
    const { sideMenu, children, ...rest } = props
    const showUserMenu = useSelector(state => state.appState.userMenuOpen)
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <AppContainer showUserMenu={showUserMenu} {...rest} >
            {children}
            {sideMenu}
            {showUserMenu && <CloseSideMenuArea onClick={() => {
                dispatch(setUserMenuOpen(false))
                history.goBack() // the re-render over switch router causes a location change.
            }}/>}
        </AppContainer>
    )
}

export default AppWithSideMenu
