import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { client } from './client'
import { appState } from './appState'
import { restaurant } from './restaurant'
import { paymentData } from './paymentData'
import { wizardState } from './wizardState'
import { transaction } from './transaction'
import { voucherRequest } from './voucherRequest'
import { wirecardRequest } from './wirecardRequest'
import { voucherCard } from './voucherCard'
import { voucherBalance } from './voucherBalance'
import { company } from './company'
import { companies } from './profile'
import { authState } from './authState'
import { stripePaymentIntent } from './stripePaymentIntent'

export default (history) => combineReducers({
    router: connectRouter(history),
    client,
    appState,
    authState,
    restaurant,
    paymentData,
    wizardState,
    company,
    companies,
    transaction,
    voucherCard,
    voucherRequest,
    voucherBalance,
    wirecardRequest,
    stripePaymentIntent,
})
