import { setXHRInProgress } from './appStateActions'
import { deleteVoucherRequest, getVoucherRequest, patchVoucherRequest, postVoucherRequest } from '../services/voucherRequests'
import { REMOVE_VOUCHER_REQUEST, SET_PIN, SET_REQUEST_TYPE, SET_VOUCHER_REQUEST } from './types'
import { errorHandler } from '../errorHandler'

export const setVoucherRequest = (payload) => {
    return {
        type: SET_VOUCHER_REQUEST,
        payload: payload,
    }
}

export const setPin = (payload) => {
    return {
        type: SET_PIN,
        payload: payload,
    }
}

export const setRequestType = (payload) => {
    return {
        type: SET_REQUEST_TYPE,
        payload: payload,
    }
}

export const removeVoucherRequest = () => {
    return {
        type: REMOVE_VOUCHER_REQUEST,
    }
}

export const createVoucherRequest = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const voucherRequest = state.voucherRequest

        try {
            dispatch(setXHRInProgress(true))
            const result = await postVoucherRequest(voucherRequest)
            dispatch(setVoucherRequest(result.data))
            dispatch(setXHRInProgress(false))
            return Promise.resolve(result.data)
        } catch (e) {
            dispatch(setXHRInProgress(false))
            return Promise.reject(e)
        }
    }
}

export const updateVoucherRequest = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const voucherRequest = state.voucherRequest

        try {
            dispatch(setXHRInProgress(true))
            const result = await patchVoucherRequest(voucherRequest.id)
            dispatch(setVoucherRequest(result.data))
            dispatch(setXHRInProgress(false))
            return Promise.resolve(result.data)
        } catch (e) {
            dispatch(setXHRInProgress(false))
            return Promise.reject(e)
        }
    }
}

export const fetchVoucherRequest = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const voucherRequest = state.voucherRequest

        try {
            const result = await getVoucherRequest(voucherRequest.id)

            dispatch(setVoucherRequest(result.data))

            return Promise.resolve(result.data)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

/**
 * Cancels the voucher request on the server
 * And sets id to null in state
 * @return Promise
 */
export const destroyVoucherRequest = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const voucherRequest = state.voucherRequest

        try {
            await deleteVoucherRequest(voucherRequest.id)

            dispatch(removeVoucherRequest())

            return Promise.resolve()
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

export const payWithVoucher = () => {
    return async (dispatch, getState) => {
        try {
            const result = await dispatch(updateVoucherRequest())

            return Promise.resolve(result.data)
        } catch (e) {
            errorHandler(e)
        }
    }
}
