import { CLEAR_COMPANY_FAVORITE, FILTER_COMPANY_LIST, SET_COMPANIES_DATA, SET_COMPANY_FAVORITE } from '../actions/types'
import { FilterType } from '../components/profileadmin/company-list/ListFilter'

const initState = null

export function companies (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_COMPANY_FAVORITE: {
            if (!state) return state

            return state.map(company => (company.id === action.payload.company ? {
                ...company,
                favorite: { id: action.payload.favoriteID },
            } : company))
        }
        case CLEAR_COMPANY_FAVORITE: {
            if (!state) return state

            return state.map(company => (company.id === action.payload ? {
                ...company,
                favorite: null,
            } : company))
        }
        case SET_COMPANIES_DATA: {
            return state = action.payload
        }
        case FILTER_COMPANY_LIST: {
            return state = state.slice().sort((a, b) => {

                switch (action.payload) {
                    case FilterType.favorite:
                        const favA = a.favorite ?? false
                        const favB = b.favorite ?? false
                        return favA > favB ? -1 : 1
                    case FilterType.balance:
                        const amountA = a.voucher.balance.amountCents ?? 0
                        const amountB = b.voucher.balance.amountCents ?? 0
                        return amountA > amountB ? -1 : 1
                    case FilterType.points:
                        const pointsA = a.loyalty.account.amount ?? 0
                        const pointsB = b.loyalty.account.amount ?? 0
                        return pointsA > pointsB ? -1 : 1
                    case FilterType.relevance:
                        const dealsA = a.deals?.length ?? 0
                        const dealsB = b.deals?.length ?? 0
                        return dealsA > dealsB ? -1 : 1
                    default:
                        const nameA = a.name
                        const nameB = b.name
                        return nameA > nameB ? -1 : 1
                }

            })

        }

        default:
            return state
    }
}

export function isFavoriteSelector (state, companyId) {
    if (state.company.id && state.company.id === companyId) {
        return state.company.favorite
    }

    return state.companies.find(company => company.id === companyId)?.favorite ?? false
}
