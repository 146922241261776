import API, { URLs } from '../api'

export const createWirecardRequest = (data) => {
    return API.post(URLs.wirecardRequests, {
        request: data,
    }, { headers: { 'Key-Inflection': 'camel' } })
}

export const updateWirecardRequest = (request) => {
    return API.patch(`${URLs.wirecardRequests}/${request.id}`, {
        request: request,
    }, { headers: { 'Key-Inflection': 'camel' } })
}

export function getWirecardRequest (requestId) {
    return API.get(`${URLs.wirecardRequests}/${requestId}`, { headers: { 'Key-Inflection': 'camel' } })
}

export const deleteWirecardRequest = (requestId) => {
    return API.delete(`${URLs.wirecardRequests}/${requestId}`)
}
