import React from 'react'
import PropTypes from 'prop-types'
import Input from './Input'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isToGoSelector } from '../reducers/restaurant'
import { Field, Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

const StyledForm = styled.form`
  display: ${props => props.hidden ? 'none' : 'block'};
  & input {
    font-size: 4em;
    border-color: ${props => props.theme.mainButtonColor};
    @media (min-width: ${props => props.theme.desktop}) and (-webkit-min-device-pixel-ratio:0) {
      margin-top: 4px;
    }
  }
`

const CurrencyForm = (props) => {
    const { onSubmit, initialValues, autoFocus, onChange, onBlur, onKeyUp, disabled } = props
    const { t } = useTranslation()
    const isToGo = useSelector(state => isToGoSelector(state))

    const required = value => (value ? undefined : t('amount.errors.required'))
    const minValue = min => value => isNaN(value) || value >= min ? undefined : t('amount.errors.invalid')
    const composeValidators = (...validators) => value =>
        validators.reduce((error, validator) => error || validator(value), undefined)

    const formatCurrency = (input) => {
        return (parseFloat(input) / 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    const normalizeCurrency = (value) => {
        return value
    }

    const parseValue = (value) => {
        const clearedValue = value.replace(/,|\.|\D/g, '').replace(/^0*/, '').padStart(3, '0')
        return parseInt(clearedValue)
    }

    const onFocus = (e) => {
        const target = e.target
        const value = e.target.value
        if (parseValue(value) === 0) {
            setTimeout(function () {
                target.setSelectionRange(value.length, value.length)
            }, 0)
        }
    }

    return (
        <Form initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, pristine, reset, submitting }) => (
                <StyledForm onSubmit={handleSubmit} hidden={isToGo && disabled} id="amountForm">
                    <Field
                        name="amount"
                        component={Input}
                        type="tel"
                        autoComplete={'off'}
                        autoCorrect={'off'}
                        spellCheck={'off'}
                        autoFocus={autoFocus}
                        required={true}
                        format={formatCurrency}
                        normalize={normalizeCurrency}
                        parse={parseValue}
                        validate={composeValidators(required, minValue(0))}
                        showTooltip={false}
                        borderVariant="white"
                        inverted
                        disabled={disabled}
                        inputWidth={isToGo ? '60%' : ''}
                        inputVariant="xl"
                        small={isToGo}
                        labelVariant={isToGo ? 'normal' : 'medium'}
                        labelAlign={isToGo ? 'center' : 'left'}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyUp={onKeyUp}
                    />
                    {onChange && <OnChange name="amount">
                        {onChange}
                    </OnChange>}
                </StyledForm>
            )}
        </Form>
    )
}

CurrencyForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    fieldStyle: PropTypes.object,
    hint: PropTypes.string,
    initialValues: PropTypes.object,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    autoFocus: PropTypes.bool,
}

CurrencyForm.defaultProps = {
    disabled: false,
    autoFocus: true,
}

export default CurrencyForm
