import { createCreditCardInfo } from './payment'
import { parseStringPromise } from 'xml2js'

const includeWirecardLib = () => {
    return new Promise((resolve, reject) => {
        if (!window.WirecardPaymentPage) {
            const script = document.createElement('script')
            script.src = process.env.REACT_APP_ENV === 'production' ? 'https://api.wirecard.com/engine/hpp/paymentPageLoader.js' : 'https://api-test.wirecard.com/engine/hpp/paymentPageLoader.js'
            script.async = true
            script.onload = () => {
                resolve()
            }
            script.onerror = err => {
                reject(err)
            }
            document.body.appendChild(script)
        } else {
            resolve()
        }
    })
}

const renderWirecardSeamlessForm = (data, locale) => {
    return new Promise((resolve, reject) => {
        window.WirecardPaymentPage.seamlessRenderForm({
            requestData: { ...data, locale: locale },
            wrappingDivId: 'seamless-credit-card-form',
            onSuccess: resp => {
                resolve(resp)
            },
            onError: err => {
                reject(err)
            },
        })
    })
}

const submitWirecardSeamlessForm = () => {
    return new Promise((resolve, reject) => {
        window.WirecardPaymentPage.seamlessSubmitForm({
            onSuccess: resp => {
                resolve(resp)
            },
            onError: err => {
                if (err.form_validation_result && err.form_validation_result === 'failed') {
                    reject(err)
                } else {
                    // Resolve if the error is not from form validation
                    resolve(err)
                }
            },
        })
    })
}

//*** Thunks ***

/**
 * Renders the wirecard credit card form
 * @param {i18n} i18n
 */
export const renderCreditCardForm = (i18n) => {
    return async (dispatch, getState) => {
        const state = getState()
        const wirecardRequest = state.wirecardRequest
        const data = await xmlRequestToJson(wirecardRequest.request)

        try {
            await includeWirecardLib()

            return renderWirecardSeamlessForm(data, i18n.language)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

export const submitCreditCardForm = () => {
    return async (dispatch, getState) => {
        const store = getState()
        const transaction = store.transaction

        try {
            const resp = await submitWirecardSeamlessForm()
            await createCreditCardInfo(resp, transaction.wirecardRequestId)

            if (resp.acs_url) {
                const parser = new DOMParser()
                const doc = parser.parseFromString(resp.acs_url, 'text/html')

                const form = document.createElement('form')
                form.method = 'POST'
                form.action = doc.body.innerText
                form.enctype = 'application/x-www-form-urlencoded'
                form.id = 'acsform'

                var input = document.createElement('input')
                input.type = 'hidden'
                input.name = 'PaReq'
                input.value = parser.parseFromString(resp.pareq, 'text/html').body.innerText
                form.appendChild(input)

                input = document.createElement('input')
                input.type = 'hidden'
                input.name = 'TermUrl'
                input.value = process.env.REACT_APP_ENV === 'production' ? `https://api.wirecard.com/engine/rest/hpp/acs/${resp.transaction_id}/` : `https://api-test.wirecard.com/engine/rest/hpp/acs/${resp.transaction_id}/`
                form.appendChild(input)

                input = document.createElement('input')
                input.type = 'hidden'
                input.name = 'MD'
                input.value = `merchant_account_id=${resp.merchant_account_id}&transaction_type=${resp.transaction_type}&nonce3d=${resp.nonce3d}`
                form.appendChild(input)

                document.body.appendChild(form)

                form.submit()
            }
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

function toCamelCase (str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

const xmlRequestToJson = async (xml) => {
    try {
        const result = await parseStringPromise(
            xml,
            { tagNameProcessors: [toCamelCase], explicitArray: false },
        )
        const payment = result.payment

        const wirecardRequestData = {
            request_id: payment.requestId,
            request_time_stamp: payment.requestTimeStamp,
            merchant_account_id: payment.merchantAccountId,
            transaction_type: payment.transactionType,
            requested_amount: payment.requestedAmount._,
            requested_amount_currency: payment.requestedAmount.$.currency,
            request_signature: payment.requestSignature,
            payment_method: payment.paymentMethods.paymentMethod.$.name,
            attempt_three_d: payment.attemptThreeD,
            three_d_version: payment.threeDVersion,
            cancel_redirect_url: payment.cancelRedirectUrl,
            success_redirect_url: payment.successRedirectUrl,
            fail_redirect_url: payment.failRedirectUrl,
            notification_url_1: payment.notifications.notification.$.url,
            notifications_format: payment.notifications.$.format,
        }

        return Promise.resolve(wirecardRequestData)
    } catch (e) {
        console.error(e)
    }
}
