import React from 'react'
import styled from 'styled-components'
import Container from '../components/Container'
import Header from '../components/Header'
import { Trans, useTranslation } from 'react-i18next'
import Button from '../components/Button'
import { useHistory } from 'react-router-dom'
import Footer from '../components/Footer'
import { useSelector } from 'react-redux'
import ErrorBoundary from '../components/ErrorBoundary'
import { useTracker } from '../hooks'
import Text from '../components/Text'
import Figure from '../components/Figure'
import { ReactComponent as ErrorIcon } from '../images/error_icon.svg'

const StyledFigure = styled(Figure)`
  margin: 100px auto;
  max-width: 120px;
`

const StyledErrorIcon = styled(ErrorIcon)`
  fill: ${props => props.theme.highlight};
 `

const PaymentFailed = () => {
    const paymentData = useSelector(state => state.paymentData)
    const { t } = useTranslation()
    const history = useHistory()
    useTracker('Payment failed')

    let button = ''
    let header = ''
    if (paymentData?.paymentGatewayData?.cancelReturnUrl) {
        button = <a href={paymentData.paymentGatewayData.cancelReturnUrl}>
            <Button>
                {t('paymentFailed.buttonTextPgm')}
            </Button>
        </a>
    } else {
        button =
            <Button onClick={() => history.push('/wizard/step-1')}>
                {t('paymentFailed.buttonText')}
            </Button>
        header = <Header onlyLogo/>
    }

    return (
        <ErrorBoundary>
            {header}
            <Container centered>
                <StyledFigure maxWidth="30%">
                    <StyledErrorIcon/>
                </StyledFigure>
                <Text variant="size-5">
                    <Trans i18nKey={'paymentFailed.message'}/>
                </Text>
            </Container>
            <Footer>
                {button}
            </Footer>
        </ErrorBoundary>
    )
}

export default PaymentFailed
