import React from 'react'
import Container from '../components/Container'
import styled, { keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Footer from '../components/Footer'
import Button from '../components/Button'
import { useTracker } from '../hooks'
import Headline from '../components/Headline'
import { ReactComponent as emailIcon } from '../images/email_icon.svg'

const rotation = keyframes`
  from {
    transform: rotate(0deg);
    right: 100%;
  }
  to {
    transform: rotate(360deg);
    right: 0;
  }
`

const Envelope = styled(emailIcon)`
  width: 174px;
  height: 138px;
  animation: ${rotation} 1s;
  position: relative;
  margin-top: 50px;
  
  @media (min-width: ${props => props.theme.desktop}) {
    path {
      fill: ${props => props.theme.mainColorDesktop};
    }
  }
`

const EmailSentContainer = styled(Container)`
  marginTop: '20px';
  align-items: center;
`

const StepEmailSent = (props) => {
    useTracker(null)
    const { t } = useTranslation()

    return (
        <>
            <EmailSentContainer centered>
                <Headline as="h3" fontWeight="400">
                    {t('email.title')}
                </Headline>
                <Envelope>
                    <path
                        fill="#fff"
                        fillRule="nonzero"
                        d="M156.6 0H17.4C7.787 0 .087 7.72.087 17.25L0 120.75C0 130.28 7.787 138 17.4 138h139.2c9.614 0 17.4-7.72 17.4-17.25V17.25C174 7.72 166.214 0 156.6 0zm0 34.5L87 77.625 17.4 34.5V17.25L87 60.375l69.6-43.125V34.5z"
                    />
                </Envelope>
            </EmailSentContainer>
            <Footer>
                <Button onClick={() => props.wizard.history.goBack()}>
                    {t('email.button')}
                </Button>
            </Footer>
        </>
    )
}

export default StepEmailSent
