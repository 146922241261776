import React, { Component } from 'react'
import { airbrake } from '../airbrake'
import PaymentFailed from '../views/PaymentFailed'

class ErrorBoundary extends Component {
    constructor (props) {
        super(props)
        this.state = { hasError: false, error: null }
    }

    componentDidCatch (error, errorInfo) {
        this.setState({ hasError: true, error: error })
        airbrake.notify({
            error: error,
            params: { info: errorInfo },
        })
    }

    render () {
        if (this.state.hasError) {
            return (
                <PaymentFailed/>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
