import { CLEAR_AUTH_TOKEN, SET_AUTH_TOKEN } from '../actions/types'

const initState = {
    authToken: null,
}

export function authState (
    state = initState,
    action,
) {
    switch (action.type) {

        case SET_AUTH_TOKEN: {
            return { ...state, authToken: action.payload }
        }

        case CLEAR_AUTH_TOKEN: {
            return { ...state, authToken: null }
        }

        default:
            return state
    }
}
