import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Overlay = styled.div`
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.7);
  z-index: 9000;
  align-items: center;
  justify-content: center;
  font-size: 0.95em;
  & a, a:hover, a:visited {
    color: black;
    text-decoration: none;
  }
  display: flex;
  align-items: center;
  align-content: center;
`

const Popup = styled.div`
  position: relative;
  z-index: 9001;
  margin:auto;
  width: 80%;
  max-width: 360px;
  height: max-content;
  background: white; //TODO theme color
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  border-radius: 6px;
`

const OverlayPopup = (props) => {
    const { onOutsideClick, children, ...rest } = props
    return (
        <Overlay onClick={onOutsideClick}>
            <Popup onClick={(e) => { e.stopPropagation() }} {...rest}>
                {children}
            </Popup>
        </Overlay>
    )
}

OverlayPopup.propTypes = {
    onOutsideClick: PropTypes.object,
}

export default OverlayPopup
