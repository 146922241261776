import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as actionFlame } from '../../../images/profile/cashback_icon.svg'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const StyledPointsContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0.5em 0 0.5em;
    font-size: 0.9em;
`

export const StyledActionFlame = styled(actionFlame)`
  height: 30px;
  width: 30px;
  margin: 0.5em;
  fill: ${props => props.hot ? props.theme.boldActionColor : props.theme.mainColor};
`

const PointsDisplay = (props) => {

    const { loyalty } = props
    const amount = loyalty?.account?.amount || 0
    const threshold = loyalty?.configuration?.threshold || 0
    const { t } = useTranslation()

    return <StyledPointsContainer>
        <StyledActionFlame hot={amount >= threshold ? 1 : 0}/>
        <div style={{ textAlign: 'left', flexGrow: '1' }}>
            <div style={{ letterSpacing: '1px' }}>
                <span style={{ fontWeight: '500' }}>{amount} | </span>
                <span style={{ color: '#000' }}>{threshold}</span>
            </div>
            <span>{t('profile.cashback.points')}</span>
        </div>
    </StyledPointsContainer>
}

PointsDisplay.propTypes = {
    loyalty: PropTypes.object.isRequired,
}

export default PointsDisplay

