import { CLEAR_STRIPE_PAYMENT_INTENT_STATE, REMOVE_STRIPE_PAYMENT_INTENT, SET_STRIPE_PAYMENT_INTENT } from './types'
import { postPaymentIntent } from '../services/paymentIntents'

export const setStripePaymentIntent = (payload) => {
    return {
        type: SET_STRIPE_PAYMENT_INTENT,
        payload: payload,
    }
}

export const removeStripePaymentIntent = () => {
    return {
        type: REMOVE_STRIPE_PAYMENT_INTENT,
    }
}

export const clearStripePaymentIntent = () => {
    return {
        type: CLEAR_STRIPE_PAYMENT_INTENT_STATE,
    }
}

// Thunk actions (async)

export const createPaymentIntent = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const transaction = state.transaction

        try {
            const result = await postPaymentIntent({ transactionId: transaction.id })

            dispatch(setStripePaymentIntent(result.data))

            return Promise.resolve(result.data)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

// export const fetchWirecardRequest = () => {
//     return async (dispatch, getState) => {
//         const state = getState()
//         const wirecardRequest = state.wirecardRequest
//
//         try {
//             const result = await getWirecardRequest(wirecardRequest.id)
//
//             dispatch(setWirecardRequest(result.data))
//
//             return Promise.resolve(result.data)
//         } catch (e) {
//             if (e.message === 'Network Error') {
//                 //for now we need this as a quick fix for a safari and firefox bug
//                 //https://bugzilla.mozilla.org/show_bug.cgi?id=1280189
//                 return Promise.resolve(wirecardRequest)
//             } else {
//                 return Promise.reject(e)
//             }
//         }
//     }
// }

/**
 * Cancels the voucher request on the server
 * And sets id to null in state
 * @return Promise
 */
// export const destroyWirecardRequest = () => {
//     return async (dispatch, getState) => {
//         const state = getState()
//         const wirecardRequest = state.wirecardRequest
//
//         try {
//             await deleteWirecardRequest(wirecardRequest.id)
//             dispatch(fetchWirecardRequest())
//             dispatch(removeWirecardRequest())
//
//             return Promise.resolve()
//         } catch (e) {
//             return Promise.reject(e)
//         }
//     }
// }
