import React from 'react'

import { ReactComponent as balanceIcon } from '../../../images/profile/voucher_balance.svg'
import styled from 'styled-components'
import { getValueCentsInCurrencyFormat } from '../../../utils'
import { useTranslation } from 'react-i18next'

const StyledBalanceContainer = styled.div`
    height: 100%;
    display: flex;
    padding: 0 0.5em 0 0.5em;
    align-items: center;
    font-size: 0.9em;
`

const StyledP = styled.p`
    margin:0;
    padding:0;
`

const StyledBalanceIcon = styled(balanceIcon)`
  height: 30px;
  width: 30px;
  margin: 0.5em;
  //fill: ${props => props.isHot ? props.theme.boldActionColor : props.theme.profile.cashbackPrimaryColor};
`

const BalanceDisplay = (props) => {
    const { voucher } = props
    const { t } = useTranslation()
    const balance = voucher?.balance || 0
    const amountCents = balance?.amountCents || 0

    return <StyledBalanceContainer>
        <StyledBalanceIcon/>
        <div style={{ flexGrow: '1' }}>
            <div style={{ textAlign: 'left', fontWeight: '500' }}>
                <StyledP>{getValueCentsInCurrencyFormat(amountCents || 0)}</StyledP>
                <StyledP>{t('profile.voucher.shortTitle')}</StyledP>
            </div>
        </div>
    </StyledBalanceContainer>
}


export default BalanceDisplay

