import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledImage = styled.img`
  height: auto;
  max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
  
  ${props => props.square && `
    width: 100%;
    height: 100%;
`};
  
  @media (min-width: ${props => props.theme.desktop}) {
    ${props => props.maxWidthDesktop && `max-width: ${props.maxWidthDesktop};`};
  }
`

const Image = ({ children, maxWidth, ...rest }) => {
    return <StyledImage maxWidth={maxWidth} {...rest}>{children}</StyledImage>
}

Image.propTypes = {
    maxWidth: PropTypes.string,
    maxWidthDesktop: PropTypes.string,
    square: PropTypes.bool,
}

export default Image
