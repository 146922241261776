import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import { ReactComponent as Arrow } from '../images/arrow_pay.svg'

const bounce = keyframes`
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
`

const Bounce = styled.div`
  position:fixed;
  left:50%;
  bottom: ${props => props.bottom};
  margin-top:-35px;
  margin-left:-25px;
  height:50px;
  width:50px;
  animation: ${bounce} 1s infinite;
`

const ScrollIndicator = (props) => {
    const { bottom } = props

    return (
        <Bounce bottom={bottom}>
            <Arrow/>
        </Bounce>
    )
}

ScrollIndicator.propTypes = {
    bottom: PropTypes.string,
}

ScrollIndicator.defaultProps = {
    bottom: '0',
}

export default ScrollIndicator
