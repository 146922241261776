import React from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getValueCentsInCurrencyFormat } from '../utils'

const baseInnerContainerClass = css`
  vertical-align: middle;
  height: 100px;
`
const PaymentBoxContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 110px;
    padding-bottom:10px;
    color: ${props => props.theme.voucherBoxTextColor};
    background-color: ${props => props.theme.headerBottomColor};
`
const LeftContainer = styled.div`
    ${baseInnerContainerClass}
    justify-content: center;
    background: ${props => props.theme.voucherBoxBackgroundColor};
    width:50%;
    display: flex;
    flex-flow: column;
`
const RightContainer = styled.div`
    ${baseInnerContainerClass};
    justify-content: center;
    width:50%;
    background: ${props => props.theme.voucherBoxBackgroundColor};
    display: flex;
    flex-flow: column;
`
const ContainerValue = styled.div`
    text-align: ${props => (props.right) ? 'right' : 'left'};
    font-weight: 500;
    font-size: 1.1em;
    ${props => (props.right) ? 'margin-right: 24px;' : 'margin-left: 24px;'};
    
    & span {
      border-bottom: 5px solid ${props => props.theme.actionColor};
      display: inline-block;
      width: max-content;
      height: 35px;
      ${props => (!props.right) && 'border:none'};
    }
`

const ParentContainer = styled.div`
    display: block;
`

const StampayNoVoucherBox = () => {
    const transaction = useSelector(state => state.transaction)
    const { t } = useTranslation()

    return (
        <ParentContainer> {/*TODO explore solution with flex columns and Bulma*/}
            <PaymentBoxContainer>
                <LeftContainer>
                    <ContainerValue>
                        <span>{t('voucherBox.openAmount')}</span>
                    </ContainerValue>
                </LeftContainer>
                <RightContainer>
                    <ContainerValue right={true}>
                        <span>{getValueCentsInCurrencyFormat(transaction.remainingAmountCents)}</span>
                    </ContainerValue>
                </RightContainer>
            </PaymentBoxContainer>
        </ParentContainer>
    )
}


export default StampayNoVoucherBox
