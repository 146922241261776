import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as userProfileIcon } from '../images/profile/user_profile.svg'
import { Link } from 'react-router-dom'
import Figure from './Figure'
import { setRedirectAfterLogin, setUserMenuOpen } from '../actions/appStateActions'
import { useDispatch } from 'react-redux'

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  font-size: 10px; 
  margin: 8px 4px;
  text-indent: -9999em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background: #000;
  background: linear-gradient(to right, #000 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: ${Spin} 1.4s infinite linear;
  transform: translateZ(0);
  float: right;
  
  &:before {
      width: 50%;
      height: 50%;
      background: #000;
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
  }
  &:after {
      background: #fff;
      width: 75%;
      height: 75%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
  }
`

const ProfileDropDownMenu = styled.div`
  width: 30px;
  height: 36px;
  padding: 3px 0;
  float: right;
  display: flex;
  align-content: center; 
  align-items: center;
  position: relative;
  z-index: 100; 
`

const StyledUserProfileIcon = styled(userProfileIcon)`
  margin-left: 2px;
  
  path:nth-of-type(2){ 
    fill: ${props => (props.loggedin === 'true') ? 'green' : 'black'}
  }
  path:nth-of-type(3){ 
    fill: ${props => (props.loggedin === 'true') ? 'green' : 'black'}
  }
  
`

const AvatarImg = styled.img`
  border-radius: 100%;
`

const IconLink = styled(Link)`
  margin:auto;
  z-index: 101;
`

const ProfileIconWrapperFigure = styled(Figure)`
  display: flex;
`


const ProfileButton = (props) => {
    const { loginWithRedirect } = useAuth0()
    const { user, isAuthenticated, isLoading } = useAuth0()
    const dispatch = useDispatch()

    const loginWithReturnUrl = async () => {
        await dispatch(setRedirectAfterLogin(window.location.pathname))
        loginWithRedirect()
    }

    return (
        <>
            {
                (isLoading) ?
                    <Loader/> :
                    <ProfileDropDownMenu onClick={() => (isAuthenticated) ? dispatch(setUserMenuOpen(true)) : loginWithReturnUrl()}>
                        <IconLink to={'#'}>
                            <ProfileIconWrapperFigure maxWidth="2em" maxWidthDesktop="2em">
                                {
                                    isAuthenticated && user ?
                                        <AvatarImg src={user.picture} width="100%" height="100%"/> :
                                        <StyledUserProfileIcon/>
                                }

                            </ProfileIconWrapperFigure>

                        </IconLink>


                    </ProfileDropDownMenu>
            }
        </>)

}

export default ProfileButton
