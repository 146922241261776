import React from 'react'
import Tooltip from './Tooltip'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Label from './Label'
import Text from './Text'

// To give it a name
const Field = styled.div`
`

const StyledInput = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  color: ${props => props.theme.inputColor};
  border-radius: ${props => props.theme.borderRadius};
  width: 100%;
  text-align: ${props => props.align};
  padding: 12px 12px 12px 12px;
  opacity: 1; //safari disabled fix
  font-size: 1rem;
  ${props => props.variant === 'small' && 'font-size: .75rem;'};
  ${props => props.variant === 'medium' && 'font-size: 1.25rem;'};
  ${props => props.variant === 'large' && 'font-size: 1.5rem;'};
  ${props => props.variant === 'xl' && 'font-size: 2rem;'};
  ${props => `border: 2px solid ${props.borderVariant};`}
  
  ${props => props.small && `padding: 2px;`};
  
  ${props => props.inverted && `
    background-color: transparent;
    color: ${props.theme.inputColorDesktop};
    border-width: 2px;
  `}
  
  &:focus {
    outline: none;
  }
  
  &[disabled] {
    cursor: not-allowed;
  }
  
  @media (min-width: ${props => props.theme.desktop}) {
    border-color: ${props => props.theme.inputColor};
    
    ${props => props.inverted && `
      color: ${props.theme.inputColor};
      border-color: ${props.theme.inputColor};
    `};
  }
`

const Error = styled(Text)`
  background-color: ${props => props.theme.danger};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  
  @media (min-width: ${props => props.theme.desktop}) {
    color: ${props => props.theme.mainColor}
  }
`

const Wrapper = styled.div`
  width: ${props => props.width};
  position: relative;
  
  ${props => props.align === 'center' && 'margin: auto;'};
  ${props => props.align === 'right' && 'margin-left: auto;'};
`

const Currency = styled.i`
  color: ${props => props.theme.inputColor};
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 30px;
  text-align: center;
  font-style: normal;
  right: 20px;
  font-size: 1rem;
  ${props => props.variant === 'small' && 'font-size: .75rem;'};
  ${props => props.variant === 'medium' && 'font-size: 1.25rem;'};
  ${props => props.variant === 'large' && 'font-size: 1.5rem;'};
  ${props => props.variant === 'xl' && 'font-size: 2rem;'};
  
  ${props => props.inverted && `
    color: ${props.theme.inputColorDesktop};
  `};
  
  @media (min-width: ${props => props.theme.desktop}) {
    color: ${props => props.theme.inputColorDesktop};
    
    ${props => props.inverted && `
      color: ${props.theme.inputColor};
    `};
  }
`

const Input = (props) => {
    const { input, meta, style, hint, showTooltip, isCurrency, borderVariant, inverted, labelVariant, labelAlign, inputAlign, inputVariant, inputWidth, small, onBlur, ...rest } = props

    const handleOnBlur = (event) => {
        if (onBlur) onBlur(event)

        input.onBlur(event)
    }

    return (
        <Field style={style} inverted={inverted}>
            {showTooltip && meta.error && meta.touched && <Tooltip>{meta.error}</Tooltip>}
            {hint && <Label variant={labelVariant} align={labelAlign}>{hint}</Label>}
            {isCurrency ?
                <Wrapper width={inputWidth} align={inputAlign} inverted={inverted}>
                    <StyledInput {...input} {...rest} onBlur={handleOnBlur} small={small} width={inputWidth} align={inputAlign} variant={inputVariant} borderVariant={borderVariant} inverted={inverted}/>
                    <Currency variant={inputVariant} inverted={inverted}>€</Currency>
                </Wrapper> :
                <StyledInput {...input} {...rest} onBlur={handleOnBlur} small={small} width={inputWidth} align={inputAlign} variant={inputVariant} borderVariant={borderVariant} inverted={inverted}/>
            }
            {!showTooltip && meta.touched && meta.error && <Error fontSize="0.8em">{meta.error}</Error>}
        </Field>
    )
}

Input.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object,
    hint: PropTypes.string,
    isCurrency: PropTypes.bool,
    borderVariant: PropTypes.string,
    inverted: PropTypes.bool,
    small: PropTypes.bool,
    inputVariant: PropTypes.oneOf(['small', 'normal', 'medium', 'large', 'xl']),
    labelVariant: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
    inputAlign: PropTypes.oneOf(['left', 'center', 'right']),
    labelAlign: PropTypes.oneOf(['left', 'center', 'right']),
    inputWidth: PropTypes.string,
    onBlur: PropTypes.func,
}

Input.defaultProps = {
    borderVariant: 'transparent',
    showTooltip: true,
    inputVariant: 'normal',
    labelVariant: 'normal',
    inputAlign: 'center',
    labelAlign: 'center',
    inputWidth: '100%',
}

export default Input
