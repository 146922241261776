import React from 'react'
import FavoriteButton from './FavoriteButton'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ReactComponent as IconBack } from '../../images/profile/chevron-left.svg'
import styled, { useTheme } from 'styled-components'
import { clearCompany } from '../../actions/companyActions'

const BackIcon = styled(IconBack)`
color:  ${props => props.theme.mainColor};
height: ${props => props.theme.profile.subheaderIconHeight};
position: relative;
right: 7px;
`

const CompanyCustomHeaderTagPlugin = (props) => {
    const pushBackPath = useSelector(state => state.appState.pushBackPath)
    const dispatch = useDispatch()
    const history = useHistory()
    const theme = useTheme()
    const company = useSelector(state => state.company)

    return (
        <>
            <div style={{
                display: 'flex', height: theme.profile.subheaderHeight,
                alignItems: 'center', flexDirection: 'row', position: 'relative',
            }}>
                <div onClick={() => {
                    dispatch(clearCompany())
                    history.push((pushBackPath) ? pushBackPath : '/')
                    //dispatch(clearPushBackState)
                }}
                     style={{ display: 'flex', flexGrow: '1', alignItems: 'center' }}>
                    <span><BackIcon/></span>
                    <span>{company.name}</span>
                </div>
                <FavoriteButton company={company}/>
            </div>
        </>
    )
}

export default CompanyCustomHeaderTagPlugin
