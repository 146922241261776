import { CLEAR_AUTH_TOKEN, SET_AUTH_TOKEN } from './types'

export const setAuthToken = (token) => {
    return {
        type: SET_AUTH_TOKEN,
        payload: token,
    }
}

export const clearAuthToken = () => {
    return {
        type: CLEAR_AUTH_TOKEN,
        payload: null,
    }
}


