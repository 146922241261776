import React from 'react'
import { useSelector } from 'react-redux'
import Container from '../components/Container'
import Header from '../components/Header'
import Slides from '../components/Slides'
import Footer from '../components/Footer'
import Button from '../components/Button'
import ErrorBoundary from '../components/ErrorBoundary'
import { useTranslation } from 'react-i18next'
import { shouldSkipTipsSelector } from '../reducers/restaurant'
import Hint from '../components/Hint'
import { useTracker } from '../hooks'
import { useHistory } from 'react-router-dom'

const Home = () => {
    const { t } = useTranslation()
    const skipTips = useSelector(state => shouldSkipTipsSelector(state))
    const restaurant = useSelector(state => state.restaurant)
    useTracker('Home')
    const history = useHistory()

    const goToWizard = () => {
        history.push('/wizard/step-1')
    }

    const textList = skipTips ? t('home.listSkipTips') : t('home.list')

    return (
        <ErrorBoundary>
            <Header onlyLogo title={`${t('at')} <strong>${restaurant.name}</strong>`}/>
            <Container fluid>
                <Slides textList={textList}/>
            </Container>
            <Footer>
                <Hint hintText={t(`home.hint-${process.env.REACT_APP_ENV}`)} smaller margin="0 0 10px 0"/>
                <Button type={'button'} onClick={goToWizard}>
                    {t('home.button')}
                </Button>
            </Footer>
        </ErrorBoundary>
    )
}

export default Home
