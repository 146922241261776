import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledLabel = styled.label`
  display: block;
  margin-bottom: .5em;
  font-size: 1rem;
  ${props => props.variant === 'small' && 'font-size: .75rem;'};
  ${props => props.variant === 'medium' && 'font-size: 1.25rem;'};
  ${props => props.variant === 'large' && 'font-size: 1.5rem;'};
  font-weight: normal;
  text-align: ${props => props.align};
`

const Label = ({ children, ...rest }) => {
    return (
        <StyledLabel {...rest}>{children}</StyledLabel>
    )
}

Label.propTypes = {
    variant: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
    align: PropTypes.oneOf(['left', 'center', 'right']),
}

Label.defaultProps = {
    variant: 'normal',
    align: 'center',
}

export default Label
