import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate, getValueCentsInCurrencyFormat } from '../../utils'
import { ExtraStatusMessage, HistoryDateColumn, IconColumn, StyledImgIcon, StyledLi, ValueInCentsColumn } from './_baseComponents'
import { paymentMethodConfig } from '../../paymentMethodConfig'
import DefaultIcon from '../../images/voucher/gift_card_icon_simplified.svg'

const ListItemCashbackEvent = (props) => {
    const { t } = useTranslation()
    const { historyEvent, lastItem } = props
    const paymentWithVoucher = (historyEvent.voucherAmountCents > 0)
    console.log('paymentWithVoucher', paymentWithVoucher)
    const getPaymentIcon = (name) => {
        return (paymentMethodConfig[name]) ? paymentMethodConfig[name].icon : DefaultIcon
    }

    const paymentMethodName = (historyEvent.psp === 'voucher') ? 'voucher' : historyEvent.paymentMethod

    return <>
        <StyledLi>
            <IconColumn>
                <StyledImgIcon src={getPaymentIcon(paymentMethodName)}/>
            </IconColumn>

            <ValueInCentsColumn> {getValueCentsInCurrencyFormat(historyEvent.totalAmountCents)} </ValueInCentsColumn>

            <HistoryDateColumn>
                {`${formatDate(new Date(historyEvent.updatedAt), t('profile.historyDateFormat'))}`} {t('profile.historyDateFormatClock')}
                {(paymentWithVoucher) && <ExtraStatusMessage>
                    {t('profile.payments.hybridPayment', { amountCents: getValueCentsInCurrencyFormat(historyEvent.voucherAmountCents) })}
                </ExtraStatusMessage>}
            </HistoryDateColumn>
        </StyledLi>
    </>
}

export default ListItemCashbackEvent
