import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import Text from '../../components/Text'
import { getValueCentsInCurrencyFormat } from '../../utils'
import OverlayPopup from '../../components/OverlayPopup'
import { useDispatch, useSelector } from 'react-redux'
import Container from '../../components/Container'
import CompanyHeader from '../../components/profileadmin/CompanyHeader'
import topupGift from '../../images/profile/topup_gift.svg'
import topupWallet from '../../images/profile/topup_wallet.svg'
import Image from '../../components/Image'
import tipHaken from '../../images/tip_haken.svg'
import Footer from '../../components/Footer'
import { ReactComponent as cashbackIcon } from '../../images/profile/action_flame.svg'
import { ReactComponent as ChevronDown } from '../../images/profile/chevron-down.svg'
import { useHistory, useParams } from 'react-router-dom'
import { fetchCompany } from '../../actions/companyActions'
import Processing from '../Processing'
import { firstPlaceOfCompanySelector } from '../../reducers/company'

const VoucherSelectButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 120px;
  padding: 0;
`

const VoucherSelectInput = styled.div`
  cursor: pointer;
  overflow: hidden;
  position: relative;
  background-color: ${props => (props.disabled) ? 'white' : 'lightgray'};
  border-radius: 6px;
  border: 1px solid lightgray;
  padding: 0 5px;
  width: 44%;
  max-width: 220px;
  height: 100px;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-content: center;
  align-items: center;
  line-height: 80%;
  
  & div {
    font-size: 0.7em;
    margin: auto;
    text-align: center;
  }
  
  & img {
    height: 50%;
    margin: 10px auto;
  }
`

const SelectBadge = styled.div`
  position: absolute;
  top:-25px;
  right: -25px;
  width: 50px;
  height: 50px;
  padding: 0;
  transform: rotate(45deg);
  background: ${props => props.theme.profile.cashbackGradient};
  vertical-align: bottom;
  
  & img {
    position: absolute;
    left: 20px;
    top: 18px;
    max-width: 12px;
    transform: rotate(-45deg);
`

const VoucherFormArea = styled.div`
  align-items: center;
  align-content: center;
  width: 100%;
  flex: 1 1 auto;
  padding: 0 10px;
  display: flex;
  flex-flow: column;
`

const AmountTitle = styled.div` 
  padding-top: 0.4em;
  margin: auto;
  text-align: center;
  //font-weight: 500;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin: auto;
  background: ${props => (!props.disabled) ? props.theme.profile.cashbackGradient : 'lightgray'};
`

const SelectAmountWrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  text-align: center;
  padding: 20px 0px;
`

const BringSelectPopupButton = styled.button`
  width: 100%;
  margin:auto;
  cursor:pointer;
  background: white;
  border: 1px solid lightgray;
  padding: 10px 10px;
  border-radius: 5px;
  color: #4c4c4c;
  font-size: 1em;
  text-align: left;
  position: relative;
  & div {
    border-left: 1px solid lightgray;
  }
`
const BringSelectPopupDropDownSquare = styled(ChevronDown)`
  height: 100%;
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
  color: black;
  padding: 2px;
`

const VoucherOptionWrapper = styled.div`
  width: 100%;
  
  cursor: pointer;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  margin: 0 auto;
  padding: 10px 20px;
  
  background-color: ${props => props.checked && 'lightgray'};
  
  &:last-of-type,
  &:last-child {
    border-bottom: none;
  }
  
  & p { //Text
    text-align: left;
    width: 120px;
  }
  
  & div { //Text wrapper
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    text-align: left;
  }
    
  & input {
    float:right;
  }
`

const StyledInput = styled.input`
   visibility: hidden;
`

const StyledInputDisplay = styled.span`
  display: unset;
  position: relative;
  
  border: 1px solid darkgrey;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  background: white;
  
  &:before {
      position: absolute;
      content: '';
      border-radius: 100%;
      height: 14px;
      width: 14px;
      top: 4px;
      left: 4px;
      margin: auto;
      background: ${props => props.checked ? props.theme.profile.cashbackGradient : ''};
  }
`

const DealIcon = styled(cashbackIcon)`
  fill: ${props => props.theme.profile.dealHighlightBackground};
  width: 20px;
  margin: 0 10px;
`

const DealIconForButton = styled(cashbackIcon)`
  fill: ${props => props.theme.profile.dealHighlightBackground};
  width: 20px;
  margin: 0 10px;
  position: absolute;
  left: 1px;
  top: 8px;
  z-index: 2;
  display: inline;
`

const DealTextForButton = styled.span`
  color: ${props => props.theme.profile.dealHighlightBackground};
`
const DealWrapper = styled.div`
  color: ${props => props.theme.profile.dealHighlightBackground};
  display: flex;
  & p { //text
    width: max-content;
  }
`

const SelectAmountButton = (props) => {
    const { onClick, label } = props

    return <BringSelectPopupButton onClick={onClick}>
        {label}
        <BringSelectPopupDropDownSquare>
        </BringSelectPopupDropDownSquare>
    </BringSelectPopupButton>
}

const DealText = (props) => {
    const { bonusCents } = props
    return <DealWrapper>
        <DealIcon/>
        <Text fontSize='0.8em'>+{getValueCentsInCurrencyFormat(bonusCents)}</Text>
    </DealWrapper>
}

const SelectedAmountDisplay = styled.div`
  display: flex;
  flex-flow: column;
  margin: auto;
  font-size: 0.7em;
  font-weight: 500;
  
  & span {
    font-size: 0.4em;
    font-weight: 400;
    position: relative;
    margin: auto;
    width: max-content;
  }
`

const DealBadgeByAmountDisplay = styled.div`
  position: absolute;
  top: 2px;
  right: -90px;
`

const DealBonusTo = styled.div`
  margin-bottom: 10px;
  text-align: center;
  font-size: 0.8em;
  display: flex;
  
  & input {
    width: 20px;
    
  }
`


const doesDealApplyToValue = (deal, voucherOption) => {
    return (deal && (!deal.threshold || deal.threshold <= voucherOption))
}

const
    OptionItem = (props) => {
        const { onChange, deal, value, checked } = props
        return (
            <VoucherOptionWrapper onClick={onChange} checked={checked}>
                <div>
                    <Text>{getValueCentsInCurrencyFormat(value * 100 /* to cents */)}</Text>
                    {doesDealApplyToValue(deal, value) && <DealText bonusCents={deal.voucher * 100}/>}
                </div>
                <StyledInput type="radio" name="voucher" onChange={onChange} onClick={onChange} value={value} checked={checked}/>
                <StyledInputDisplay onClick={onChange} checked={checked}/>
            </VoucherOptionWrapper>
        )
    }


const BuyVoucher = () => {
    const { id } = useParams()
    const company = useSelector(state => state.company)
    const deal = (company.deals && company.deals[0] && company.deals[0].voucher) ? company.deals[0] : null
    const { t } = useTranslation()
    const history = useHistory()
    const [selectedVoucherType, setSelectedVoucherType] = useState(0)
    const [selectedVoucherAmount, setSelectedVoucherAmount] = useState(0)
    const [showVoucherOptions, setShowVoucherOptions] = useState(false)
    const [alsoGiftBonus, setAlsoGiftBonus] = useState(false)
    const qrCode = company.voucher?.card?.qrCode
    const dispatch = useDispatch()
    const firstRestaurantOfCompany = useSelector(state => firstPlaceOfCompanySelector(state))
    const paymentUrl = `/restaurants/${firstRestaurantOfCompany.id}?amount=${(selectedVoucherAmount * 100)}&card_id=${qrCode}`
    console.log('paymentUrl', paymentUrl)
    useEffect(() => {
        (async function () {
            try {
                dispatch(fetchCompany(id))
            } catch (e) {
                console.error(e)
                dispatch(history.push('/'))
            }
        })()
    }, [])

    const toggleGiftBonus = () => {
        setAlsoGiftBonus(!alsoGiftBonus)
    }
    const VoucherTypes = [
        {
            icon: topupGift,
            label: t('profile.voucher.buy.asGift'),
            value: 1,
        },
        {
            icon: topupWallet,
            label: t('profile.voucher.buy.asForMe'),
            value: 2,
        },
    ]

    const voucherBuyOptions = [10, 15, 20, 25, 50, 75, 100, 150, 200]

    const displayVoucherOptionsClickHandler = (e) => {
        setShowVoucherOptions(!showVoucherOptions)
        e.stopPropagation()
    }

    if (!company.id) {
        return <Processing/>
    }

    return <>
        <CompanyHeader displayCompanyArea={true} deal={deal} company={company}/>
        <Container>
            <VoucherSelectButtonWrapper>
                {VoucherTypes.map((voucherType, index) => {
                    return <VoucherSelectInput disabled={(voucherType.value != 1)} key={index} onClick={() => { voucherType.value != 1 && setSelectedVoucherType(voucherType.value)}}>
                        <img src={voucherType.icon}/>
                        <div>{voucherType.label}</div>
                        {(voucherType.value === selectedVoucherType) && <SelectBadge><Image src={tipHaken}/></SelectBadge>}
                    </VoucherSelectInput>
                })}
            </VoucherSelectButtonWrapper>

            {(selectedVoucherType > 0) && <VoucherFormArea>
                <AmountTitle>{t('profile.voucher.buy.amount')}</AmountTitle>
                <SelectAmountWrapper>

                    <SelectAmountButton
                        disabled={showVoucherOptions}
                        onClick={displayVoucherOptionsClickHandler}
                        label={selectedVoucherAmount <= 0 ? t('profile.voucher.buy.selectAmount') :
                            <div style={{
                                textAlign: 'center',
                                border: 0,
                            }}>
                                {getValueCentsInCurrencyFormat(selectedVoucherAmount * 100)}
                                {deal && deal.threshold && deal.threshold <= selectedVoucherAmount &&
                                <span>
                                <DealTextForButton> + {getValueCentsInCurrencyFormat(deal.voucher * 100)}</DealTextForButton>
                                    <DealIconForButton/>
                                </span>
                                }

                            </div>

                        }

                    />
                    {showVoucherOptions &&
                    <OverlayPopup onOutsideClick={() => {setShowVoucherOptions(false)}}>
                        {voucherBuyOptions.map((option, index) =>
                            <OptionItem key={index}
                                        onChange={() => {
                                            setSelectedVoucherAmount(option)
                                            setShowVoucherOptions(false)
                                        }}
                                        checked={(selectedVoucherAmount === option)}
                                        deal={deal}
                                        value={option}/>,
                        )}
                    </OverlayPopup>}
                </SelectAmountWrapper>

            </VoucherFormArea>}

        </Container>
        <Footer>
            <StyledButton disabled={(selectedVoucherAmount <= 0)} variant={'normal'}
                          onClick={() => history.push(paymentUrl)}>
                {t('profile.actions.voucher.forwardToPayment')}
            </StyledButton>
        </Footer>
    </>
}

export default BuyVoucher
