import { SET_CLIENT, SET_CLIENT_EMAIL, SET_CLIENT_LOCALE } from './types'
import { setXHRInProgress } from './appStateActions'
import { createClient, updateClient } from '../services/clients'

export const setClient = (payload) => {
    return {
        type: SET_CLIENT,
        payload: payload,
    }
}

export const setClientEmail = (payload) => {
    return {
        type: SET_CLIENT_EMAIL,
        payload: payload,
    }
}

export const setClientLocale = (payload) => {
    return {
        type: SET_CLIENT_LOCALE,
        payload: payload,
    }
}

// Thunks

export const createOrUpdateClient = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const client = state.client

        try {
            dispatch(setXHRInProgress(true))

            const result = client.id ? await updateClient(client) : await createClient(client)
            dispatch(setClient(result.data))

            return Promise.resolve(result.data)
        } catch (e) {
            return Promise.reject(e)
        } finally {
            dispatch(setXHRInProgress(false))
        }
    }
}
