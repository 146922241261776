import React, { useState } from 'react'
import Container from '../../components/Container'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import ViewCashback from './ViewCashback'
import ViewVoucher from './ViewVoucher'
import ViewPayments from './ViewPayments'
import { ReactComponent as CashbackIcon } from '../../images/profile/cashback_icon.svg'
import { ReactComponent as VoucherIcon } from '../../images/profile/voucher_balance_filled.svg'
import { ReactComponent as PaymentIcon } from '../../images/profile/payment.svg'
import LoyaltyCategoryIconButton from '../../components/profileadmin/LoyaltyCategoryIconButton'
import { getValueCentsInCurrencyFormat } from '../../utils'
import CompanyHeader from '../../components/profileadmin/CompanyHeader'

const StyledContainer = styled(Container)`
  
`

const LoyaltyButtonsArea = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: flex-end;
`

const LoyaltyDetailsArea = styled.div`
  align-items: center;
  text-align: center;
  width: 100%;
  flex: 1 ;
  height: 0;
`

const CompanyLoyaltyDetail = () => {
    const company = useSelector(state => state.company)
    const { t } = useTranslation()
    const theme = useTheme()
    const cashbackThreshold = company.loyalty?.configuration?.threshold || 0
    const cashbackPunkte = company.loyalty?.account?.amount || 0

    const detailViews = [
        {
            id: 'cashback',
            shortTitle: t('profile.cashback.shortTitle'),
            view: <ViewCashback/>,
            color: theme.profile.cashbackPrimaryColor,
            icon: CashbackIcon,
            iconWidth: '76%',
            alt: t('profile.cashback.iconAlt'),
            bottomBadge: cashbackPunkte + ' | ' + cashbackThreshold + ' cp',
        },
        {
            id: 'voucher',
            shortTitle: t('profile.voucher.shortTitle'),
            view: <ViewVoucher/>,
            color: theme.profile.voucherPrimaryColor,
            icon: VoucherIcon,
            iconWidth: '50%',
            alt: t('profile.cashback.iconAlt'),
            bottomBadge: getValueCentsInCurrencyFormat((company.voucher && company.voucher.balance) ? company.voucher.balance.amountCents : 0),
        },
        {
            id: 'payments',
            shortTitle: t('profile.payments.shortTitle'),
            view: <ViewPayments/>,
            color: theme.profile.PaymentsPrimaryColor,
            icon: PaymentIcon,
            iconWidth: '50%',
            alt: t('profile.cashback.iconAlt'),
        },

    ]
    const [viewInDetail, setViewInDetail] = useState(detailViews[0])

    return (
        <>
            <CompanyHeader company={company}/>
            <StyledContainer fluid streched>
                <LoyaltyButtonsArea>
                    {detailViews.map((detailView) =>
                        <LoyaltyCategoryIconButton
                            key={detailView.id}
                            bottomBadge={detailView.bottomBadge}
                            categoryDetails={detailView}
                            selected={(detailView.id === viewInDetail.id)}
                            onClick={() => {setViewInDetail(detailView)}}/>,
                    )}
                </LoyaltyButtonsArea>
                <LoyaltyDetailsArea>
                    {viewInDetail.view}
                </LoyaltyDetailsArea>

            </StyledContainer>
        </>
    )
}

export default CompanyLoyaltyDetail
