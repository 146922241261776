import { ReactComponent as pinIcon } from '../../../images/profile/place.svg'
import { ReactComponent as chevronDown } from '../../../images/profile/chevron-down.svg'
import { ReactComponent as chevronUp } from '../../../images/profile/chevron-up.svg'
import { ReactComponent as clockIcon } from '../../../images/profile/clock.svg'

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Address from './Address'

const StyledChevronDown = styled(chevronDown)`
    height: 20px;
    width: 20px;
`
const StyledChevronUp = styled(chevronUp)`
    height: 20px;
    width: 20px;
`

const StyledPlaceIcon = styled(pinIcon)`
    height: 18px;
    width: 18px;
`

const StyledClockIcon = styled(clockIcon)`
height: 18px;
width: 18px;
`

const StyledOtherPlaces = styled.div`
  margin-left: 15px;
`

const PlaceDetail = (props) => {
    const { places } = props
    const { t } = useTranslation()

    const [showAll, setShowAll] = useState(false)

    const toggleShowAll = event => {
        event.stopPropagation()
        setShowAll(!showAll)
    }

    const otherPlaces = places.slice(1).map((place) => <Address style={{ marginTop: '5px' }} key={place.id} place={place}/>,
    )

    return <>


        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <StyledClockIcon/>
            <div style={{ marginLeft: '5px' }}>{places[0].openTil ? t('profile.list.openUntil', { until: places[0].openTil }) :
                t('profile.list.openUntilUnknown')}</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <StyledPlaceIcon/>
            <Address key={places[0].id} place={places[0]}/>
            {places.length > 1 && !showAll && <StyledChevronDown onClick={toggleShowAll} style={{ display: 'block' }}/>}
            {places.length > 1 && showAll && <StyledChevronUp onClick={toggleShowAll} style={{ display: 'block' }}/>}
        </div>

        {showAll && <StyledOtherPlaces>
            {otherPlaces}
        </StyledOtherPlaces>
        }
    </>
}

PlaceDetail.propTypes = {
    places: PropTypes.array.isRequired,
}

export default PlaceDetail
