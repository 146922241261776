import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from './Text'

const StyledHint = styled.div`
  padding: ${props => props.transparent && '10px'};
  background: ${props => props.transparent && '#7F7F7F'};
  
  @media (min-width: ${props => props.theme.desktop}) {
    background-color: transparent;
  }
`

const StyledText = styled(Text)`
    line-height: 1.3;
    
    b, a, .highlight {
      color: ${props => props.theme.highlight};
      
      @media (min-width: ${props => props.theme.desktop}) {
        color: ${props => props.theme.highlightDesktop};
      }
    }
      
    a {
      text-decoration: none;
    }
`

const Hint = (props) => {
    const { hintText, transparent, ...rest } = props

    return (
        <StyledHint transparent={transparent}>
            <StyledText align="left" dangerouslySetInnerHTML={{ __html: hintText }} {...rest}/>
        </StyledHint>
    )
}

Hint.propTypes = {
    hintText: PropTypes.string.isRequired,
    transparent: PropTypes.bool,
}

export default Hint
