import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledFigure = styled.figure`
  display: block;
  position: relative;
  height: auto;
  max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
  
  ${props => props.square && `
    width: 100%;
    height: 100%;
`};
  
  @media (min-width: ${props => props.theme.desktop}) {
    ${props => props.maxWidthDesktop && `max-width: ${props.maxWidthDesktop};`};
  }
`

const Figure = ({ children, maxWidth, ...rest }) => {
    return <StyledFigure maxWidth={maxWidth} {...rest}>{children}</StyledFigure>
}

Figure.propTypes = {
    maxWidth: PropTypes.string,
    maxWidthDesktop: PropTypes.string,
    square: PropTypes.bool,
}

export default Figure
