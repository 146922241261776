import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCompany } from '../../actions/companyActions'

const StyledDiv = styled.div`
  border: 0;
  cursor: pointer;
`

const BaseVoucherBuyButton = (props) => {
    const { company, children, ...rest } = props
    const history = useHistory()
    const dispatch = useDispatch()
    const onBuyVoucherButton = (e) => {
        dispatch(setCompany(company))
        history.push(`/profile/companies/${company.id}/buyvoucher`)
        e.stopPropagation()
    }

    return (
        <StyledDiv onClick={onBuyVoucherButton} {...rest} >
            {children}
        </StyledDiv>
    )
}

BaseVoucherBuyButton.propTypes = {
    company: PropTypes.object.isRequired,
}

export default BaseVoucherBuyButton
