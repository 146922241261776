import {
    CLEAR_STATE,
    INIT_IS_BILL_SCANNED,
    SET_AMOUNT_EDITABLE,
    SET_CASHBACK_POT,
    SET_HIDE_RESTAURANT_IN_HEADER,
    SET_REDIRECT_AFTER_LOGIN,
    SET_TRANSACTION_FINISHED,
    SET_USER_MENU_OPEN,
    SET_XHR_PROGRESS,
} from './types'

export const initIsBillScanned = () => {
    return {
        type: INIT_IS_BILL_SCANNED,
    }
}

export const setTransactionFinished = (payload) => {
    return {
        type: SET_TRANSACTION_FINISHED,
        payload: payload,
    }
}

export const setAmountEditable = (payload) => {
    return {
        type: SET_AMOUNT_EDITABLE,
        payload: payload,
    }
}

export const clearState = () => {
    return {
        type: CLEAR_STATE,
    }
}

export const setXHRInProgress = (payload) => {
    return {
        type: SET_XHR_PROGRESS,
        payload: payload,
    }
}

export const setHideRestaurantInHeader = (payload) => {
    return {
        type: SET_HIDE_RESTAURANT_IN_HEADER,
        payload: payload,
    }
}

export const setRedirectAfterLogin = (payload) => {
    return {
        type: SET_REDIRECT_AFTER_LOGIN,
        payload: payload,
    }
}

export const setUserMenuOpen = (payload) => {
    return {
        type: SET_USER_MENU_OPEN,
        payload: payload,
    }
}

export const setCashbackPot = (payload) => {
    return {
        type: SET_CASHBACK_POT,
        payload: payload,
    }
}
