import { CLEAR_STATE, CLEAR_STRIPE_PAYMENT_INTENT_STATE, REMOVE_STRIPE_PAYMENT_INTENT, SET_STRIPE_PAYMENT_INTENT } from '../actions/types'

const initState = {
    id: null,
    clientSecret: null,
    status: null,
}

export function stripePaymentIntent (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_STRIPE_PAYMENT_INTENT: {
            return { ...state, ...action.payload }
        }
        case CLEAR_STRIPE_PAYMENT_INTENT_STATE: {
            return { ...initState }
        }
        case CLEAR_STATE: {
            return { ...initState }
        }
        case REMOVE_STRIPE_PAYMENT_INTENT:
            return { ...state, id: null }
        default:
            return state
    }
}
