import API, { URLs } from '../api'

export const getTransaction = (transaction) => {
    return API.get(`${URLs.transactions}/${transaction.id}`, { headers: { 'Key-Inflection': 'camel' } })
}

export const createTransaction = (payload) => {
    return API.post(URLs.transactions, { transaction: { ...payload } }, { headers: { 'Key-Inflection': 'camel' } })
}

export const updateTransaction = (transaction) => {
    return API.patch(`${URLs.transactions}/${transaction.id}`, { transaction: transaction }, { headers: { 'Key-Inflection': 'camel' } })
}
