import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import React from 'react'

const StyledNewBadge = styled.div`
  position: absolute;
  top:-25px;
  left: -25px;
  width: 50px;
  height: 50px;
  padding: 0px;
  transform: rotate(-45deg);
  background: ${props => props.theme.profile.cashbackGradient}; 
  
  display: flex;
  vertical-align: center;
  align-items: flex-end;
  justify-content: center;
  
  color: white;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;
`

const NewBadge = (props) => {
    const { t } = useTranslation()

    return <StyledNewBadge>{t('profile.list.new')}</StyledNewBadge>
}

export default NewBadge
