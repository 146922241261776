/**
 * Define the version of the Google Pay API referenced when creating your
 * configuration
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#PaymentDataRequest|apiVersion in PaymentDataRequest}
 */
const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
}

/**
 * Card networks supported by your site and your gateway
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#CardParameters|CardParameters}
 */
const allowedCardNetworks = ['MASTERCARD', 'VISA']

/**
 * Card authentication methods supported by your site and your gateway
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#CardParameters|CardParameters}
 * supported card networks
 */
const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS']

/**
 * Describe your site's support for the CARD payment method and its required
 * fields
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#CardParameters|CardParameters}
 */
const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedCardNetworks,
        allowPrepaidCards: true,
        billingAddressRequired: false,
    },
}

export const includeGooglePayLibrary = () => {
    return new Promise((resolve, reject) => {
        if (window.google) {
            return resolve()
        }

        const script = document.createElement('script')
        script.src = 'https://pay.google.com/gp/p/js/pay.js'
        script.async = true
        script.onload = () => {
            resolve()
        }
        script.onerror = err => {
            reject(err)
        }
        document.body.appendChild(script)
    })
}

/**
 * Identify your gateway and your site's gateway merchant identifier
 *
 * The Google Pay API response will return an encrypted payment method capable
 * of being charged by a supported gateway after payer authorization
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#Gateway|PaymentMethodTokenizationSpecification}
 */
export const tokenizationSpecification = (gatewayMerchantId) => {
    return {
        type: 'PAYMENT_GATEWAY',
        parameters: {
            'gateway': 'wirecard',
            'gatewayMerchantId': gatewayMerchantId,
        },
    }
}

/**
 * Describe your site's support for the CARD payment method including optional
 * fields
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#CardParameters|CardParameters}
 */
const cardPaymentMethod = (gatewayMerchantId) =>
    Object.assign({},
        baseCardPaymentMethod,
        {
            tokenizationSpecification: tokenizationSpecification(gatewayMerchantId),
        },
    )

/**
 * An initialized google.payments.api.PaymentsClient object or null if not yet set
 *
 * @see {@link getGooglePaymentsClient}
 */
let paymentsClient = null

/**
 * Configure your site's support for payment methods supported by the Google Pay
 * API.
 *
 * Each member of allowedPaymentMethods should contain only the required fields,
 * allowing reuse of this base request when determining a viewer's ability
 * to pay and later requesting a supported payment method
 *
 * @returns {object} Google Pay API version, payment methods supported by the site
 */
function getGoogleIsReadyToPayRequest () {
    return Object.assign({}, baseRequest, {
        allowedPaymentMethods: [baseCardPaymentMethod],
    })
}

/**
 * Configure support for the Google Pay API
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#PaymentDataRequest|PaymentDataRequest}
 * @returns {object} PaymentDataRequest fields
 */
export const getGooglePaymentDataRequest = (transactionInfo, gatewayMerchantId) => {
    const paymentDataRequest = Object.assign({}, baseRequest)
    paymentDataRequest.allowedPaymentMethods = [
        cardPaymentMethod(gatewayMerchantId),
    ]

    paymentDataRequest.transactionInfo = transactionInfo
    paymentDataRequest.merchantInfo = {
        merchantId: '00227727287260436916',
        merchantName: 'Stampay GmbH',
    }
    paymentDataRequest.emailRequired = true

    return paymentDataRequest
}

/**
 * Prefetch payment data to improve performance
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
 */
export function prefetchGooglePaymentData (merchantData) {
    const paymentDataRequest = getGooglePaymentDataRequest(null, merchantData)
    // transactionInfo must be set but does not affect cache
    paymentDataRequest.transactionInfo = {
        totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
        currencyCode: 'EUR',
    }
    const paymentsClient = getGooglePaymentsClient()
    paymentsClient.prefetchPaymentData(paymentDataRequest)
}

/**
 * Return an active PaymentsClient or initialize
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
 * @returns {google.payments.api.PaymentsClient} Google Pay API client
 */
export function getGooglePaymentsClient () {
    if (paymentsClient === null) {
        paymentsClient = new window.google.payments.api.PaymentsClient({ environment: process.env.REACT_APP_ENV === 'production' ? 'PRODUCTION' : 'TEST' })
    }
    return paymentsClient
}

/**
 * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
 *
 * Display a Google Pay payment button after confirmation of the viewer's
 * ability to pay.
 */
export const onGooglePayLoaded = () => {
    return new Promise((resolve, reject) => {
        const paymentsClient = getGooglePaymentsClient()
        paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
            .then(response => {
                if (response.result) {
                    resolve(true)
                }
            })
            .catch(e => reject(e))
    })
}

/**
 * Provide Google Pay API with a payment amount, currency, and amount status
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#TransactionInfo|TransactionInfo}
 * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
 */
export const getGoogleTransactionInfo = (totalPrice, currencyCode = 'EUR') => {
    return {
        currencyCode: currencyCode,
        totalPriceStatus: 'FINAL',
        // set to cart total
        totalPrice: totalPrice,
    }
}

/**
 * Add a Google Pay purchase button alongside an existing checkout button
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#ButtonOptions|Button options}
 * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines|Google Pay brand guidelines}
 */
export const getGooglePayButton = (onClick, buttonColor = 'white') => {
    const paymentsClient = getGooglePaymentsClient()

    return paymentsClient.createButton({
        onClick: onClick,
        buttonColor: buttonColor,
    })
}
