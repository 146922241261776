import { CLEAR_STEPS_HISTORY, PASS_STEP, SET_PASSED_STEPS } from './types'

export const passStep = step => {
    return {
        type: PASS_STEP,
        payload: step,
    }
}

export const setPassedSteps = steps => {
    return {
        type: SET_PASSED_STEPS,
        payload: steps,
    }
}

export const clearStepsHistory = () => {
    return {
        type: CLEAR_STEPS_HISTORY,
    }
}
