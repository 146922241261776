import React from 'react'
import Container from '../components/Container'
import { ReactComponent as Food } from '../images/food_and_drinks.svg'
import Button from '../components/Button'
import Footer from '../components/Footer'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Hint from '../components/Hint'
import { useTracker } from '../hooks'
import Headline from '../components/Headline'
import Figure from '../components/Figure'

const StyledFood = styled(Food)`
  @media (min-width: ${props => props.theme.desktop}) {
    path {
      fill: ${props => props.theme.mainColorDesktop};
    }
  }
`

const StyledHeadline = styled(Headline)`
  margin-bottom: 25px;
  
  div {
    margin-top: 20px;
    color: ${props => props.theme.highlight};
  }
`

const StepWaiter = (props) => {
    const { t } = useTranslation()
    useTracker(null)

    return (
        <>
            <Container>
                <StyledHeadline forwardedAs="h3" fontWeight="400" dangerouslySetInnerHTML={{ __html: t('waiter.text') }}/>
                <Figure>
                    <StyledFood/>
                </Figure>
            </Container>
            <Footer>
                <Hint hintText={t('waiter.hintText')} smaller margin="0 0 10px 0"/>
                <Button onClick={() => props.wizard.next()}>
                    {t('waiter.button')}
                </Button>
            </Footer>
        </>
    )
}

export default StepWaiter
