import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'

const StyledAddressLine = styled.div`
  flex-grow: 1;
  margin-left: 5px; 
`

const Address = (props) => {

    const { place, ...rest } = props

    return <StyledAddressLine {...rest}>
        <span>{place.street}, {place.city}</span>
    </StyledAddressLine>
}

Address.prototype = {
    place: PropTypes.object.isRequired,
}


export default Address
