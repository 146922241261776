import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import 'react-circular-progressbar/dist/styles.css'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'

const ProgressContainer = styled.div`
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`

const ProgressbarContent = styled.div`
  font-size: 0.8em;
  font-weight: 600;
  & p {
    margin: 0 auto;
    line-height: 14px;
  }
`

const CashbackProgressBar = (props) => {
    const { t } = useTranslation()
    const { progressRatio, progressValue } = props
    const [lazyProgressRatio, setLazyProgressRatio] = useState(0)

    useEffect(() => {
        setLazyProgressRatio(progressRatio)
    }, [lazyProgressRatio, progressRatio]) //used to show animated progressbar

    return (
        <ProgressContainer>
            <CircularProgressbarWithChildren strokeWidth={10} value={lazyProgressRatio} styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0,

                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',

                // Text size
                textSize: '20px',

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 2.0,

                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',

                // Colors
                pathColor: `#52919d`,
                textColor: '#000',
                trailColor: '#d6d6d6',
                background: '#52919d',
            })}>
                <ProgressbarContent>
                    <p>{progressValue}</p>
                    <p>€</p>
                </ProgressbarContent>
            </CircularProgressbarWithChildren>
        </ProgressContainer>
    )
}

export default CashbackProgressBar
