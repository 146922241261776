import React, { useEffect } from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import HeaderToGo from '../components/HeaderToGo'
import { Step, Steps, Wizard } from 'react-albus'
import { useDispatch, useSelector } from 'react-redux'
import steps from '../wizardConfig'
import { passStep, setPassedSteps } from '../actions/wizardStateActions'
import ErrorBoundary from '../components/ErrorBoundary'
import { isToGoSelector, shouldSkipCameraSelector, shouldSkipTipsSelector } from '../reducers/restaurant'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { cancelTransaction, persistTransaction } from '../actions/transactionActions'

const StyledLink = styled.a`
  color: ${props => props.theme.mainColor};
  font-size: 1em;
  text-align: center;
  padding: 20px;
  text-decoration: none;
  
  &:hover {
      color: ${props => props.theme.boldActionColor};
  }
`

const StepWizard = (props) => {
    const { match: { url } } = props
    const history = useHistory()
    const location = useLocation()
    const isAmountEditable = useSelector(state => state.appState.amountEditable)
    // const passedSteps = useSelector(state => state.wizardState.passedSteps)
    const isToGo = useSelector(state => isToGoSelector(state))
    const skipTips = useSelector(state => shouldSkipTipsSelector(state))
    const skipCamera = useSelector(state => shouldSkipCameraSelector(state))
    const cancelReturnUrl = useSelector(state => state.paymentData.paymentGatewayData?.cancelReturnUrl)
    const transaction = useSelector(state => state.transaction)
    const trueSteps = steps(skipTips, isToGo, !isAmountEditable || skipCamera)
    const dispatch = useDispatch()
    const { pathname } = location
    const { t } = useTranslation()

    useEffect(() => {
        const whiteList = [...['/wizard/step-6', '/wizard/step-6/sent', '/wizard/step-1/authorization']]

        if (!whiteList.includes(pathname)) {
            dispatch(setPassedSteps(['step-1']))
            history.replace('/wizard/step-1')
        }
    }, [])

    let header = ''
    header = <HeaderToGo/>

    const onNext = (wizard) => {
        if (!wizard.step.id) return

        dispatch(passStep(wizard.step.id))

        wizard.push()
    }

    const backToShop = async (event) => {
        event.stopPropagation()
        event.preventDefault()

        if (transaction.id) {
            dispatch(cancelTransaction())
            await dispatch(persistTransaction())
            window.location.href = cancelReturnUrl
        } else {
            window.location.href = cancelReturnUrl
        }
    }

    return (
        <ErrorBoundary>
            <Wizard history={history} basename={url} onNext={onNext}>
                {header}
                <Steps>
                    {Object.keys(trueSteps).map((stepKey, key) => (
                        <Step key={`${key}-${stepKey}`} id={stepKey} render={(wizard) => trueSteps[stepKey].component({ wizard })}/>
                    ))}
                </Steps>
            </Wizard>
            {(cancelReturnUrl && pathname !== '/wizard/step-6') && <StyledLink onClick={backToShop} href="#">{t('backToShop')}</StyledLink>}
        </ErrorBoundary>
    )
}

export default StepWizard
