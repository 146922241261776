import { setXHRInProgress } from './appStateActions'
import { SET_VOUCHER_CARD, SET_VOUCHER_NUMBER_CODE, SET_VOUCHER_QR_CODE } from './types'
import { getVoucherCard, validateVoucherCardPin } from '../services/voucherCards'
import { setVoucherBalance } from './voucherBalanceActions'

export const setVoucherCard = (payload) => {
    return {
        type: SET_VOUCHER_CARD,
        payload: payload,
    }
}

export const setVoucherQrCode = (payload) => {
    return {
        type: SET_VOUCHER_QR_CODE,
        payload: payload,
    }
}

export const setVoucherNumberCode = (payload) => {
    return {
        type: SET_VOUCHER_NUMBER_CODE,
        payload: payload,
    }
}

// Thunk actions (async)
export const fetchVoucherCard = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const restaurant = state.restaurant
        const voucherCard = state.voucherCard

        try {
            dispatch(setXHRInProgress(true))
            const result = await getVoucherCard(restaurant.companyId, voucherCard.qrCode, voucherCard.numberCode)
            const { balance, ...card } = result.data
            dispatch(setVoucherCard(card))
            if (balance) {
                dispatch(setVoucherBalance(balance))
            }
            dispatch(setXHRInProgress(false))
            return Promise.resolve(result.data)
        } catch (e) {
            dispatch(setXHRInProgress(false))
            return Promise.reject(e)
        }
    }
}

/**
 * Sends request to the server that validates card pin with entered pin saved
 * in voucherRequest state
 *
 * @return Promise. data is 200 on success, 422 on failure
 */
export const validateEnteredPin = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const voucherCardId = state.voucherCard.id
        const pin = state.voucherRequest.pin

        try {
            dispatch(setXHRInProgress(true))
            const result = await validateVoucherCardPin(voucherCardId, pin)
            dispatch(setXHRInProgress(false))
            return Promise.resolve(result)
        } catch (e) {
            dispatch(setXHRInProgress(false))
            return Promise.reject(e)
        }
    }
}
