import {
    CLEAR_STATE,
    CLEAR_WIRECARD_REQUEST_STATE,
    REMOVE_WIRECARD_REQUEST,
    SET_APPLE_PAY_TOKEN,
    SET_GOOGLE_PAYMENT_DATA,
    SET_PAYMENT_METHOD,
    SET_TRANSACTION,
    SET_WIRECARD_REQUEST,
} from '../actions/types'

const initState = {
    id: null,
    status: null,
    authorizationStatus: 0,
    merchantAccountId: null,
    paymentMethodId: null,
    transactionId: null,
    token: null,
    googlePaymentData: null,
}

export function wirecardRequest (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_WIRECARD_REQUEST: {
            return { ...state, ...action.payload }
        }
        case CLEAR_WIRECARD_REQUEST_STATE: {
            return { ...initState }
        }
        case SET_PAYMENT_METHOD:
            return { ...state, paymentMethodId: action.paymentMethod.id }
        case SET_TRANSACTION:
            return { ...state, transactionId: action.payload.id }
        case SET_APPLE_PAY_TOKEN:
            return { ...state, token: action.payload }
        case SET_GOOGLE_PAYMENT_DATA:
            return { ...state, googlePaymentData: action.payload }
        case CLEAR_STATE: {
            return { ...initState }
        }
        case REMOVE_WIRECARD_REQUEST:
            return { ...state, id: null }
        default:
            return state
    }
}
