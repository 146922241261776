import { CLEAR_WIRECARD_REQUEST_STATE, REMOVE_WIRECARD_REQUEST, SET_APPLE_PAY_TOKEN, SET_GOOGLE_PAYMENT_DATA, SET_PAYMENT_METHOD, SET_WIRECARD_REQUEST } from './types'
import { createWirecardRequest, deleteWirecardRequest, getWirecardRequest, updateWirecardRequest } from '../services/wirecardRequests'

export const setWirecardRequest = (payload) => {
    return {
        type: SET_WIRECARD_REQUEST,
        payload: payload,
    }
}

export function setPaymentMethod (paymentMethod) {
    return {
        type: SET_PAYMENT_METHOD,
        paymentMethod: paymentMethod,
    }
}

export const removeWirecardRequest = () => {
    return {
        type: REMOVE_WIRECARD_REQUEST,
    }
}

export const clearWirecardRequest = () => {
    return {
        type: CLEAR_WIRECARD_REQUEST_STATE,
    }
}

export const setApplePayToken = (payload) => {
    return {
        type: SET_APPLE_PAY_TOKEN,
        payload: payload,
    }
}

export const setGooglePaymentData = (payload) => {
    return {
        type: SET_GOOGLE_PAYMENT_DATA,
        payload: payload,
    }
}

// Thunk actions (async)

export const fetchWirecardRequest = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const wirecardRequest = state.wirecardRequest

        try {
            const result = await getWirecardRequest(wirecardRequest.id)

            dispatch(setWirecardRequest(result.data))

            return Promise.resolve(result.data)
        } catch (e) {
            if (e.message === 'Network Error') {
                //for now we need this as a quick fix for a safari and firefox bug
                //https://bugzilla.mozilla.org/show_bug.cgi?id=1280189
                return Promise.resolve(wirecardRequest)
            } else {
                return Promise.reject(e)
            }
        }
    }
}

export const createOrUpdateWirecardRequest = (data = {}) => {
    return async (dispatch, getState) => {
        const state = getState()
        const wirecardRequest = state.wirecardRequest

        try {
            const result = wirecardRequest.id ? await updateWirecardRequest(wirecardRequest) : await createWirecardRequest(wirecardRequest)

            dispatch(setWirecardRequest(result.data))

            return Promise.resolve(result.data)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

/**
 * Cancels the voucher request on the server
 * And sets id to null in state
 * @return Promise
 */
export const destroyWirecardRequest = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const wirecardRequest = state.wirecardRequest

        try {
            await deleteWirecardRequest(wirecardRequest.id)
            dispatch(fetchWirecardRequest())
            dispatch(removeWirecardRequest())

            return Promise.resolve()
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
