import API, { URLs } from '../api'

export const getClient = (client) => {
    return API.get(`${URLs.clients}/${client.id}`)
}

export const createClient = (payload) => {
    return API.post(URLs.clients, { client: { ...payload } }, { headers: { 'Key-Inflection': 'camel' } })
}

export const updateClient = (client) => {
    return API.patch(`${URLs.clients}/${client.id}`, { client: client }, { headers: { 'Key-Inflection': 'camel' } })
}
