import { Notifier } from '@airbrake/browser'

export const airbrake = new Notifier({
    projectId: 246598,
    projectKey: '4d1cfe9d8d5d36f97b1acf939183c6a8',
    environment: process.env.REACT_APP_ENV,
})

airbrake.addFilter(function (notice) {
    if (notice.context.environment === 'development') return null

    return notice
})
