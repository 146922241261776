import { useEffect } from 'react'

export default function () {
    useEffect(() => {
        window.history.pushState(null, null, window.location.href)
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.href)
        }

        return function cleanup () {
            window.onpopstate = () => {}
        }
    }, [])
}
