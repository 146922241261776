import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Figure from './Figure'

const Navigation = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 10px;

  &:after {
    width: calc(${props => props.stepAmount - 1} * 50px);
    content: '';
    position: absolute;
    height: 3px;
    display: block;
    background: ${props => props.theme.mainColor};
    bottom: 0;
    
    @media (min-width: ${props => props.theme.desktop}) {
      background-color: ${props => props.theme.mainColorDesktop};
    }
  }
`

const Item = styled.li`
  position: relative;
  width: 30px;
  height: 30px;
  list-style-type: none;
  margin-right: 20px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;

    width: 0;
    height: 10px;
    bottom: -10px;
    left: 44%;
    border-bottom: 3px solid ${props => props.theme.wizardNavigation};
    border-right: 4px solid ${props => props.theme.wizardNavigation};
    z-index: 10;
    display: ${props => props.active ? 'block' : 'none'};
  }

  &:nth-child(2) {
    &:after {
      width: 54px;
      left: -37px;
    }
  }
  &:nth-child(3) {
    &:after {
      width: 104px;
      left: -87px;
    }
  }
  &:nth-child(4) {
    &:after {
      width: 154px;
      left: -138px;
    }
  }
  &:nth-child(5) {
    &:after {
      width: 203px;
      left: -187px;
    }
  }
  &:nth-child(6) {
    &:after {
      width: 262px;
      left: -245px;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
`

const StyledFigure = styled(Figure)`
  @media (min-width: ${props => props.theme.desktop}) {
    #background {
      fill: ${props => props.theme.toolTipDesktop};
    }
  }
  
  ${props => props.active && `
    #background {
      fill: ${props.theme.wizardNavigation};
    }
    #icon {
      path, rect {
        fill: #ffffff;
      }
    }
  `};
`

const WizardStepNavigation = (props) => {
    const { stepConfig, wizard } = props
    const passedSteps = useSelector(state => state.wizardState.passedSteps)

    if (!stepConfig) return null

    if (!stepConfig[wizard.step.id || 'step-1'].showNavigation) return null

    const onClick = ({ item, wizard }) => {
        if (passedSteps.includes('step-6')) { // if step-6 is passed, we disable the possibility to go back
            // click on step-6 and passed steps include step-6/sent aka e-mail entered go back to step-6
            if (item === 'step-6' && passedSteps.includes('step-6/sent')) {
                wizard.previous()
            }
            return
        }

        if (passedSteps.includes(item)) {
            wizard.push(item)
        }
    }

    const stepsToShow = Object.keys(stepConfig).reduce((p, c) => {
        if (stepConfig[c].showOnTimeline) p[c] = stepConfig[c]
        return p
    }, {})

    return (
        <Navigation stepAmount={Object.keys(stepsToShow).length}>
            {Object.keys(stepConfig).map((item, index) => {
                if (!stepConfig[item].showOnTimeline) return null
                return (
                    <Item
                        key={index}
                        active={wizard.step.id ? wizard.step.id.includes(item) : false}
                        onClick={() => onClick({ item, wizard })}>
                        <StyledFigure active={wizard.step.id?.includes(item)}>
                            {stepConfig[item].icon}
                        </StyledFigure>
                    </Item>
                )
            })}
        </Navigation>
    )
}

WizardStepNavigation.propTypes = {
    stepConfig: PropTypes.objectOf(PropTypes.object).isRequired,
    wizard: PropTypes.object.isRequired,
}

export default WizardStepNavigation
