import PointsDisplay from './PointsDisplay'
import BalanceDisplay from './BalanceDisplay'
import React from 'react'
import BaseVoucherBuyButton from '../BaseVoucherBuyButton'
import {CardActionButton} from '../_baseComponents'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'

const StyledVoucherBuyButton = styled(BaseVoucherBuyButton)`
${CardActionButton}
`

const CardActions = (props) => {
    const {t} = useTranslation()
    const {company} = props

    return <div style={{display: 'flex', textAlign: 'center', alignItems: 'stretch'}}>

        <div style={{flexGrow: '1', width: '33%'}}>
            <PointsDisplay loyalty={company.loyalty}/>
        </div>
        <div style={{flexGrow: '1', width: '33%'}}>
            <BalanceDisplay voucher={company.voucher}/>
        </div>
        <div style={{flexGrow: '1', width: '33%'}}>
            <StyledVoucherBuyButton company={company}>{t('profile.actions.voucher.buy')}</StyledVoucherBuyButton>
        </div>

    </div>

}

export default CardActions
