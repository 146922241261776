import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as actionFlame } from '../../../images/profile/flame.svg'

const DealContainer = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 100%;
    top: -7px;
    z-index: 1;
    font-size: ${props => props.theme.fontSize};
    ${props => props.primarydeal === 'true' && 'font-size: 1.2em; top: -10px;'}
`

const DealContent = styled.h3`
    display: flex;        
    position: relative;
    margin: 0;
    padding: 0.2em 1em 0.2em 1em;
    border-radius: 2px;   
    font-size: 0.65em;
    color: ${props => props.theme.profile.dealHighlightColor};
    box-shadow: ${props => props.theme.profile.dealHighlightShadow};
    background: ${props => props.theme.profile.dealHighlightBackground};
    justify-content: center;
    align-content: center;
    ${props => props.primary === 'true' && 'width:80%; border-radius:5px; box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.5)'}
`

const DealIcon = styled(actionFlame)`
    height: 23px;
    width: 23px;

    display: inline-block;
    position: absolute;
    left: -13px;
    top: -2px;
    ${props => props.primarydeal === 'true' &&
    'height: 27px;' +
    'width: 27px;' +
    'top: -4px;'}
    
`

const DealBadge = (props) => {
    const { t } = useTranslation()
    const { company, primaryDeal } = props
    const primaryDealString = (!primaryDeal) ? false.toString() : primaryDeal.toString()

    //Camelcase component property produces error log when using svg.
    return <DealContainer primarydeal={primaryDealString}>
        <DealContent primarydeal={primaryDealString}>
            <DealIcon primarydeal={primaryDealString}/>
            {company.deals[0].cashback && <span>{t('profile.list.cashbackDeal', { amount: company.deals[0].cashback })}</span>}
            {company.deals[0].voucher && <span>{t('profile.list.voucherDeal', { amount: company.deals[0].voucher })}</span>}
        </DealContent>
    </DealContainer>
}

export default DealBadge
