import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, FormSpy } from 'react-final-form'
import Input from './Input'
import { useTranslation } from 'react-i18next'
import { OnChange } from 'react-final-form-listeners'
import styled from 'styled-components'

const StyledForm = styled.form`
  margin-bottom: 4px;
  & input {
    text-align: center;
    border: solid;
    border: 0px;
    border-bottom: 1px solid #ccc;
    padding: 0px;
    margin-bottom: 5px;
  }
`

const EmailForm = ({ onSubmit, initialValues, onChange, onValidationChanged, formId }) => {
    const { t } = useTranslation()

    const required = value => (value ? undefined : t('finished.errors.invalid'))
    // eslint-disable-next-line no-control-regex
    const isEmail = value => (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value) ? undefined : t('finished.errors.invalid'))
    const composeValidators = (...validators) => value =>
        validators.reduce((error, validator) => error || validator(value), undefined)

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, pristine, reset, submitting }) => (
                <StyledForm onSubmit={handleSubmit} id={formId}>
                    <Field
                        name="email"
                        type="email"
                        component={Input}
                        placeholder={t('mailAddress')}
                        maxLength="150"
                        inputVariant="medium"
                        inputAlign="center"
                        validate={composeValidators(required, isEmail)}
                    />
                    {onChange && <OnChange name="email">
                        {onChange}
                    </OnChange>}
                    {onValidationChanged && <FormSpy subscription={{ valid: true }} onChange={onValidationChanged}/>}
                </StyledForm>
            )}
        </Form>
    )
}

EmailForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    formId: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onValidationChanged: PropTypes.func,
}

EmailForm.defaultProps = {
    initialValues: {},
}

export default EmailForm
