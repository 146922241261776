import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from './Label'
import Image from './Image'
import Text from './Text'
import { useDispatch, useSelector } from 'react-redux'
import { persistTransaction, setTipsAmount, setTipsPercentage } from '../actions/transactionActions'
import tipHaken from '../images/tip_haken.svg'
import { useTranslation } from 'react-i18next'

const TipsChooserWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 10px;
`

const TipsItemWrapper = styled(Label)`
  margin-right: 12px;
  cursor: pointer;
  text-align: center;

  &:last-of-type,
  &:last-child {
    margin-right: 0;
  }
`

const TipsIncremeterWrapper = styled.div`
  cursor: pointer;
  text-align: center;

  &:last-of-type,
  &:last-child {
    margin-right: 0;
  }
`

const StyledInput = styled.input`
  display: none;
  
  &:checked ~ span {
    color: ${props => props.theme.tipsChooser.selectedColor};
    background-color: ${props => props.theme.tipsChooser.selectedBackground};
    border: 1px solid ${props => props.theme.tipsChooser.selectedBorderColor};
    
    @media (min-width: ${props => props.theme.desktop}) {
      color: ${props => props.theme.tipsChooser.selectedColorDesktop};
      background-color: ${props => props.theme.tipsChooser.selectedBackgroundDesktop};      
      border: 1px solid ${props => props.theme.tipsChooser.selectedBorderColor};
    }
  }
`

const Box = styled.span`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: ${props => (props.incrementer) ? '48px' : '90px'};
  height: ${props => (props.incrementer) ? '48px' : '90px'};
  font-size: 1.6em;
  font-weight: 500;
  border: 1px solid ${props => props.theme.secondaryInputColor};
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;
`

const TickBox = styled.div`
  position: absolute;
  top:-25px;
  right: -25px;
  width: 50px;
  height: 50px;
  padding: 0px;
  transform: rotate(45deg);
  background-color: ${props => props.theme.boldActionColor};
  vertical-align: bottom;
  
  
  & img {
    position: absolute;
    left: 15px;
    bottom: 5px;
    max-width: 15px;
    transform: rotate(-45deg);
    
    @media (min-width: ${props => props.theme.desktop}) and (-webkit-min-device-pixel-ratio:0) {
      bottom:10px;
    }
    
    @-moz-document url-prefix() {
      bottom: 5px;
    }
    
    @media not all and (min-resolution:.001dpcm) { @media {
      bottom: 5px;
    }}
}
`

const ListOfTips = [
    { value: 10 },
    { value: 15 },
    { value: 20 },
]

const SecondaryText = styled(Text)`
  color: ${props => props.theme.secondaryInputColor}
`

const TipsHint = styled(Text)`
  font-size: 0.8em;
  color: ${props => props.theme.secondaryInputColor}; 
  
  height: 48px;
  line-height: 48px;
  padding: 0px;
  margin: 0px;
`

const FreeInputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
`

const TipsItem = (props) => {
    const { onChange, value, checked } = props
    const { t } = useTranslation()

    return (
        <TipsItemWrapper>
            <StyledInput type="radio" name="tips" onChange={onChange} onClick={onChange} value={value} checked={checked}/>
            <Box>
                <Text fontSize='1em'>{value}%</Text>
                <SecondaryText fontSize='0.5em'>{t('tips.title3')}</SecondaryText>
                {checked && <TickBox><Image src={tipHaken}/></TickBox>}
            </Box>
        </TipsItemWrapper>
    )
}

const TipsIncrementer = (props) => {
    const { onClick, text } = props
    return (
        <TipsIncremeterWrapper onClick={onClick}>
            <Box incrementer>
                <Text fontSize='1.1em'>{text}</Text>
            </Box>
        </TipsIncremeterWrapper>
    )
}

TipsItem.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    checked: PropTypes.bool,
    isToGo: PropTypes.bool,
}

TipsItem.defaultProps = {
    checked: false,
    isToGo: false,
}


const TipsChooser = (props) => {
    const { t } = useTranslation()
    const { isToGo } = props
    const dispatch = useDispatch()
    const xhrInProgress = useSelector(state => state.appState.xhrInProgress)
    const transaction = useSelector(state => state.transaction)
    const tipsPercentage = transaction.tipsPercentage
    const tipsAmountCents = transaction.tipsAmountCents

    const handleChange = event => {
        if (xhrInProgress) return
        const value = parseInt(event.target.value, 10)
        if (value === tipsPercentage) {
            dispatch(setTipsPercentage(0))
        } else {
            dispatch(setTipsPercentage(value))
        }
        dispatch(setTipsAmount(null))

        dispatch(persistTransaction())
    }


    const getDesiredStepBase = (baseAmount) => {
        if (baseAmount < 500) {
            return 10
        }
        if (baseAmount < 1000) {
            return 20
        }
        if (baseAmount < 2000) {
            return 50
        }
        if (baseAmount < 5000) {
            return 100
        }
        return 200

    }
    const getTipStep = (signBase) => {
        const baseAmount = transaction.totalAmountCents
        const desiredStepMultiple = getDesiredStepBase(baseAmount)
        const baseTotalAmount = baseAmount + desiredStepMultiple
        const desiredAmount = Math.floor(baseTotalAmount / desiredStepMultiple) * desiredStepMultiple
        const desiredStepAmount = Math.abs(desiredAmount - baseAmount) * signBase
        if (signBase < 0 && Math.abs(desiredStepAmount) >= tipsAmountCents) {
            return -tipsAmountCents
        }
        return desiredStepAmount
    }

    const incrementTip = (sign) => {
        const stepJump = getTipStep(sign)
        if (stepJump < 0 && tipsAmountCents <= 0) {
            return
        }
        dispatch(setTipsAmount(tipsAmountCents + stepJump))
        dispatch(setTipsPercentage(0))
        dispatch(persistTransaction())
    }

    return (
        <>
            <TipsChooserWrapper isToGo={isToGo}>
                {ListOfTips.map((item, index) => (
                    <TipsItem
                        key={index}
                        value={item.value}
                        onChange={handleChange}
                        checked={tipsPercentage === item.value}
                        isToGo={isToGo}
                    />
                ))}
            </TipsChooserWrapper>
            <FreeInputWrapper>
                <TipsIncrementer text="-" onClick={() => incrementTip(-1)}/>
                <TipsHint>{t('tips.freeInputTitle')}</TipsHint>
                <TipsIncrementer text="+" onClick={() => incrementTip(1)}/>
            </FreeInputWrapper>
        </>
    )
}

TipsChooser.propTypes = {
    isToGo: PropTypes.bool,
}

export default TipsChooser
