import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledHeadline = styled.h1`
  text-align: center;
  line-height: 1.125;
  font-weight: ${props => props.fontWeight};
  
  font-size: ${props => props.bigger && '3em'};
  font-size: ${props => props.smaller && '1.5em'};
  font-size: ${props => props.xsmaller && '1.3em'};
`

const Headline = ({ children, ...rest }) => {
    return <StyledHeadline {...rest}>{children}</StyledHeadline>
}

Headline.propTypes = {
    fontWeight: PropTypes.string,
    bigger: PropTypes.bool,
    smaller: PropTypes.bool,
}

export default Headline
