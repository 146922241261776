import React, { useEffect, useRef, useState } from 'react'
import { compose } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { initIsBillScanned } from '../actions/appStateActions'
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo'
import Container from '../components/Container'
import styled from 'styled-components'
import { base64toBlob } from '../utils'
import Footer from '../components/Footer'
import CameraNotAllowedIcon from '../images/camera_not_allowed.svg'
import CameraPlain from '../images/camera_plain_white.svg'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button'
import { isToGoSelector } from '../reducers/restaurant'
import PropTypes from 'prop-types'
import { setReceiptScanned } from '../actions/paymentDataActions'
import { useTracker } from '../hooks'
import API, { URLs } from '../api'
import Processing from './Processing'
import Image from '../components/Image'
import Text from '../components/Text'

const EqualWidthBox = styled.div`
  flex: 1;
  
  margin: 0 20px;
  
  &:first-child,
  &:last-child {
    margin: 0
  }
`

const StyledButton = styled(EqualWidthBox)`
  background-color: ${props => props.theme.schemeMain};
  color: white;
  border-radius: 12px;
  border: 4px solid ${props => props.theme.schemeMain};
  box-shadow: 0 0 0 1.5px white inset;
  padding: 6px 14px 6px 14px;
`

const CameraButton = styled(StyledButton)`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: 24%;
  flex-grow: 0;
`

const StyledVideo = styled.video`
  width: 100%;
  object-fit: fill;
  flex-grow: 1;
`

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: flex-end;
`

const StepCamera = ({ restaurant, transaction, wizard, isToGo }) => {
    let cameraPhoto = useRef()
    let camera = useRef()

    const [hasCameraAccess, setHasCameraAccess] = useState(true)
    const [showLoading, setShowLoading] = useState(false)
    useTracker(null)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(initIsBillScanned())
        cameraPhoto.current = new CameraPhoto(camera.current)
        startCamera(FACING_MODES.ENVIRONMENT, {})

        return function cleanup () {
            cameraPhoto.current.stopCamera()
                .catch((error) => {
                    console.info(error)
                })
        }
    }, [])

    const startCamera = (idealFacingMode, idealResolution) => {
        cameraPhoto.current.startCamera(idealFacingMode, idealResolution)
            .then(() => {
                setHasCameraAccess(true)
            })
            .catch((error) => {
                console.error(error)
                setHasCameraAccess(false)
            })
    }

    const handleCaptureButton = () => {
        let config = {
            //Used to get a desired resolution. Example, a sizeFactor of 1 get the same resolution of the
            // camera while sizeFactor of 0.5 get the half resolution of the camera. The sizeFactor can be
            // between range of ]0, 1] and the default value is 1
            sizeFactor: 1,
            // Used to get the desired image type between jpg or png. to specify the imageType use the constant
            // IMAGE_TYPES, for example to specify jpg format use IMAGE_TYPES.JPG. The default imageType is png.
            imageType: IMAGE_TYPES.JPG,
            // Used to get the desired compression when jpg is selected. choose a compression between [0, 1],
            // 1 is maximum, 0 is minimum. The default value imageCompression is 0.92
            imageCompression: 0,
            // Used to get an image mirror when is set to true, the result of the dataUri is the mirror of the actual
            // camera data. Many software that use camera mirror like hangout etc... Please note if you want to enable this option,
            // for consistency with the camera video, you need to use css transform: rotateY(180deg) to the <video> tag
            // to mirror the stream, because the stream is not mirrored. It's only apply to the canvas dataUri. The default value is false (no mirror)
            isImageMirror: false,
        }
        takePhoto(cameraPhoto.current.getDataUri(config))
    }

    const takePhoto = (dataUri) => {
        setShowLoading(true)

        if (dataUri) {
            const formData = new FormData()
            const blob = base64toBlob(dataUri.split(',')[1], 'image/jpeg')
            formData.append('transaction[receipt_photo]', blob, `restaurant_${restaurant.id}.jpg`)
            API.patch(URLs.transactions + '/' + transaction.id, formData, { headers: { 'Content-Type': 'application/json' } })
                .then(resp => {
                    if (resp.data.ocr_response?.statusCode || 0 >= 400) {
                        dispatch(setReceiptScanned(resp.data))
                    }
                    dispatch(setReceiptScanned(resp.data))
                })
                .catch(() => {
                    dispatch(setReceiptScanned({}))
                })
                .finally(() => {
                    if (isToGo) {
                        wizard.previous()
                    } else {
                        wizard.next()
                    }
                })
        }
    }

    const skip = () => {
        isToGo ? wizard.previous() : wizard.next()
    }

    if (showLoading) {
        return <Processing text={t('notifications.receiptScanning')}/>
    }

    if (!hasCameraAccess) {
        return (
            <>
                <Container>
                    <Text variant="size-5" align="left">
                        {t('camera.accessDenied')}
                    </Text>
                    <Image maxWidth="70%" src={CameraNotAllowedIcon} alt={''}/>
                </Container>
                <Footer>
                    <Button onClick={() => skip()}>
                        {t('camera.button')}
                    </Button>
                </Footer>
            </>
        )
    }
    return (
        <Container centered fluid>
            <StyledVideo ref={camera} autoPlay={true} playsInline={true}/>
            <ButtonWrapper>
                <StyledButton style={{ visibility: 'hidden' }}/>
                <CameraButton onClick={handleCaptureButton}>
                    <Image src={CameraPlain} alt={t('camera.click')}/>
                    <Text variant="size-5">
                        {t('camera.click')}
                    </Text>
                </CameraButton>
                <StyledButton onClick={skip}>
                    <Text fontSize="0.8em">
                        {t('camera.button')}
                    </Text>
                </StyledButton>
            </ButtonWrapper>
        </Container>
    )
}

const mapStateToProps = (state) => {
    const { restaurant, transaction } = state
    return {
        restaurant,
        transaction,
        isToGo: isToGoSelector(state),
    }
}

StepCamera.propTypes = {
    restaurant: PropTypes.object.isRequired,
    transaction: PropTypes.object.isRequired,
    isToGo: PropTypes.bool.isRequired,
}

export default compose(
    connect(mapStateToProps),
)(StepCamera)
