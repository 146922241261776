import React from 'react'
import Container from '../components/Container'
import { Trans, useTranslation } from 'react-i18next'
import ErrorBoundary from '../components/ErrorBoundary'
import { useTracker } from '../hooks'
import Text from '../components/Text'
import Headline from '../components/Headline'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { ReactComponent as NotFound } from '../images/error_icon.svg'
import styled from 'styled-components'

const StyledNotFound = styled(NotFound)`
  width: 120px;
  height: 120px;
  fill: ${props => props.theme.danger};
`

const NotFoundPage = () => {
    useTracker('404')
    useTranslation()

    return (
        <ErrorBoundary>
            <Header onlyLogo/>
            <Container centered>
                <StyledNotFound/>
                <Headline as="h2">
                    <Trans i18nKey={'notFound.headline'}/>
                </Headline>
                <Text>
                    <Trans i18nKey={'notFound.subtitle'}/>
                </Text>
            </Container>
            <Footer/>
        </ErrorBoundary>
    )
}

export default NotFoundPage
