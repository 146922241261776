import React, { useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { payStripeWithCard } from '../../services/paymentStripe'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import { Column, Columns } from '../../components/Columns'
import styled from 'styled-components'
import Text from '../../components/Text'
import RemoteSubmitButton from '../../components/RemoteSubmitButton'

const StyledText = styled(Text)`
  display: none;

  @media (min-width: ${props => props.theme.desktop}) {
    display: block;
    font-size: 15px;
    font-weight: 700;
    margin-left: ${props => props.theme.containerPadding};
    margin-top: 1rem;
  }
`

const StyledCardElement = styled(CardElement)`
  border: 1px solid black;
  padding: 15px 20px;
  font-size: 1.1em;
`

const Error = styled(Text)`
  background-color: ${props => props.theme.danger};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: ${props => props.visible ? 'block' : 'none'};

  @media (min-width: ${props => props.theme.desktop}) {
    color: ${props => props.theme.mainColor}
  }
`

const StripeCredentials = (props) => {
    const stripe = useStripe()
    const elements = useElements()
    const paymentIntent = useSelector(state => state.stripePaymentIntent)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return
        }
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement)

        setLoading(true)
        setError(null)

        try {
            const result = await dispatch(payStripeWithCard(stripe, cardElement))

            setLoading(false)

            if (result.error) {
                console.error(result.error)
                setError(result.error)
            } else {
                props.wizard.next()
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    // TODO: Card element can not be found after this. Use overlay solution
    // if (loading) {
    //     return <Processing/>
    // }

    return (<>
            <Container stretched>
                <Columns>
                    <Column>
                        <StyledText align="left">{t('paymentCredentials.hint')}</StyledText>
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <form onSubmit={handleSubmit} id="stripe-card-form">
                            <StyledCardElement/>
                        </form>
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <Error visible={error}>
                            {error?.message}
                        </Error>
                    </Column>
                </Columns>
            </Container>
            <Footer>
                <RemoteSubmitButton formName="stripe-card-form" disabled={!stripe || loading}>
                    {t('paymentTypes.buttonText.creditCard')}
                </RemoteSubmitButton>
            </Footer>
        </>
    )
}

export default StripeCredentials