import React from 'react'
import styled from 'styled-components'
import Text from '../Text'

const SelectButton = styled.div`
  cursor: pointer;
  padding: 0px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  
  height: 60px;
  width: 60px;
  border-radius: 100%;
 
  margin: auto; 
  position: relative;
 
  border: ${props => (!props.selected) ? `1px solid ${props.theme.mainColor}` : '1px solid lightgrey'};
  background: ${props => (!props.selected) ? `${props.theme.profileActionColor}` : '#e7e7e7'};
  
  & svg {
    object-fit: cover;
    padding: 1px; 
    fill: ${props => (!props.selected) ? `white` : 'black'};
  }
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    bottom: -7px;
    left: -7px;
    right: -7px;
    border-radius: 100%;
    border: ${props => (props.selected) ? `3px solid ${props.theme.profile.cashbackProgressColor}` : 'none'};
  }
  
`

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;

  font-size: 0.8em;
  font-weight: 500;

  align-content: center;
  padding: 1.5em;
`

const BadgeContainer = styled.div`
  height: 1.2em;
  font-size: 0.8em;
  font-weight: 300;
  text-align: center;
`

const BottomBadge = styled.span`
  border-radius: 10px;
  background: #F0EEF1;
  padding: 0.2em 0.8em;
  font-weight: 500;
`

const Subtitle = styled(Text)`
  margin-top: 1em;
`

const LoyaltyCategoryIconButton = (props) => {
    const {
        categoryDetails,
        bottomBadge,
        selected,
        onClick,
        ...rest
    } = props

    return (
        <ButtonContainer>
            <SelectButton
                color={categoryDetails.color}
                selected={selected}
                onClick={onClick}
                {...rest}>
                <categoryDetails.icon width={categoryDetails.iconWidth}/>
            </SelectButton>


            <Subtitle smaller>{categoryDetails.shortTitle}</Subtitle>
            <BadgeContainer>
                {bottomBadge && <BottomBadge>{bottomBadge}</BottomBadge>}
            </BadgeContainer>
        </ButtonContainer>
    )
}

export default LoyaltyCategoryIconButton
