import { CLEAR_STATE, SET_VOUCHER_CARD, SET_VOUCHER_NUMBER_CODE, SET_VOUCHER_QR_CODE } from '../actions/types'

const initState = {
    id: null,
    qrCode: null,
    numberCode: null,
}

export function voucherCard (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_VOUCHER_CARD:
            return { ...state, ...action.payload }
        case SET_VOUCHER_QR_CODE:
            return { ...state, qrCode: action.payload }
        case SET_VOUCHER_NUMBER_CODE:
            return { ...state, numberCode: action.payload }
        case CLEAR_STATE: {
            return { ...initState }
        }
        default:
            return state
    }
}
