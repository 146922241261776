import { CANCEL_TRANSACTION, SET_AMOUNT, SET_EXTERNAL_TRX_ID, SET_LINE_ITEMS, SET_TIPS_AMOUNT, SET_TIPS_PERCENTAGE, SET_TRANSACTION, SET_WAITER } from './types'
import { createTransaction, getTransaction, updateTransaction } from '../services/transactions'
import { isPaymentGatewayModeSelector } from '../reducers/paymentData'
import { createPaymentGatewayTransaction } from '../services/paymentGatewayTransactions'
import { setXHRInProgress } from './appStateActions'

export const setTransaction = (payload) => {
    return {
        type: SET_TRANSACTION,
        payload: payload,
    }
}

export const setAmount = payload => {
    return {
        type: SET_AMOUNT,
        payload: payload,
    }
}

export const setTipsPercentage = value => {
    return {
        type: SET_TIPS_PERCENTAGE,
        payload: value,
    }
}

export const setTipsAmount = value => {
    return {
        type: SET_TIPS_AMOUNT,
        payload: value,
    }
}

export const setWaiter = payload => {
    return {
        type: SET_WAITER,
        payload: payload,
    }
}

export const setExternalTrxID = payload => {
    return {
        type: SET_EXTERNAL_TRX_ID,
        payload: payload,
    }
}

export const setLineItems = payload => {
    return {
        type: SET_LINE_ITEMS,
        payload: payload,
    }
}

export const cancelTransaction = () => {
    return {
        type: CANCEL_TRANSACTION,
    }
}

// Thunk actions (async)

export const fetchTransaction = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const transaction = state.transaction

        try {
            dispatch(setXHRInProgress(true))
            const result = await getTransaction(transaction)
            dispatch(setTransaction(result.data))

            return Promise.resolve(result.data)
        } catch (e) {
            if (e.message === 'Network Error') {
                //for now we need this as a quick fix for a safari and firefox bug
                //https://bugzilla.mozilla.org/show_bug.cgi?id=1280189
                return Promise.resolve(transaction)
            } else {
                return Promise.reject(e)
            }
        } finally {
            dispatch(setXHRInProgress(false))
        }

    }
}

export const persistTransaction = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const transaction = state.transaction

        try {
            dispatch(setXHRInProgress(true))
            const result = await updateTransaction(transaction)
            dispatch(setTransaction(result.data))
            dispatch(setXHRInProgress(false))

            return Promise.resolve(result.data)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

export const createOrUpdateTransaction = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const transaction = state.transaction
        const paymentData = state.paymentData

        try {
            if (transaction.id) {
                dispatch(setXHRInProgress(true))
                const result = await updateTransaction(transaction)
                dispatch(setTransaction(result.data))
                dispatch(setXHRInProgress(false))

                return Promise.resolve(result.data)
            } else {
                dispatch(setXHRInProgress(true))
                const result = await createTransaction(transaction)
                dispatch(setTransaction(result.data))

                if (isPaymentGatewayModeSelector(paymentData)) {
                    await createPaymentGatewayTransaction({ ...paymentData.paymentGatewayData, transaction_id: result.data.id })
                }

                dispatch(setXHRInProgress(false))
                return Promise.resolve(result.data)
            }
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
