import React, { useState } from 'react'
import styled from 'styled-components'
import FeatureTitle from './FeatureTitle'
import OverlayPopup from '../OverlayPopup'

const InfoButton = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background-color: white;
  color: darkgrey;
  position: absolute;
  top: 0px;
  right: -15px;
  cursor: pointer;
  font-size: 0.7em;
  line-height: 14px;
  align-items: flex-end;
  border: 1px solid darkgrey;
`

const DefaultPopupBody = styled.div`
  padding: 20px;
  width: 80%;
  margin: auto;
  font-size: 1.0em;
  font-weight: 300;
`
const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top:-7px;
  right: -7px;
  border-radius: 10px;
  font-size: 0.8em;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
`

const Wrapper = styled.div`
  font-size: ${props => props.theme.fontSize}; //reset relative fonts
`


const FeatureInfo = (props) => {
    const { customInfo, title, description, titleColor } = props
    const [showInfo, setShowInfo] = useState(false)
    return (
        <Wrapper>
            <InfoButton onClick={() => {setShowInfo(true)}}>i</InfoButton>
            {(showInfo) && <>
                <OverlayPopup onOutsideClick={() => {setShowInfo(false)}}>
                    <FeatureTitle style={{ marginBottom: '30px' }} color={titleColor}>
                        {title}
                    </FeatureTitle>
                    {(customInfo) && customInfo}
                    <DefaultPopupBody>
                        {description}
                    </DefaultPopupBody>
                    <CloseButton onClick={() => {setShowInfo(false)}}>
                        x
                    </CloseButton>
                </OverlayPopup>
            </>}

        </Wrapper>
    )
}

export default FeatureInfo
