import axios from 'axios'
import { history } from './configureStore'

export const URLs = {
    restaurants: '/v1/restaurants',
    transactions: '/v1/transactions',
    clients: '/v1/clients',
    paymentGatewayTransactions: '/v1/payment_gateway_transactions',
    wirecardRequests: '/v1/wirecard/requests',
    applePaySession: '/v1/apple_pay_sessions',
    creditCardInfo: '/v1/wirecard/credit_card_infos',
    voucherCards: '/v1/voucher/cards',
    voucherRequest: 'v1/voucher/requests',
    companies: '/v1/companies',
    // profile data
    profileData: '/user/v1/companies',
    companyFavorite: '/user/v1/favorite_companies',
    userCompanies: '/user/v1/companies',
    loyaltyEvents: '/v1/loyalty/events',
    //stripe
    paymentIntents: '/v1/stripe/payment_intents'
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    maxRedirects: 0,
})

instance.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response.status === 401) {
        history.replace('/logout')
    }

    return Promise.reject(error)
})

export default instance

export function setToken (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer token=${token}`
}

export function removeToken () {
    delete instance.defaults.headers.common['Authorization']
}
