import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

const StyledContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: ${props => props.stretched ? 'stretch' : 'center'};
  overflow-y: auto;
  width: auto;
  position: relative;

  ${props => !props.fluid && `
    padding-left: ${props.theme.containerPadding};
    padding-right: ${props.theme.containerPadding};`
  };
  
  justify-content: ${props => props.centered && 'center'};
`

const blinkCoral = keyframes`
  0% {
  }
  50% {
    background-color: #fff;
  }
  100% {
  }
`

const BlinkingContainer = styled(StyledContainer)`
  background-color: ${props => props.theme.blinkingBackground};
  animation: ${blinkCoral} 2s ease-in-out infinite;
`

const Container = (props) => {
    const {
        children,
        blinking,
        innerRef,
        ...rest
    } = props

    if (blinking) {
        return <BlinkingContainer {...rest}>
            {children}
        </BlinkingContainer>
    }

    return (
        <StyledContainer ref={innerRef} {...rest}>
            {children}
        </StyledContainer>
    )
}

Container.propTypes = {
    centered: PropTypes.bool,
    stretched: PropTypes.bool,
    fluid: PropTypes.bool,
    blinking: PropTypes.bool,
}

export default Container
