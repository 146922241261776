import styled, { css } from 'styled-components'
import React from 'react'

// used for all plasic elements
// these elements should be considered as clickable
// e.g buttons and cards
export const PlasticBox = css`
  background: white;
  border-radius: 4px;
  //box-shadow: 1px 1px 3px rgba(26,32,38,0.45);
  box-shadow: 0px 1px 3px 0px rgba(26,32,38,0.45);
  padding: 20px;
  margin: 10px;
  //border: 1px solid red; // enable this line to test all elements 
`

export const ProfileCTAButtonClass = css`
    padding: 5px 20px;
    width: 220px;
    margin: auto;
    //background: ${props => props.theme.profile.cashbackGradient};
    background: white;
    box-shadow: 0px 1px 1px 0px rgba(26,32,38,0.5);
    border: 2px solid black;
    border-color: ${props => props.color};
    border-radius: 10px;
    color:${props => props.color};
    vertical-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-size: 0.9em;
`

export const DetailHeadLine = styled.div`
  font-size: 1.8em;
  font-weight: 700;
`

export const LoyaltyHistoryWrapper = styled.div`
  ${PlasticBox};
`

export const LoyaltyHistoryTitle = styled.h3`
  border-bottom: 1px solid lightgrey;
  text-align: left;
  font-size: 0.95em;
  padding: 0px;
  margin: -15px 0px 0px;
`

export const LoyaltyHistoryUl = styled.ul`
  
  list-style: none;
  font-size: 0.9em;
  font-weight: 300;
  color: #4b4b4b;
  padding: 0px;
  
  //flex: 1 1 auto;
  //overflow-y: auto;
`

export const LoyaltyHistoryList = (props) => {
    const { title, bgIllustration, children } = props

    if (children.length == 0) {
        return <div style={{
            width: '75%', margin: 'auto',
        }}><img src={bgIllustration} style={{ maxHeight: '350px' }}/>
        </div>
    }
    return <LoyaltyHistoryWrapper>
        <LoyaltyHistoryTitle>
            {title}
        </LoyaltyHistoryTitle>
        <LoyaltyHistoryUl>
            {children}
        </LoyaltyHistoryUl>
    </LoyaltyHistoryWrapper>
}


export const DetailContainer = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    `

export const DisplayWrapper = styled.div`
    position: relative;
    `

export const DisplayContainer = styled.div`
    ${PlasticBox};
    display: flex;
    flex-flow: row;
    height: 130px;

    margin-bottom: 20px;
    flex: 0;
    `

//history list component
export const StyledLi = styled.li`
    display: flex;
    flex-flow: row;
    width: 100%;
    height: 50px;
    align-content: center;
    align-items: center;
    `

export const ExtraStatusMessage = styled.div`
    color: ${props => props.theme.profile.historyListExtraMessageColor};
    font-weight: 400;
    `

export const OperationColumn = styled.div`
    font-weight: 900;
    align-items: center;
    align-content: center;
    width: 20px;
    `

export const ValueColumn = styled.div`
    width: 60px;
    font-weight: 600;
    `

export const ValueInCentsColumn = styled.div`
    flex: 1 1 auto;
    min-width: 100px;
    font-weight: 600;
    text-align: right;
    padding: 0 10px;
    `

export const IconColumn = styled.div`
    text-align: left;
    min-width: 70px;
    padding-top: 5px;
    `

export const StyledImgIcon = styled.img`
    height: 20px;
    max-width: 70px;
    margin: 1px;
    `

export const HistoryDateColumn = styled.div`
    text-align: right;
    width: 100%;
    font-size: 0.85em;
    font-weight: 600;
    `

export const HistoryLiSeparator = styled.li`
    width: 95%;
    border-bottom: 1px solid grey;
    margin: auto;
    `

export const CardActionButton = css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    font-weight: 500;
    line-height: 1em;
    height: 100%;
    box-shadow: rgb(175 175 175 / 25%) 1px 2px 5px 0;
    padding: 0 50px;
    `
