import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { ReactComponent as ChevronDown } from '../../../images/profile/chevron-down.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { filterCompanies } from '../../../actions/profileActions'

const MenuEventCapturerDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;
  height:100%;
  width: 100%;
  z-index: 9999;
`

const StyledChevron = styled(ChevronDown)`
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
`

const StyledDropdown = styled.ul`
  position: absolute;
  padding-top: 0.5em;
  top: 0;
  right:0;
  list-style: none;
  z-index: 9999;

  li {
    border-top: 1px solid #999999;
    border-right: 1px solid #999999;
    border-left: 1px solid #999999;   
    background: white;
    padding: 0.25em 1.25em;
  &:hover {
    background: #b9b7b7;
  }  
  
  &:last-child {
     border-bottom: 1px solid #999999;
  }
}
`

export const FilterType = Object.freeze({
    favorite: 1,
    balance: 2,
    points: 3,
    relevance: 4,
})

const ListFilter = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [showFilter, setShowFilter] = useState(false)
    const theme = useTheme()

    const sortBy = (type) => {
        setShowFilter(false)
        switch (type) {
            case FilterType.relevance:
                dispatch(filterCompanies(FilterType.relevance))
                break
            case FilterType.points:
                dispatch(filterCompanies(FilterType.points))
                break
            case FilterType.balance:
                dispatch(filterCompanies(FilterType.balance))
                break
            case FilterType.favorite:
                dispatch(filterCompanies(FilterType.favorite))
                break
            default:
                console.error('unknown filter type')
                break
        }
    }

    const toggleFilters = () => {
        setShowFilter(!showFilter)
    }

    return (
        <>
            {
                showFilter && <MenuEventCapturerDiv onClick={(event) => {setShowFilter(!showFilter)}}/>
            }

            <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', alignItems: 'center', height: theme.profile.subheaderHeight }}>
                <span style={{ flexGrow: 1 }}> {t('profile.list.title')}</span>
                <span onClick={() => setShowFilter(!showFilter)}>{t('profile.list.sortBy')} <StyledChevron/></span>
                {
                    showFilter && <StyledDropdown>
                        <li onClick={() => sortBy(FilterType.favorite)}>{t('profile.list.sort.favorites')}</li>
                        <li onClick={() => sortBy(FilterType.balance)}>{t('profile.list.sort.balance')}</li>
                        <li onClick={() => sortBy(FilterType.points)}>{t('profile.list.sort.points')}</li>
                        <li onClick={() => sortBy(FilterType.relevance)}>{t('profile.list.sort.relevance')}</li>
                    </StyledDropdown>
                }
            </div>
        </>
    )
}

export default ListFilter

