import { ApplePay, GooglePay, Sofort, VisaMastercardAmex } from './images/payment'
import GiftCardIconSimplified from './images/voucher/gift_card_icon_simplified.svg'

export const paymentMethodStripeConfig = {
    voucher: {
        icon: GiftCardIconSimplified,
    },
    stripe_credit_card: {
        icon: VisaMastercardAmex,
        iconStyle: { height: '40px', maxWidth: '60%' },
        label: 'Visa/Mastercard',
        prePaymentLink: 'step-stripe-credentials',
        link: 'step-stripe-credentials',
        paymentAction: 'payWithCard',
        discarded: false,
        name: 'stripe_credit_card',
    },
    stripe_google_pay: {
        icon: GooglePay,
        iconStyle: { height: '50px', maxWidth: '100%' },
        label: 'Google Pay',
        prePaymentLink: 'step-6',
        link: 'step-6',
        paymentAction: 'payWithGooglePay',//TODO remove
        discarded: false,
        name: 'stripe_google_pay',
    },
    stripe_apple_pay: {
        icon: ApplePay,
        iconStyle: { height: '50px', maxWidth: '100%' },
        label: 'Apple Pay',
        prePaymentLink: 'step-6',
        link: 'step-6',
        paymentAction: 'payWithGooglePay',//TODO remove
        discarded: false,
        name: 'stripe_apple_pay',
    },
    stripe_sofort: {
        icon: Sofort,
        iconStyle: { height: '50px', maxWidth: '100%' },
        label: 'Klarna/Sofortüberweisung',
        prePaymentLink: 'step-start-payment',
        link: 'step-6',
        paymentAction: 'payWithSofort',
        discarded: false,
        name: 'stripe_sofort',
    },
}
