import React, { useEffect } from 'react'
import Processing from './Processing'
import { useDispatch, useSelector } from 'react-redux'
import { useTracker } from '../hooks'
import { useAuth0 } from '@auth0/auth0-react'
import { clearAuthToken } from '../actions/authActions'

const Logout = () => {
    const dispatch = useDispatch()
    useTracker(null)
    const restaurant = useSelector(state => state.restaurant)
    const { logout } = useAuth0()

    useEffect(() => {
        dispatch(clearAuthToken())
        // const redirectUrl = restaurant.id ? `/restaurants/${restaurant.id}` : '/list' // TODO
        const redirectUrl = window.location.origin // TODO
        logout({ returnTo: redirectUrl })
    }, [])

    return (
        <Processing/>
    )
}

export default Logout
