import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getValueCentsInCurrencyFormat } from '../../utils'
import BaseVoucherBuyButton from './BaseVoucherBuyButton'
import { useSelector } from 'react-redux'
import { DetailHeadLine, DisplayWrapper } from './_baseComponents'
import LoyaltyDisplay from './LoyaltyDisplay'
import FeatureTitle from './FeatureTitle'
import DealBadge from './company-list/DealBadge'
import { Column } from '../Columns'
import CountUp from 'react-countup'

const StyledColumn = styled(Column)`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
 
`

const BuyVoucherPrimaryButton = styled(BaseVoucherBuyButton)`
    background-color: ${props => props.theme.profileActionColor};
    color: white;
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 14px;
    width: 150px;
    font-size: 0.8em;
    position: absolute;
    bottom: 48px;
    right: 20px;
`

const VoucherDisplay = (props) => {
    const { t } = useTranslation()
    const { voucherBalanceAmountCents } = props
    const theme = useTheme()
    const company = useSelector(state => state.company)

    const generateSpecialNotification = () => {
        if (voucherBalanceAmountCents > 0) {
            return 'Du kannst mit deinem Guthaben bezahlen'
        }

        return 'Du hast hier noch kein Guthaben'
    }
    const specialNotification = generateSpecialNotification()
    const voucherBalanceCurrency = getValueCentsInCurrencyFormat(voucherBalanceAmountCents, false)
    const voucherBalanceNumber = (typeof voucherBalanceCurrency === 'number') ? voucherBalanceCurrency : parseFloat(voucherBalanceCurrency)
    return (
        <DisplayWrapper>
            {company.deals?.[0]?.voucher && <DealBadge primaryDeal={true} company={company}/>}
            <LoyaltyDisplay specialNotification={specialNotification}>
                <StyledColumn>
                    <FeatureTitle color={theme.profile.voucherPrimaryColor}
                                  infoTitle={t('profile.voucher.info.title')}
                                  infoDescription={t('profile.voucher.info.description')}>
                        {t('profile.voucher.title')}
                    </FeatureTitle>

                    <DetailHeadLine style={{ textAlign: 'left' }}>
                        <CountUp
                            start={0.00}
                            end={voucherBalanceNumber}
                            duration={2}
                            separator=" "
                            useEasing={true}
                            decimals={2}
                            decimal="."
                            suffix=" €"
                        /></DetailHeadLine>

                </StyledColumn>
                <StyledColumn style={{ alignItems: 'center' }}>
                    <BuyVoucherPrimaryButton company={company}>{t('profile.actions.voucher.buy')}</BuyVoucherPrimaryButton>
                </StyledColumn>

            </LoyaltyDisplay>
        </DisplayWrapper>
    )
}

export default VoucherDisplay
