import React from 'react'

import styled from 'styled-components'

const StyledCompanyImage = styled.img`
    width: 100%;
    height: 100%;
    min-width: 100px;
    min-height: 100px;
    max-height: 100px;
    max-width: 100px;
    object-fit: cover;
`

const CompanyImage = (props) => {
    const { company } = props
    return <StyledCompanyImage
        src={company.brand}
        alt={company.name}
    />
}

export default CompanyImage
