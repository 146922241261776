import React from 'react'
import styled from 'styled-components'
import FeatureInfo from './FeatureInfo'

const TitleContainer = styled.div`
   position: relative;
   font-weight: 500;
   height: 24px;
   width: max-content;

`
const TitleEffect = styled.div`
  width: 100%;
  height: 8px;
  margin-top: -10px;
  background-color: ${props => props.color};
`

const TitleBody = styled.div`
  width: max-content;
  height: 100%;
  background-color: ${props => props.color};
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  font-weight: 600;
  color: ${props => props.theme.profile.HeaderColor};
  
`


const FeatureTitle = (props) => {
    const { children, color, customInfo, infoTitle, infoDescription, ...rest } = props
    const usesFeatureInfo = (customInfo || (infoTitle && infoDescription))
    return (
        <>
            <TitleContainer {...rest}>
                <TitleBody>{children}</TitleBody>
                {(usesFeatureInfo) && <FeatureInfo customInfo={customInfo} title={infoTitle} description={infoDescription} titleColor={color}/>}
            </TitleContainer>
        </>
    )
}

export default FeatureTitle
