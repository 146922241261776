import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from '../images/logo-without-claim-black.svg'
import Figure from '../components/Figure'
import IconLabel from './IconLabel'
import locationIcon from '../images/voucher/pin_icon_black.svg'
import { Column } from './Columns'
import PropTypes from 'prop-types'
import ProfileButton from './ProfileButton'

const StyledHeader = styled.header`
  align-items: normal;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.schemeMain};
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.schemeMain};
`

const TopHeader = styled.div`
  display: flex;
  padding: 0px 22px;
`


const SubHeader = styled.div`
  color: black;
  font-weight: 500;
  background-color: ${props => props.theme.headerBottomColor};
  width: 100%;
  padding: 0 22px;
  display: ${props => props.visible ? 'block' : 'none'};
`

const SubHeaderSuccess = styled(SubHeader)`
  background-color: ${props => props.theme.actionColor};
  text-align: center;
  display: ${props => props.visible ? 'block' : 'none'};
`


const HeaderToGo = (props) => {
    const { t } = useTranslation()
    const { customSubHeader } = props
    const restaurant = useSelector(state => state.restaurant)
    const transaction = useSelector(state => state.transaction)
    const isAmountEditable = useSelector(state => state.appState.amountEditable)
    const isRestaurantHiddenInHeader = useSelector(state => state.appState.hideRestaurantInHeader)

    return (
        <>
            <StyledHeader border fullWidth>
                <TopHeader>
                    <Column>
                        <div style={{ marginTop: '10px' }}>
                            <Figure maxWidth="5em" maxWidthDesktop="5em"><Logo/></Figure>
                        </div>
                    </Column>
                    <Column>
                        <ProfileButton/>
                    </Column>
                </TopHeader>
                {
                    (customSubHeader) ?
                        <SubHeader visible={true}>
                            {customSubHeader}
                        </SubHeader>
                        :
                        <>
                            <SubHeader
                                visible={(!isRestaurantHiddenInHeader || (!isAmountEditable && transaction.status !== 'success'))}>
                                <IconLabel icon={locationIcon} align='left' width="50%"
                                           weight='500'>{restaurant.name}</IconLabel>
                            </SubHeader>
                            <SubHeaderSuccess visible={(transaction.status === 'success')}>
                                {t('finished.title2')}
                            </SubHeaderSuccess>
                        </>
                }
            </StyledHeader>
        </>
    )
}


HeaderToGo.propTypes = {
    customSubHeader: PropTypes.object,
}


export default HeaderToGo
