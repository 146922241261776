import API, { URLs } from '../api'

export const postVoucherRequest = (payload) => {
    return API.post(`${URLs.voucherRequest}`, { request: { ...payload } }, { headers: { 'Key-Inflection': 'camel' } })
}

export const patchVoucherRequest = (voucherRequestId, payload) => {
    return API.patch(`${URLs.voucherRequest}/${voucherRequestId}`, { request: { ...payload } }, { headers: { 'Key-Inflection': 'camel' } })
}

export const getVoucherRequest = (voucherRequestId) => {
    return API.get(`${URLs.voucherRequest}/${voucherRequestId}`, { headers: { 'Key-Inflection': 'camel' } })
}

export const deleteVoucherRequest = (voucherRequestId) => {
    return API.delete(`${URLs.voucherRequest}/${voucherRequestId}`)
}
