import { useEffect } from 'react'
import * as ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { isToGoSelector } from '../reducers/restaurant'

export default function (title) {
    const isToGo = useSelector(state => isToGoSelector(state))

    useEffect(() => {
        ReactGA.ga('set', 'contentGroup1', isToGo ? 'togo' : 'restaurant')
        ReactGA.pageview(window.location.pathname, title)
    }, [isToGo, title])
}
