import API, { URLs } from '../api'

export const getVoucherCard = (companyId, qrCode = null, numberCode = null) => {
    let params = ''
    if (qrCode) {
        params = `qr_code=${qrCode}`
    } else if (numberCode) {
        params = `number_code=${numberCode}`
    }

    return API.get(`${URLs.companies}/${companyId}/voucher/cards?${params}`,
        { headers: { 'Key-Inflection': 'camel' } })
}

export const getVoucherCardById = (companyId, cardId) => {
    return API.get(`${URLs.companies}/${companyId}/voucher/cards/${cardId}`,
        { headers: { 'Key-Inflection': 'camel' } })
}

export const validateVoucherCardPin = (voucherCardId, enteredPin) => {
    return API.get(`${URLs.voucherCards}/${voucherCardId}/validate?pin=${enteredPin}`)
}
