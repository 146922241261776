import React from 'react'
import styled from 'styled-components'
import { DisplayContainer } from './_baseComponents'

const SpecialNotification = styled.div`
  font-size: 0.8em;
  color: darkgrey;
  position: absolute;
  top: 20px;
  left: 30px;
  text-align: left;
  width: 70%;
`

const LoyaltyDisplay = (props) => {

    const { specialNotification, children, ...rest } = props
    return <>
        <DisplayContainer {...rest}>
            {specialNotification && <SpecialNotification> {specialNotification} </SpecialNotification>}
            {children}
        </DisplayContainer>

    </>
}

export default LoyaltyDisplay
