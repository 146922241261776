import { CLEAR_COMPANY, CLEAR_COMPANY_FAVORITE, SET_COMPANY, SET_COMPANY_FAVORITE } from '../actions/types'

const initState = {
    id: null,
    name: null,
    street: null,
    city: null,
    zipCode: null,
    country: null,
    brand: null,
    openTil: null,
    cashbackThreshold: null,
    places: [],
    favorite: false,
    transactions: [],
    voucher: {
        card: null,
        balance: null,
        requests: [],
    },
    loyalty: {
        configuration: null,
        account: null,
        events: [],
    },
}

export function company (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_COMPANY: {
            return { ...state, ...action.payload }
        }
        case SET_COMPANY_FAVORITE: {
            return { ...state, favorite: { id: action.payload.favoriteID } }
        }
        case CLEAR_COMPANY_FAVORITE: {
            return { ...state, favorite: null }
        }
        case CLEAR_COMPANY:
            return { ...initState }
        default:
            return state
    }
}

export function isFavoriteSelector (state) {
    return state.company.favorite
}

export function firstPlaceOfCompanySelector (state) {
    return state.company.places[0]
}
