import React, { useEffect } from 'react'
import Processing from './Processing'
import { useDispatch, useSelector } from 'react-redux'
import { useTracker } from '../hooks'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { setAuthToken } from '../actions/authActions'
import { connectCashback } from '../actions/companyActions'

const AuthCallback = () => {
    const dispatch = useDispatch()
    useTracker(null)
    const history = useHistory()
    const restaurant = useSelector(state => state.restaurant)
    const redirectUrl = useSelector(state => state.appState.redirectAfterLogin)
    const cashbackPot = useSelector(state => state.appState.cashbackPot)

    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const token = await getAccessTokenSilently()
                dispatch(setAuthToken(token))

                if (redirectUrl) {
                    if (cashbackPot) {
                        dispatch(connectCashback())
                    }
                    history.push(redirectUrl)
                } else {
                    history.push('/list')
                }
            } catch (e) {
                console.log(e) //TODO how to show this error?
                history.push('/list')
            }

        }

        fetchToken()
    }, [])

    return (
        <Processing/>
    )
}

export default AuthCallback
