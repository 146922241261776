import React from 'react'
import HeaderToGo from '../HeaderToGo'
import styled from 'styled-components'
import CompanyCustomHeaderTagPlugin from './CompanyCustomHeaderTagPlugin'
import { getValueCentsInCurrencyFormat } from '../../utils'
import { useTranslation } from 'react-i18next'
import { ReactComponent as cashbackIcon } from '../../images/profile/action_flame.svg'

const DealIcon = styled(cashbackIcon)`
  fill: ${props => props.theme.profile.dealHighlightBackground};
  width: 50px;
  margin-bottom: 5px;
`

const Logo = styled.img`
  height: 80px;
`

const CompanyArea = styled.div`
  text-align: center;
  align-items: center;
  height: 100px;
  width:100%;
  padding:10px;
  border-bottom: 1px solid lightgray;
`

const DealDisplayWrapper = styled.div`
  background-color: #A9F4CD; //color proposal ${props => props.theme.profile.dealHighlightBackground};
  & h3 {
    font-weight: 600;
    text-align: center;
    margin: 5px;
    font-size: 0.9em;
  }
`

const DealDisplayDescription = styled.div`
  display: flex;
  font-size: 0.65em;
  align-items: center;
  text-align: center;
  line-height: 1.8em;
  margin-bottom: 5px;
  padding: 0px 10px;
`

const DealDisplay = (props) => {
    const { deal } = props
    const { t } = useTranslation()
    const bonusInCents = getValueCentsInCurrencyFormat(deal.voucher * 100)
    const thresholdInCents = getValueCentsInCurrencyFormat(deal.threshold * 100)
    return (
        <DealDisplayWrapper>
            <h3>{t('profile.voucher.deal.defaultTitle', { bonus: deal.voucher })}</h3>
            <DealDisplayDescription>
                <DealIcon/>
                <div>{t('profile.voucher.deal.defaultDescriptionWithThreshold', { bonus: bonusInCents, threshold: thresholdInCents })}</div>
                <DealIcon/>
            </DealDisplayDescription>
        </DealDisplayWrapper>
    )
}


const CompanyHeader = (props) => {
    const { company, displayCompanyArea, deal, children, ...rest } = props
    const customSubheader = <CompanyCustomHeaderTagPlugin/>

    return (
        <>
            <HeaderToGo customSubHeader={customSubheader}/>
            {deal && <DealDisplay deal={deal}/>}
            {displayCompanyArea && <CompanyArea>
                <div><Logo src={company.brand}/></div>
            </CompanyArea>}
        </>
    )
}

export default CompanyHeader
