export const createApplePaySession = () => {
    const requestData = {
        countryCode: 'DE',
        currencyCode: 'EUR',
        supportedNetworks: ['visa', 'masterCard'],
        merchantCapabilities: ['supports3DS'],
        total: { label: 'stampayGO', amount: '0.00', type: 'final' },
    }

    window.applePaySession = new window.ApplePaySession(4, requestData)
}
