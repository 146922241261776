import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate, getValueCentsInCurrencyFormat } from '../../utils'
import { ExtraStatusMessage, HistoryDateColumn, OperationColumn, StyledLi, ValueInCentsColumn } from './_baseComponents'

const RT_CHARGE = 'charge'
const RT_TOPUP = 'topUp'
const RT_REDEEMED = 'redeem'

const ListItemVoucherRequest = (props) => {
    const { t } = useTranslation()
    const { historyEvent, lastItem } = props
    const isCharge = (historyEvent.requestType === RT_CHARGE)

    const extraStatusMessageText = (requestType) => {
        switch (requestType) {
            case RT_CHARGE:
                return t('profile.voucher.extraStatus.charge')
            case RT_TOPUP:
                return t('profile.voucher.extraStatus.topUp')
            case RT_REDEEMED:
                return t('profile.voucher.extraStatus.redeem')
        }
    }
    return <>
        <StyledLi>
            <OperationColumn> {isCharge ? '-' : '+'} </OperationColumn>
            <ValueInCentsColumn> {getValueCentsInCurrencyFormat(historyEvent.amountCents)} </ValueInCentsColumn>
            <HistoryDateColumn>
                {`${formatDate(new Date(historyEvent.updatedAt), t('profile.historyDateFormat'))}`} {t('profile.historyDateFormatClock')}
                <ExtraStatusMessage>
                    {extraStatusMessageText(historyEvent.requestType)}
                </ExtraStatusMessage>
            </HistoryDateColumn>
        </StyledLi>
    </>
}

export default ListItemVoucherRequest
