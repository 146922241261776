import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button'

const RemoteSubmitButton = (props) => {
    const { formName, children, ...rest } = props

    return (
        <Button
            onClick={() => document.getElementById(formName).dispatchEvent(new Event('submit', { cancelable: true }))}
            {...rest}>
            {children}
        </Button>
    )
}

RemoteSubmitButton.propTypes = {
    disabled: PropTypes.bool,
    formName: PropTypes.string.isRequired,
}

export default RemoteSubmitButton
