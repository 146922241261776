import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { DetailHeadLine, DisplayWrapper } from './_baseComponents'
import FeatureTitle from './FeatureTitle'
import { useTranslation } from 'react-i18next'
import { getValueCentsInCurrencyFormat } from '../../utils'
import CashbackProgressBar from './CashbackProgressBar'
import { Column } from '../Columns'
import FeatureInfo from './FeatureInfo'
import DealBadge from './company-list/DealBadge'
import { useDispatch, useSelector } from 'react-redux'
import LoyaltyDisplay from './LoyaltyDisplay'
import { redeemCashback } from '../../actions/companyActions'
import CountUp from 'react-countup'

const RedeemBaseButton = styled.div`
    cursor: pointer;
    position: relative;
    margin: 0 auto;
    padding: 40px 0px;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 0.9em;
    background-color: #f1f1f1;
    color: white;
    font-weight: 600;
    padding: 8px 10px;
    border-radius: 14px;
    width: 150px;
    font-size: 0.8em;
    bottom: 48px;
    right: 20px;
`

const RedeemButton = styled(RedeemBaseButton)`
background-color: #52919d;

`

const DonateButton = styled(RedeemBaseButton)`
margin-top: 4px;
color: black;
background-color: #2DD07A;

`

const StyledSpan = styled.span`
  font-weight: 600;
  font-size: 1.05em;
`

const StyledHeadline = styled(DetailHeadLine)`
  position: relative;
  width: max-content;
`

const FocusSpan = styled.span`
  color: ${props => props.theme.mainColor};
`

const LightSpan = styled.span`
  color: ${props => props.theme.profile.HeaderSecondaryColor}
`
const StyledColumn = styled(Column)`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
`


const CashbackDisplay = (props) => {
    const { t } = useTranslation()
    const company = useSelector(state => state.company)
    const { cashbackPoints, cashbackThreshold, cashbackPointValue } = props
    const cashBackRatio = (cashbackThreshold > 0) ? cashbackPoints / cashbackThreshold : 0
    const progressRatio = Math.min(100, cashBackRatio * 100)
    const progressValue = getValueCentsInCurrencyFormat(cashbackPoints * cashbackPointValue, false)
    const dispatch = useDispatch()

    const theme = useTheme()
    const deal = (company.deals?.[0]?.cashback) ? company.deals[0] : undefined

    const handleOnClick = () => {
        dispatch(redeemCashback())
    }

    const handleDonateClick = () => {
        console.log("reddeeming");
        dispatch(redeemCashback())

        setTimeout(() => {
            console.log("redirecting");
            window.location.href = 'https://topup.stampay.com/greendonate?amount=' + (cashbackPoints * cashbackPointValue)
        }, 750)

    }

    const [cashbackCounter, setCashbackCounter] = useState(0)

    const countUpDuration = 2 //seconds

    useEffect(() => {
        setCashbackCounter(cashbackPoints)
    },
        [cashbackPoints],
    )

    const infoProgressBar = <>
        <CashbackProgressBar progressRatio={25} progressValue={getValueCentsInCurrencyFormat(25 * cashbackPointValue)} />
    </>
    const generateSpecialNotification = () => {
        if (progressRatio >= 100) {
            return 'Cashback bereit zum einlösen' // TODO use real translation once we got the final texts

        }
        if (progressRatio > 75) {
            const goal = cashbackThreshold - cashbackPoints
            return 'Nur noch ' + goal + ' Cashback Punkte!' // TODO use real translation once we got the final texts
        }

        if (progressRatio > 0) {
            return 'Cashback Punkte sind Geld!' // TODO use real translation once we got the final texts
        }

        return 'Du hast hier noch kein Cashback Punkte' // TODO use real translation once we got the final texts
    }
    const specialNotification = generateSpecialNotification()
    return (
        <DisplayWrapper>
            {deal && <DealBadge primaryDeal={true} company={company} />}
            <LoyaltyDisplay specialNotification={specialNotification}>
                <StyledColumn>
                    <FeatureTitle color={theme.profile.cashbackPrimaryColor}>
                        {t('profile.cashback.title')}
                    </FeatureTitle>
                    <StyledHeadline>
                        <FocusSpan><CountUp end={cashbackCounter} duration={countUpDuration} /></FocusSpan>
                        <LightSpan> | {cashbackThreshold}</LightSpan>
                        <FeatureInfo infoTitle={t('profile.cashback.info.title')}
                            customInfo={infoProgressBar}
                            infoDescription={t('profile.cashback.info.description')} />
                    </StyledHeadline>
                </StyledColumn>
                <StyledColumn style={{ alignItems: 'flex-end' }}>
                    {(progressRatio >= 100) ? (

                        <div style={{
                            display: 'flex', 'flex-direction': 'column',
                            'justify-content': 'center',
                            'margin-bottom': '-59px',
                            'padding-left': '31px'
                        }}>

                            <RedeemButton onClick={handleOnClick}>
                                {getValueCentsInCurrencyFormat(cashbackPoints * cashbackPointValue)} Cashback
                            </RedeemButton>

                            <DonateButton onClick={handleDonateClick}>
                                {getValueCentsInCurrencyFormat(cashbackPoints * cashbackPointValue)} Spenden
                            </DonateButton>

                        </div>)
                        :
                        <CashbackProgressBar progressRatio={progressRatio} progressValue={progressValue} />
                    }
                </StyledColumn>
            </LoyaltyDisplay>
        </DisplayWrapper>
    )
}

export default CashbackDisplay
