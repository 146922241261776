import API, { URLs } from '../api'

export const deleteCompanyFavorite = (id) => {
    return API.delete(`${URLs.companyFavorite}/${id}`, { headers: { 'Key-Inflection': 'camel' } })
}

export const createCompanyFavorite = (companyID) => {
    return API.post(`${URLs.companyFavorite}`, {
        'favorite_company': {
            'company_id': companyID,
        },
    }, { headers: { 'Key-Inflection': 'camel' } })
}

export const getCompanies = () => {
    return API.get(`${URLs.profileData}`, { headers: { 'Key-Inflection': 'camel' } })
}

export const createLoyaltyEvent = (companyId) => {
    return API.post(`${URLs.userCompanies}/${companyId}/loyalty/events`, { headers: { 'Key-Inflection': 'camel' } })
}

export const PostLoyaltyEvent = (companyId, loyaltyEvent) => {
    return API.post(`${URLs.loyaltyEvents}`, { event: loyaltyEvent }, { headers: { 'Key-Inflection': 'camel' } })
}
