import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledText = styled.p`
  margin: ${props => props.margin};
  text-align: ${props => props.align};
  
  ${props => props.variant === 'size-1' && 'font-size: 3rem;'};
  ${props => props.variant === 'size-2' && 'font-size: 2.5rem;'};
  ${props => props.variant === 'size-3' && 'font-size: 2rem;'};
  ${props => props.variant === 'size-4' && 'font-size: 1.5rem;'};
  ${props => props.variant === 'size-5' && 'font-size: 1.25rem;'};
  ${props => props.variant === 'size-6' && 'font-size: 1rem;'};
  ${props => props.variant === 'size-7' && 'font-size: 0.75rem;'};
  
  ${props => props.fontSize && `font-size: ${props.fontSize};`}; 
`

const Text = ({ children, ...rest }) => {
    return <StyledText {...rest}>{children}</StyledText>
}

Text.propTypes = {
    bigger: PropTypes.bool,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    smaller: PropTypes.bool,
    fontSize: PropTypes.string,
    margin: PropTypes.string,
    variant: PropTypes.oneOf(['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7'])
}

Text.defaultProps = {
    align: 'center',
    margin: '0',
}

export default Text
