import { CLEAR_COMPANY_FAVORITE, FILTER_COMPANY_LIST, SET_COMPANIES_DATA, SET_COMPANY_FAVORITE } from './types'
import { createCompanyFavorite, deleteCompanyFavorite, getCompanies } from '../services/profile'

export const filterCompanies = (filterType) => {
    return {
        type: FILTER_COMPANY_LIST,
        payload: filterType,
    }
}

export const setCompaniesData = (companies) => {
    return {
        type: SET_COMPANIES_DATA,
        payload: companies,
    }
}

export const storeCompanyFavorite = (companyID, favoriteID) => {
    return {
        type: SET_COMPANY_FAVORITE,
        payload: { company: companyID, favoriteID: favoriteID },
    }
}

export const clearCompanyFavorite = (companyID) => {
    return {
        type: CLEAR_COMPANY_FAVORITE,
        payload: companyID,
    }
}

export const loadCompaniesData = () => {
    return async (dispatch, getState) => {
        try {
            const result = await getCompanies()
            dispatch(setCompaniesData(result.data))
            return Promise.resolve(result.data)
        } catch (e) {
            console.error('could not load company data: ', e)
            return Promise.reject(e)
        }
    }
}

export const createOrDeleteCompanyFavorite = (company) => {
    return async (dispatch, getState) => {
        try {
            if (company.favorite?.id) {
                await deleteCompanyFavorite(company.favorite.id)
                dispatch(clearCompanyFavorite(company.id))
                return Promise.resolve()
            } else {
                const result = await createCompanyFavorite(company.id)
                dispatch(storeCompanyFavorite(company.id, result.data.id))
                return Promise.resolve(result.data)
            }
        } catch (e) {
            console.error('could set company favorite: ', e)
            return Promise.reject(e)
        }
    }
}
