import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled.button`
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  font-weight: 500;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.inverted ? props.theme.buttonColorInverted : props.theme.buttonColor};
  cursor: pointer;
  outline: none;
  color: ${props => props.theme.mainButtonColor};
  ${props => props.variant === 'small' && 'font-size: .75rem;'};
  ${props => props.variant === 'medium' && 'font-size: 1.25rem;'};
  ${props => props.variant === 'large' && 'font-size: 1.5rem;'};
  ${props => props.variant === 'xl' && 'font-size: 2rem;'};
  
  &:focus {
    outline: none;
  }
  
  &[disabled] {
    cursor: not-allowed;
  }
  
  &:disabled {
    cursor: default;
    background-color: ${props => props.theme.disabledButtonBackgroundColor};
    color: ${props => props.theme.disabledButtonColor};
  }

  @media (min-width: ${props => props.theme.desktop}) {
    background-color: ${props => props.inverted ? props.theme.buttonColorInvertedDesktop : props.theme.buttonColorDesktop};
  }
`

const Button = (props) => {
    const { children, ...rest } = props

    return (
        <StyledButton {...rest}>
            {children}
        </StyledButton>
    )
}

Button.propTypes = {
    disabled: PropTypes.bool,
    inverted: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    variant: PropTypes.oneOf(['small', 'normal', 'medium', 'large', 'xl']),
}

Button.defaultProps = {
    type: 'submit',
}

export default Button
