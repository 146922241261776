export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const PASS_STEP = 'PASS_STEP'
export const CLEAR_STEPS_HISTORY = 'CLEAR_STEPS_HISTORY'
export const SET_PASSED_STEPS = 'SET_PASSED_STEPS'
export const SET_AMOUNT = 'SET_AMOUNT'
export const SET_TIPS = 'SET_TIPS'
export const SET_TIPS_AMOUNT = 'SET_TIPS_AMOUNT'
export const SET_TIPS_PERCENTAGE = 'SET_TIPS_PERCENTAGE'
export const SET_CLIENT_EMAIL = 'SET_CLIENT_EMAIL'
export const SET_CLIENT_LOCALE = 'SET_CLIENT_LOCALE'
export const SET_CLIENT = 'SET_CLIENT'
export const INIT_IS_BILL_SCANNED = 'INIT_IS_BILL_SCANNED'
export const ALLOW_PAYMENT_METHOD = 'ALLOW_PAYMENT_METHOD'
export const DISALLOW_PAYMENT_METHOD = 'DISALLOW_PAYMENT_METHOD'
export const CLEAR_STATE = 'CLEAR_STATE'
export const SET_TRANSACTION = 'SET_TRANSACTION'
export const SET_WIRECARD_REQUEST = 'SET_WIRECARD_REQUEST'
export const SET_WAITER = 'SET_WAITER'
export const SET_EXTERNAL_TRX_ID = 'SET_EXTERNAL_TRX_ID'
export const SET_PAYMENT_GATEWAY_DATA = 'SET_PAYMENT_GATEWAY_DATA'
export const SET_LINE_ITEMS = 'SET_LINE_ITEMS'
export const SET_AMOUNT_EDITABLE = 'SET_AMOUNT_EDITABLE'
export const SET_IS_DONATION = 'SET_IS_DONATION'
export const SET_RESTAURANT = 'SET_RESTAURANT'
export const RECEIPT_SCANNED = 'RECEIPT_SCANNED'
export const SET_TRANSACTION_FINISHED = 'SET_TRANSACTION_FINISHED'
export const SET_XHR_PROGRESS = 'SET_XHR_PROGRESS'
export const SET_HIDE_RESTAURANT_IN_HEADER = 'SET_HIDE_RESTAURANT_IN_HEADER'
export const CANCEL_TRANSACTION = 'CANCEL_TRANSACTION'
export const REMOVE_WIRECARD_REQUEST = 'REMOVE_WIRECARD_REQUEST'
export const CLEAR_WIRECARD_REQUEST_STATE = 'CLEAR_WIRECARD_REQUEST_STATE'
export const SET_APPLE_PAY_TOKEN = 'SET_APPLE_PAY_TOKEN'
export const SET_GOOGLE_PAYMENT_DATA = 'SET_GOOGLE_PAYMENT_DATA'
export const SET_REDIRECT_AFTER_LOGIN = 'SET_REDIRECT_AFTER_LOGIN'
export const SET_USER_MENU_OPEN = 'SET_USER_MENU_OPEN'
export const SET_CASHBACK_POT = 'SET_CASHBACK_POT'

//ProfileMain page action types
export const SET_COMPANY = 'SET_COMPANY'
export const CLEAR_COMPANY = 'CLEAR_COMPANY'
export const LOAD_COMPANIES_DATA = 'LOAD_COMPANY_DATA'
export const SET_COMPANIES_DATA = 'SET_COMPANIES_DATA'
export const SET_COMPANY_FAVORITE = 'SET_COMPANY_FAVORITE'
export const CLEAR_COMPANY_FAVORITE = 'CLEAR_COMPANY_FAVORITE'
export const FILTER_COMPANY_LIST = 'FILTER_COMPANY_LIST'

//VOUCHER action types
export const SET_VOUCHER_REQUEST = 'SET_VOUCHER_REQUEST'
export const REMOVE_VOUCHER_REQUEST = 'REMOVE_VOUCHER_REQUEST'
export const SET_VOUCHER_CARD = 'SET_VOUCHER_CARD'
export const SET_VOUCHER_QR_CODE = 'SET_VOUCHER_QR_CODE'
export const SET_VOUCHER_NUMBER_CODE = 'SET_VOUCHER_NUMBER_CODE'
export const SET_PIN = 'SET_PIN'
export const SET_REQUEST_TYPE = 'SET_REQUEST_TYPE'
export const SET_VOUCHER_BALANCE = 'SET_VOUCHER_BALANCE'

//AUTH action types
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const CLEAR_AUTH_TOKEN = 'CLEAR_AUTH_TOKEN'

//STRIPE
export const SET_STRIPE_PAYMENT_INTENT = 'SET_STRIPE_PAYMENT_INTENT'
export const REMOVE_STRIPE_PAYMENT_INTENT = 'REMOVE_STRIPE_PAYMENT_INTENT'
export const CLEAR_STRIPE_PAYMENT_INTENT_STATE = 'CLEAR_STRIPE_PAYMENT_INTENT_STATE'
