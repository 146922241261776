import { CLEAR_STATE, CLEAR_STEPS_HISTORY, PASS_STEP, SET_PASSED_STEPS } from '../actions/types'

const initState = {
    passedSteps: [],
}

export function wizardState (
    state = initState,
    action,
) {
    switch (action.type) {
        case PASS_STEP: {
            const { passedSteps } = state
            if (!passedSteps.includes(action.payload)) {
                passedSteps.push(action.payload)
            }
            return { ...state, passedSteps }
        }
        case SET_PASSED_STEPS:
            return { ...state, passedSteps: action.payload }
        case CLEAR_STEPS_HISTORY: {
            return { ...state, passedSteps: [] }
        }
        case CLEAR_STATE:
            return { ...initState }
        default:
            return state
    }
}
