import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import ListFilter from '../../components/profileadmin/company-list/ListFilter'
import HeaderToGo from '../../components/HeaderToGo'
import { useHistory } from 'react-router-dom'
import CompanyList from '../../components/profileadmin/CompanyList'
import { loadCompaniesData } from '../../actions/profileActions'
import Processing from '../Processing'
import { useAuth0 } from '@auth0/auth0-react'

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  overflow-y: auto;
  width: auto;
  position: relative;
  // background: ${props => props.theme.profile.background};
  background: #fff;
  //padding-left: 5px; //full card proposal
  //padding-right: 5px;  
`

const EntryList = (props) => {
    const dispatch = useDispatch()
    const companies = useSelector(state => state.companies)
    const authState = useSelector(state => state.authState)
    const { loginWithRedirect } = useAuth0()

    const
        history = useHistory()

    const onSelectCompanyLoyalty = (selectedCompany) => {
        if (authState.authToken) {
            history.push(`/profile/companies/${selectedCompany.id}`)
        } else {
            loginWithRedirect().then(() => console.log('redirect succeeded')).catch((err) => console.error(err))
        }
    }

    useEffect(() => {
        const loadCompanyData = async () => {
            dispatch(loadCompaniesData())
        }
        loadCompanyData()
    }, [authState.authToken])

    return (
        <>
            <HeaderToGo customSubHeader={<ListFilter/>}/>
            <StyledListContainer>
                {
                    companies ?
                        <CompanyList onSelectCompanyLoyalty={onSelectCompanyLoyalty}/> :
                        <Processing/>
                }
            </StyledListContainer>
        </>
    )
}

export default EntryList
