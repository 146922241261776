import { CLEAR_STATE, SET_CLIENT, SET_CLIENT_EMAIL, SET_CLIENT_LOCALE } from '../actions/types'

const initState = {
    id: null,
    email: null,
    locale: 'de',
}

export function client (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_CLIENT:
            return { ...state, ...action.payload }
        case SET_CLIENT_EMAIL:
            return { ...state, email: action.payload }
        case SET_CLIENT_LOCALE:
            return { ...state, locale: action.payload }
        case CLEAR_STATE:
            return { ...initState }
        default:
            return state
    }
}
