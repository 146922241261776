import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import de from './i18n/de'
import en from './i18n/en'
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: {
            caches: [],
        },

        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',
        load: 'languageOnly',
        resources: {
            de: {
                translation: de,
            },
            en: {
                translation: en,
            },
        },

        saveMissing: false, // send not translated keys to endpoint

        returnObjects: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

export default i18n
