import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FavoriteButton from './FavoriteButton'
import NewBadge from './company-list/NewBadge'
import PlaceDetail from './company-list/PlaceDetail'
import { useSelector } from 'react-redux'
import DealBadge from './company-list/DealBadge'
import CompanyImage from './company-list/CompanyImage'
import CardActions from './company-list/CardActions'
import { useAuth0 } from '@auth0/auth0-react'

const StyledList = styled.div`
  margin-top: 1em;
  font-size: 0.7em;
  width: 100%;
`

const StyledCard = styled.div`
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  max-width: 100%;
  padding: 0.5em;  
  position: relative;
  overflow: hidden;
  background: white;
  margin: 6px;
`

const StyledCompanyName = styled.h3`
  padding-top: 0.8em;
  margin: 0 0 1.0em;
  font-weight: bold;
  font-size: 1.4em;
`

const StyledDivider = styled.div`
  border-bottom: 1px solid #eee;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
`

const StyledCardWrapper = styled.div`
  position: relative;
  padding: 0;
  margin: 0 0 1.5em;
`

const CompanyList = (props) => {
    const { onSelectCompanyLoyalty } = props
    const companies = useSelector(state => state.companies)
    const { isAuthenticated } = useAuth0()

    const companiesList = companies.map(
        (company) =>
            <StyledCardWrapper key={company.id}>
                {company.deals && <DealBadge company={company}/>}
                <StyledCard onClick={() => onSelectCompanyLoyalty(company)}>
                    <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
                        {company.isNew && <NewBadge/>}
                        <CompanyImage company={company}/>
                        <div style={{ flexGrow: '1' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1.5em' }}>
                                <StyledCompanyName>{company.places[0].name}</StyledCompanyName>
                                <PlaceDetail places={company.places}/>
                            </div>
                        </div>
                        <div>
                            <FavoriteButton company={company}/>
                        </div>
                    </div>


                    {isAuthenticated && <><StyledDivider/><CardActions company={company}/></>}
                </StyledCard>
            </StyledCardWrapper>,
    )

    return (
        <StyledList>
            {companiesList}
        </StyledList>
    )
}


CompanyList.propTypes = {
    onSelectCompanyLoyalty: PropTypes.func.isRequired,
}

export default CompanyList
