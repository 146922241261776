import { useTranslation } from 'react-i18next'
import React from 'react'
import Headline from './Headline'
import Text from './Text'
import Button from './Button'
import styled from 'styled-components'
import Container from './Container'
import { ReactComponent as demoIcon } from '../images/demo_icon.svg'
import PropTypes from 'prop-types'

//Overlay
const WelcomeDemo = styled.div`
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: black;
  background: rgba(0,0,0,0.7);
  border: 1px solid black;
  z-index: 9000;
  align-items: center;
  justify-content: center;
  display: ${props => (!props.hide) ? 'flex' : 'none'};
  font-size: 0.95em;
  & a, a:hover, a:visited {
    color: black;
    text-decoration: none;
  } 
`

const WelcomeContent = styled(Container)`
  background-color: white;
  max-width: 320px;
  height: 440px;
  margin: auto;
  padding: 40px 0px;
  border-radius: 2%;
  overflow: hidden;
`

const DemoIcon = styled(demoIcon)`
`

const DemoBadge = styled.div`
  position:absolute;
  top: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  transform: rotate(-45deg);
  
  background-color: ${props => props.theme.boldActionColor};
  
  & div {
    color: ${props => props.theme.schemeMain};
    text-align: center;
    margin-top: 75px;
    font-weight: bold;
  }
`

const StyledText = styled(Text)`
  margin-bottom: 30px;
  padding: 0px 10px;
  font-size: 0.8em;
  color: #c5c6c7; 
  justifyContent:"center" 
`

const StyledButton = styled(Button)`
  width:80%;
  height:80px;
`

const StyledHeadLine = styled(Headline)`
  color: #949494;
  margin: 30px 10px;
  padding: 0px;
`

const WelcomePopup = (props) => {
    const { onButtonClick, hide } = props
    const { t } = useTranslation()
    const welcomeContext = (process.env.REACT_APP_ENV === 'development') ? 'welcome-staging' : 'welcome-' + process.env.REACT_APP_ENV
    return (
        <WelcomeDemo hide={hide}>
            <WelcomeContent>
                {(process.env.REACT_APP_ENV !== 'production') && <DemoBadge>
                    <div>Demo</div>
                </DemoBadge>}
                <DemoIcon/>
                <StyledHeadLine fontWeight="bold" xsmaller dangerouslySetInnerHTML={{ __html: t(`${welcomeContext}.title`) }}/>
                <StyledText dangerouslySetInnerHTML={{ __html: t(`${welcomeContext}.body`) }}/>
                <StyledButton onClick={onButtonClick} dangerouslySetInnerHTML={{ __html: t(`${welcomeContext}.closeHint`) }}/>
            </WelcomeContent>
        </WelcomeDemo>
    )
}

WelcomePopup.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
    hide: PropTypes.bool.isRequired,
}

export default WelcomePopup
