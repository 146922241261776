import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { CLEAR_AUTH_TOKEN, SET_AUTH_TOKEN } from './actions/types'
import { removeToken, setToken } from './api'

export const history = createBrowserHistory()

const saveAuthToken = store => next => action => {
    if (action.type === SET_AUTH_TOKEN) {
        setToken(action.payload)
    } else if (action.type === CLEAR_AUTH_TOKEN) {
        removeToken()
    }

    // continue processing this action
    return next(action)
}

const middlewares = [routerMiddleware(history), thunkMiddleware, saveAuthToken]

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['companyLoyalty', 'restaurant', 'client', 'appState', 'paymentData', 'wizardState', 'transaction', 'wirecardRequest', 'voucherCard', 'voucherRequest', 'voucherBalance', 'authState', 'company'],
    stateReconciler: autoMergeLevel2,
}

if (process.env.NODE_ENV === 'staging') {
    middlewares.push(logger)
}
const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

function configureStore (preloadedState) {
    const store = createStore(persistedReducer, preloadedState, composeWithDevTools(applyMiddleware(...middlewares)))

    // Hot reloading
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createRootReducer(history))
        })
    }

    return store
}

export const store = configureStore({})
export const persistor = persistStore(store, null, () => {
    const state = store.getState()
    const authToken = state.authState.authToken

    if (authToken) {
        setToken(authToken)
    } else {
        removeToken()
    }
})
