import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Container from '../components/Container'
import styled from 'styled-components'
import TipsChooser from '../components/TipsChooser'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'
import { useTracker } from '../hooks'
import Headline from '../components/Headline'
import { shouldSkipTipsSelector } from '../reducers/restaurant'
import PaymentButton from '../components/PaymentButton'

const StyledHeadline = styled(Headline)`
  margin-top: 30px;
`

const StepTips = ({ wizard }) => {
    const { t } = useTranslation()
    useTracker(null)
    const transaction = useSelector(state => state.transaction)
    const skipTips = useSelector(state => shouldSkipTipsSelector(state))

    useEffect(() => {
        if (skipTips) wizard.next()
    }, [])

    const getMoneyWithTips = () => {
        return (transaction.totalAmountCents / 100).toLocaleString('de', { style: 'currency', currency: 'EUR' })
    }

    return (
        <>
            <Container fluid={false}>
                <StyledHeadline forwardedAs="h3" fontWeight="400">
                    {t('tips.title1')}
                </StyledHeadline>
                <TipsChooser/>
                <StyledHeadline forwardedAs="h2">
                    {t('tips.title2')}
                </StyledHeadline>
                <Headline bigger>{getMoneyWithTips()}</Headline>
            </Container>
            <Footer>
                <PaymentButton wizard={wizard}/>
            </Footer>
        </>
    )
}

export default StepTips
