import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledColumns = styled.div`
  display: flex;
  
  &:not(:last-child) {
   margin-bottom: calc(${props => props.theme.blockSpacing} - .75rem);
  }
  
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom} !important;`};
`

const StyledColumn = styled.div`
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  //padding: .75rem;
  
  ${props => props.width && `flex: none; width: ${props.width};`};
  ${props => props.offset && `margin-left: ${props.offset};`};
`

const Columns = (props) => {
    const { children, ...rest } = props

    return (
        <StyledColumns {...rest}>{children}</StyledColumns>
    )
}

Columns.propTypes = {
    marginBottom: PropTypes.string,
}

const Column = (props) => {
    const { children, ...rest } = props

    return (
        <StyledColumn {...rest}>{children}</StyledColumn>
    )
}

Column.propTypes = {
    width: PropTypes.string,
    offset: PropTypes.string,
}

export { Columns, Column }
