import React from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import Container from '../components/Container'
import { ReactComponent as ReceiptWhite } from '../images/receipt_white.svg'
import { useTranslation } from 'react-i18next'
import { shouldSkipCameraSelector } from '../reducers/restaurant'
import { useTracker } from '../hooks'
import { useSelector } from 'react-redux'
import Headline from '../components/Headline'
import Figure from '../components/Figure'

const StyledHeadline = styled(Headline)`
  color: #03787A;
`

const BottomButton = styled(Button)`
  margin-top: auto;
  margin-bottom: 20px;
`

const StepColorScreen = (props) => {
    const { t } = useTranslation()
    useTracker(null)
    const skipCamera = useSelector(state => shouldSkipCameraSelector(state))
    const isAmountEditable = useSelector(state => state.appState.amountEditable)

    return (
        <>
            <Container blinking centered>
                <StyledHeadline forwardedAs="h3" fontWeight="400" dangerouslySetInnerHTML={{ __html: t(skipCamera ? 'color.textSkipCamera' : 'color.text') }}/>
                <Figure maxWidth="60%">
                    <ReceiptWhite/>
                </Figure>
                <BottomButton inverted onClick={() => props.wizard.next()}>
                    {skipCamera || !isAmountEditable ? t('color.buttonSkipCamera') : t('color.button')}
                </BottomButton>
            </Container>
        </>
    )
}

export default StepColorScreen
