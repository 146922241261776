import { CLEAR_STATE, REMOVE_VOUCHER_REQUEST, SET_PIN, SET_REQUEST_TYPE, SET_RESTAURANT, SET_TRANSACTION, SET_VOUCHER_CARD, SET_VOUCHER_REQUEST } from '../actions/types'

const initState = {
    id: null,
    pin: null,
    transactionId: null,
    requestType: 'charge',
    cardId: null,
    restaurantId: null,
}

export function voucherRequest (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_VOUCHER_REQUEST:
            return { ...state, ...action.payload }
        case SET_PIN:
            return { ...state, pin: action.payload }
        case SET_REQUEST_TYPE:
            return { ...state, requestType: action.payload }
        case SET_TRANSACTION:
            return { ...state, transactionId: action.payload.id }
        case SET_VOUCHER_CARD:
            return { ...state, cardId: action.payload.id }
        case REMOVE_VOUCHER_REQUEST:
            return { ...state, id: null }
        case SET_RESTAURANT:
            return { ...state, restaurantId: action.payload.id }
        case CLEAR_STATE: {
            return { ...initState }
        }
        default:
            return state
    }
}
