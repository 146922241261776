import React from 'react'
import styled from 'styled-components'
import Text from './Text'

const SpeechBubble = styled.div`
  padding: 14px;
  width: 260px;
  background-color: ${props => props.theme.tooltip};
  position: relative;
  border-radius: ${props => props.theme.borderRadius};
  margin-left: 19px;
  margin-bottom: 5px;

  &:after {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 50%;
    border: 12px solid transparent;
    border-top-color: ${props => props.theme.tooltip};
    border-bottom: 0;
    border-right: 0;
    margin-right: -4px;
    margin-bottom: -12px;
    width: 0;
    height: 0;
  }
  
  @media (min-width: ${props => props.theme.desktop}) {
    width: fit-content;
    background-color: ${props => props.theme.toolTipDesktop};
    
    &:after {
      border-top-color: ${props => props.theme.toolTipDesktop};
    }
  }
`

const Tooltip = (props) => {
    const { children } = props

    return (
        <SpeechBubble>
            <Text smaller align="left">{children}</Text>
        </SpeechBubble>
    )
}

export default Tooltip
