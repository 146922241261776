import React from 'react'
import styled from 'styled-components'
import { Scribble1, Scribble2, Scribble3 } from '../images/slides'
import PropTypes from 'prop-types'
import ReactSwipe from 'react-swipe'

const Indicators = styled.div`
    padding-bottom: calc(1vh + 1vw);
    display: flex;
    justify-content: center;
`

const Indicator = styled.button`
  width: 12px;
  height: 12px;
  border: 1px solid #000;
  margin-right: 10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ isActive }) => isActive ? '#000000' : '#ffffff'};
  padding: 0;
`

const SlideContainer = styled.div`
    background-color: white;
    padding-top: calc(1vh + 1vw);
    width: 100%;
`

const Image = styled.img`
  display: flex;
  height: 50vw;
  margin: 0 auto;
  max-height: 36vh;
`

const TextWrapper = styled.p`
  font-size: calc(1.5vw + 1.5vh);
  text-align: center;
  line-height: 3vh;
  padding: calc(1.25vw + 1.25vh) 0;
  width: 90vw;
  margin: auto;
  color: black;
  
  @media (min-width: ${props => props.theme.desktop}) {
    font-size: 19px;
    width: initial;
   }
`

class Slides extends React.Component {
    constructor (props) {
        super(props)

        this.reactSwipeEl = null
        this.state = {
            currentIndex: 0,
        }
    }

    afterSlideChanged = (index, element) => {
        this.setState({ currentIndex: index })
    }

    render () {
        const { textList } = this.props

        return (
            <SlideContainer>
                <ReactSwipe
                    className="carousel"
                    swipeOptions={{
                        speed: 750,
                        auto: 3500,
                        continuous: true,
                        callback: this.afterSlideChanged,
                    }}
                    ref={el => (this.reactSwipeEl = el)}
                >
                    <div>
                        <Image src={Scribble1} alt={''}/>
                        <TextWrapper>{textList[0]}</TextWrapper>
                    </div>
                    <div>
                        <Image src={Scribble2} alt={''}/>
                        <TextWrapper>{textList[1]}</TextWrapper>
                    </div>
                    <div>
                        <Image src={Scribble3} alt={''}/>
                        <TextWrapper>{textList[2]}</TextWrapper>
                    </div>
                </ReactSwipe>

                <Indicators>
                    <Indicator isActive={this.state.currentIndex === 0}
                               onClick={() => this.reactSwipeEl.slide(0)}/>
                    <Indicator isActive={this.state.currentIndex === 1}
                               onClick={() => this.reactSwipeEl.slide(1)}/>
                    <Indicator isActive={this.state.currentIndex === 2}
                               onClick={() => this.reactSwipeEl.slide(2)}/>
                </Indicators>
            </SlideContainer>
        )
    }
}

Slides.propTypes = {
    textList: PropTypes.array.isRequired,
}

export default Slides
