import {
    CANCEL_TRANSACTION,
    CLEAR_STATE,
    RECEIPT_SCANNED,
    REMOVE_WIRECARD_REQUEST,
    SET_AMOUNT,
    SET_CLIENT,
    SET_EXTERNAL_TRX_ID,
    SET_LINE_ITEMS,
    SET_RESTAURANT,
    SET_TIPS_AMOUNT,
    SET_TIPS_PERCENTAGE,
    SET_TRANSACTION,
    SET_WAITER,
    SET_WIRECARD_REQUEST,
} from '../actions/types'

const initState = {
    id: null,
    restaurantId: null,
    tipsPercentage: 0,
    clientId: null,
    waiterNumber: null,
    externalTransactionNumber: null,
    lineItems: null,
    totalAmountCents: 0,
    parsedAmountCents: null,
    enteredAmountCents: 0,
    tipsAmountCents: 0,
    voucherAmountCents: 0,
    remainingAmountCents: 0,
}

export function transaction (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_TRANSACTION:
            return { ...state, ...action.payload }
        case SET_RESTAURANT:
            return { ...state, restaurantId: action.payload.id }
        case SET_AMOUNT:
            return { ...state, enteredAmountCents: action.payload }
        case SET_TIPS_PERCENTAGE:
            return { ...state, tipsPercentage: action.payload }
        case SET_TIPS_AMOUNT:
            return { ...state, tipsAmountCents: action.payload }
        case RECEIPT_SCANNED:
            return { ...state, parsedAmountCents: action.payload.parsed_amount_cents || 0 }
        case SET_WIRECARD_REQUEST:
            return { ...state, wirecardRequestId: action.payload.id }
        case SET_WAITER:
            return { ...state, waiterNumber: action.payload }
        case SET_EXTERNAL_TRX_ID:
            return { ...state, externalTransactionNumber: action.payload }
        case SET_LINE_ITEMS:
            return { ...state, lineItems: action.payload }
        case SET_CLIENT:
            return { ...state, clientId: action.payload.id }
        case CANCEL_TRANSACTION:
            return { ...state, status: 'canceled' }
        case REMOVE_WIRECARD_REQUEST:
            return { ...state, wirecardRequestId: null, paymentMethodId: null }
        case CLEAR_STATE: {
            return { ...initState }
        }
        default:
            return state
    }
}
