import { CLEAR_COMPANY, SET_COMPANY } from './types'
import { getCompany } from '../services/companies'
import { setCashbackPot, setXHRInProgress } from './appStateActions'
import { createLoyaltyEvent, PostLoyaltyEvent } from '../services/profile'

export const setCompany = (payload) => {
    return {
        type: SET_COMPANY,
        payload: payload,
    }
}

export const clearCompany = () => {
    return {
        type: CLEAR_COMPANY,
    }
}

// Thunks

export const fetchCompany = (id) => {
    return async (dispatch, getState) => {
        try {
            const result = await getCompany(id)

            dispatch(setCompany(result.data))

            return Promise.resolve(result.data)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

export const connectCashback = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const cashBackPot = state.appState.cashbackPot
        const companyId = cashBackPot.companyId
        try {
            dispatch(setXHRInProgress(true))
            const result = await PostLoyaltyEvent(companyId, { transactionId: cashBackPot.transactionId })
            dispatch(setCashbackPot(null)) //clear cashbackpot after loyalty event
            dispatch(fetchCompany(companyId))
            dispatch(setXHRInProgress(false))
            return Promise.resolve(result)
        } catch (e) {
            dispatch(setXHRInProgress(false))
            return Promise.reject(e)

        }
    }
}

export const redeemCashback = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const company = state.company

        try {
            dispatch(setXHRInProgress(true))
            const result = await createLoyaltyEvent(company.id)
            dispatch(fetchCompany(company.id))
            dispatch(setXHRInProgress(false))
            return Promise.resolve(result)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
