//*** Thunks ***

// Calls stripe.confirmCardPayment
// If the card requires authentication Stripe shows a pop-up modal to
// prompt the user to enter authentication details without leaving your page.
import { errorHandler } from '../errorHandler'

export const payStripeWithCard = (stripe, card) => {
    return async (dispatch, getState) => {
        const state = getState()
        const paymentIntent = state.stripePaymentIntent

        try {
            const result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
                    payment_method: {
                        card: card,
                    },
                },
            )
            console.log(result)
            return Promise.resolve(result)
        } catch (error) {
            console.error(error.message)
            return Promise.reject(error)
        }
    }
}

export const payStripeWithWallet = (stripe, paymentMethodId) => {
    return async (dispatch, getState) => {
        const state = getState()
        const paymentIntent = state.stripePaymentIntent

        try {
            const result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
                    payment_method: paymentMethodId,
                },
                { handleActions: false },
            )
            console.log(result)
            return Promise.resolve(result)
        } catch (error) {
            console.error(error.message)
            return Promise.reject(error)
        }
    }
}

const payStripeWithSofort = (stripe) => {
    return async (dispatch, getState) => {
        const state = getState()
        const paymentIntent = state.stripePaymentIntent

        try {
            const result = await stripe.confirmSofortPayment(paymentIntent.clientSecret, {
                payment_method: {
                    sofort: {
                        country: 'DE',
                    },
                },
                return_url: `${window.location.origin}/wizard/step-6`,
            })
        } catch (e) {
            console.log(e.message)
        }
    }
}

const availablePaymentFunctions = {
    payWithSofort: payStripeWithSofort,
    payWithCard: payStripeWithCard,
}

export const payStripe = (paymentMethod, stripe) => {
    return async (dispatch, getState) => {
        try {
            return dispatch(availablePaymentFunctions[paymentMethod.paymentAction](stripe))
        } catch (e) {
            errorHandler(e)
        }
    }
}
